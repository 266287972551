export const bulkDataMock = {
  Cover: [
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "Welcome to Business Planner Bulk Upload, you can use this tool to populate demo data in Business Planner Database to prepare for a pitch presentation",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "Please go through the tables spreadsheets to manually input the database content field-by-field. Data you enter will be the same appearing in Business Planner UI, so pay particular attention. This data will completely override previous demo data sets",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD": "Runbook:",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "https://confluenceapp.dentsu.com/display/DDPST/BP+Solution+Design+v0.2+-+MVP",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD": "Please follow Below instructions:",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "1) Please provide username, password, client code and tenancy id in the below fields",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "2) Type values you want to visualise in the application. Check the wireframes mappings when unsure",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "3) When you are done, hit the submit button",
    },
    {
      "BUSINESS PLANNER BULK UPLOAD":
        "4) Please remember to double check on Busines Planner demo data is looking as you expected",
    },
    {
      __EMPTY: "CONFIGURATION",
    },
    {
      __EMPTY: "Username",
      __EMPTY_1: "tax_taxm_np@mediaecosystem.io",
    },
    {
      __EMPTY: "Password",
      __EMPTY_1: "b4TQXMj7mStJhYwEdWmuGdkjBt6QM5J2ScqxDsk6mNfQeLZXvPRh",
    },
    {
      __EMPTY: "Client Code",
      __EMPTY_1: "A_ABBO",
    },
    {
      __EMPTY: "Client Tenant",
      __EMPTY_1: "r2916",
    },
  ],
  Projects: [
    {
      id: "Project001",
      originalId: 1,
      name: "VWG Market A",
      brief: "Brief001",
      startDate: "01/01/2023",
      endDate: "12/31/2023",
      haloEffect: false,
      markets: ["Market001"],
      brands: ["Brand001"],
      channels: [
        "Channel003",
        "Channel004",
        "Channel005",
        "Channel006",
        "Channel008",
        "Channel009",
        "Channel0010"
      ],
      currency: "EUR",
      businessId: "BP-PRJ0001",
    },
    {
      id: "Project002",
      originalId: 2,
      name: "VWG Market B",
      brief: "Brief002",
      startDate: "01/01/2023",
      endDate: "12/31/2023",
      haloEffect: false,
      markets: ["Market002"],
      brands: ["Brand002"],
      channels: [
        "Channel013",
        "Channel014",
        "Channel016",
        "Channel017"
      ],
      currency: "EUR",
      businessId: "BP-PRJ0002",
    },
  ],
  ClientDimensions: [
    {
      id: "Dimension001",
      market: "Market001",
      brand: "Brand001",
      channel: "Channel001",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension002",
      market: "Market001",
      brand: "Brand001",
      channel: "Channel004",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension003",
      market: "Market001",
      brand: "Brand001",
      channel: "Channel005",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension004",
      market: "Market001",
      brand: "Brand001",
      channel: "Channel008",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension005",
      market: "Market001",
      brand: "Brand001",
      channel: "Channel009",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension006",
      market: "Market001",
      brand: "Brand002",
      channel: "Channel001",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension007",
      market: "Market001",
      brand: "Brand002",
      channel: "Channel004",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension008",
      market: "Market001",
      brand: "Brand002",
      channel: "Channel005",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension009",
      market: "Market001",
      brand: "Brand002",
      channel: "Channel008",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension010",
      market: "Market001",
      brand: "Brand002",
      channel: "Channel009",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension011",
      market: "Market001",
      brand: "Brand003",
      channel: "Channel001",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension012",
      market: "Market001",
      brand: "Brand003",
      channel: "Channel004",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension013",
      market: "Market001",
      brand: "Brand003",
      channel: "Channel005",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension014",
      market: "Market001",
      brand: "Brand003",
      channel: "Channel008",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension015",
      market: "Market001",
      brand: "Brand003",
      channel: "Channel009",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension016",
      market: "Market001",
      brand: "Brand004",
      channel: "Channel001",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension017",
      market: "Market001",
      brand: "Brand004",
      channel: "Channel004",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension018",
      market: "Market001",
      brand: "Brand004",
      channel: "Channel005",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension019",
      market: "Market001",
      brand: "Brand004",
      channel: "Channel008",
      mmm: true,
      benchmark: false,
    },
    {
      id: "Dimension020",
      market: "Market001",
      brand: "Brand004",
      channel: "Channel009",
      mmm: true,
      benchmark: false,
    },
  ],
  Markets: [
    {
      id: "Market001",
      name: "Market A",
    },
    {
      id: "Market002",
      name: "Market B",
    }
  ],
  Brands: [
    {
      id: "Brand001",
      name: "Model1 & Model20",
    },
    {
      id: "Brand002",
      name: "Model1-27",
    }
  ],
  Channels: [
    {
      id: "Channel001",
      name: "Market A All Channels",
      // parent: "AllChannels"
    },
    {
      id: "Channel002",
      name: "Market A Programmatic",
      parent: "Channel001"
    },
    {
      id: "Channel003",
      name: "Programmatic Model 20 Campaign 73",
      parent: "Channel002",
    },
    {
      id: "Channel004",
      name: "Programmatic Model 20 Campaign 74",
      parent: "Channel002",
    },
    {
      id: "Channel005",
      name: "Programmatic Model 20 Campaign 77",
      parent: "Channel002",
    },
    {
      id: "Channel006",
      name: "Programmatic Model 1 Campaign 78",
      parent: "Channel002",
    },
    {
      id: "Channel007",
      name: "Market A Social",
      parent: "Channel001"
    },
    {
      id: "Channel008",
      name: "Social Model 20 Campaign 92",
      parent: "Channel007",
    },
    {
      id: "Channel009",
      name: "Social Model 20 Campaign 93",
      parent: "Channel007",
    },
    {
      id: "Channel010",
      name: "Social Model 1 Campaign 94",
      parent: "Channel007",
    },
    {
      id: "Channel011",
      name: "Market B All Channels",
      // parent: "AllChannels"
    },
    {
      id: "Channel012",
      name: "Market B Search",
      parent: "Channel011"
    },
    {
      id: "Channel013",
      name: "Search Model 1 Campaign All",
      parent: "Channel012",
    },
    {
      id: "Channel014",
      name: "Search Model 2-27 Campaign All",
      parent: "Channel012",
    },
    {
      id: "Channel015",
      name: "Market B Social",
      parent: "Channel011"
    },
    {
      id: "Channel016",
      name: "Social Model 1 Campaign All",
      parent: "Channel015",
    },
    {
      id: "Channel017",
      name: "Social Model 11 & 18 Campaign All",
      parent: "Channel015",
    },
  ],
  KpiDefs: [
    {
      id: "KpiDef001",
      caption: "SALES",
    },
    {
      id: "KpiDef002",
      caption: "Incremental Sales",
      type: "CURRENCY",
      parent: "KpiDef001",
    },
    {
      id: "KpiDef003",
      caption: "Total Sales",
      type: "CURRENCY",
      parent: "KpiDef001",
    },
    {
      id: "KpiDef004",
      caption: "Leads",
      type: "NUMBER",
      parent: "KpiDef001",
    },
    {
      id: "KpiDef005",
      caption: "Units",
      type: "NUMBER",
      parent: "KpiDef001",
    },
    {
      id: "KpiDef006",
      caption: "Foot traffic",
      type: "NUMBER",
      parent: "KpiDef001",
    },
    {
      id: "KpiDef016",
      caption: "ROI",
      type: "NUMBER",
      parent: "KpiDef001",
    },
    {
      id: "KpiDef007",
      caption: "ATTITUDINAL",
    },
    {
      id: "KpiDef008",
      caption: "Brand recall",
      type: "PERCENTAGE",
      parent: "KpiDef007",
    },
    {
      id: "KpiDef009",
      caption: "Spontaneous brand awareness",
      type: "PERCENTAGE",
      parent: "KpiDef007",
    },
    {
      id: "KpiDef010",
      caption: "Prompted brand awareness",
      type: "PERCENTAGE",
      parent: "KpiDef007",
    },
    {
      id: "KpiDef012",
      caption: "Brand srength / Preference",
      type: "PERCENTAGE",
      parent: "KpiDef007",
    },
    {
      id: "KpiDef013",
      caption: "OTHER",
    },
    {
      id: "KpiDef014",
      caption: "Carbon emissions",
      type: "NUMBER",
      parent: "KpiDef013",
    },
    {
      id: "KpiDef015",
      caption: "Attention",
      type: "NUMBER",
      parent: "KpiDef013",
    },
  ],
  Scenarios: [
    {
      id: "Scenario001",
      name: "Base",
      description: "Benchmark (current plan)",
      type: "Existing plan",
      businessId: "SCN0001",
      status: "Sent to client",
      project: "Project001",
      totalBudget: 227956,
      IncrementalSales: 332205,
      ROI: 0,
    },
    {
      id: "Scenario002",
      name: "Scenario 2",
      description: "Same Budget & Optimized Mix",
      type: "Given budget",
      businessId: "SCN0002",
      status: "Sent to client",
      project: "Project001",
      totalBudget: 227956,
      IncrementalSales: 339460,
      ROI: -0.0214,
    },
    {
      id: "Scenario003",
      name: "Scenario 3",
      description: "+20% Budget & Optimized Mix",
      type: "Given budget",
      businessId: "SCN0003",
      status: "Sent to client",
      project: "Project001",
      totalBudget: 273547,
      IncrementalSales: 343798,
      ROI: 0.1595,
    },
    {
      id: "Scenario004",
      name: "Scenario 4",
      description: "Same Purchase Considerations & Optimized Mix",
      pinned: true,
      type: "Find the budget",
      businessId: "SCN0004",
      status: "Sent to client",
      project: "Project001",
      totalBudget: 181953,
      IncrementalSales: 332205,
      ROI: -0.2018,
    },

    {
      id: "Scenario005",
      name: "Base",
      description: "Benchmark (current plan)",
      type: "Existing plan",
      businessId: "SCN0005",
      status: "Sent to client",
      project: "Project002",
      totalBudget: 352677,
      IncrementalSales: 120214,
      ROI: 0,
    },
    {
      id: "Scenario006",
      name: "Scenario 2",
      description: "Same Budget & Optimized Mix",
      type: "Given budget",
      businessId: "SCN0006",
      status: "Sent to client",
      project: "Project002",
      totalBudget: 352677,
      IncrementalSales: 123301,
      ROI: -0.0250,
    },
    {
      id: "Scenario007",
      name: "Scenario 3",
      description: "+20% Budget & Optimized Mix",
      type: "Given budget",
      businessId: "SCN0007",
      status: "Sent to client",
      project: "Project002",
      totalBudget: 423213,
      IncrementalSales: 124738,
      ROI: 0.1565,
    },
    {
      id: "Scenario008",
      name: "Scenario 4",
      description: "Same Purchase Considerations & Optimized Mix",
      pinned: true,
      type: "Find the budget",
      businessId: "SCN0008",
      status: "Sent to client",
      project: "Project002",
      totalBudget: 282507,
      IncrementalSales: 120214,
      ROI: -0.1990,
    },
  ],
  Constraints: [
    {
      id: "Constraint001",
      type: "TV Spend",
      minValue: 100000,
      scenario: "Scenario001",
    },
    {
      id: "Constraint001",
      type: "TV Spend",
      minValue: 100000,
      scenario: "Scenario002",
    },
    {
      id: "Constraint001",
      type: "TV Spend",
      minValue: 100000,
      scenario: "Scenario003",
    },
    {
      id: "Constraint001",
      type: "TV Spend",
      minValue: 100000,
      scenario: "Scenario004",
    },
  ],
  Targets: [
    {
      id: "Target001",
      scenario: "Scenario002",
      kpiDef: "KpiDef002",
      targetValue: 10000000,
      usage: "PRIMARY",
    },
    {
      id: "Target002",
      scenario: "Scenario003",
      kpiDef: "KpiDef002",
      targetValue: 10000000,
      usage: "PRIMARY",
    },
    {
      id: "Target003",
      scenario: "Scenario004",
      kpiDef: "KpiDef002",
      targetValue: 10000000,
      usage: "PRIMARY",
    },
  ],
  Briefs: [
    {
      id: "Brief001",
      name: "My Test Brief",
      budgetCurrency: "EUR",
      budgetAmount: 12345,
      startDate: "09/01/2023",
      endDate: "12/31/2023",
    },
  ],
  Runs: [
    {
      id: "Run001",
      scenario: "Scenario001",
      type: "Existing plan",
    },
    {
      id: "Run002",
      scenario: "Scenario002",
      type: "Given budget",
    },
    {
      id: "Run003",
      scenario: "Scenario003",
      type: "Given budget",
    },
    {
      id: "Run004",
      scenario: "Scenario004",
      type: "Find the budget",
    },
    {
      id: "Run005",
      scenario: "Scenario005",
      type: "Existing plan",
    },
    {
      id: "Run006",
      scenario: "Scenario006",
      type: "Given budget",
    },
    {
      id: "Run007",
      scenario: "Scenario007",
      type: "Given budget",
    },
    {
      id: "Run008",
      scenario: "Scenario008",
      type: "Find the budget",
    },
  ],
  TimeAnalysis: [
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 22256,
      "IncrementalSales": 19556,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 25890,
      "IncrementalSales": 21432,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 14413,
      "IncrementalSales": 20246,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 24736,
      "IncrementalSales": 21817,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 16092,
      "IncrementalSales": 20881,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 6397,
      "IncrementalSales": 17939,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 6476,
      "IncrementalSales": 17502,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 6728,
      "IncrementalSales": 17461,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 2459,
      "IncrementalSales": 7334,
      "channel": "Channel003",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 425,
      "IncrementalSales": 266,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 484,
      "IncrementalSales": 267,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 495,
      "IncrementalSales": 260,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 493,
      "IncrementalSales": 240,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 509,
      "IncrementalSales": 275,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 507,
      "IncrementalSales": 254,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 512,
      "IncrementalSales": 268,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 518,
      "IncrementalSales": 279,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 223,
      "IncrementalSales": 184,
      "channel": "Channel004",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 452,
      "IncrementalSales": 260,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 510,
      "IncrementalSales": 269,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 520,
      "IncrementalSales": 263,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 519,
      "IncrementalSales": 246,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 534,
      "IncrementalSales": 279,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 541,
      "IncrementalSales": 288,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 538,
      "IncrementalSales": 267,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 538,
      "IncrementalSales": 271,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 230,
      "IncrementalSales": 185,
      "channel": "Channel005",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 3139,
      "IncrementalSales": 2098,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 22416,
      "IncrementalSales": 18182,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 23080,
      "IncrementalSales": 19412,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 17792,
      "IncrementalSales": 8504,
      "channel": "Channel006",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 1278,
      "IncrementalSales": 1853,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 1304,
      "IncrementalSales": 2060,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 885,
      "IncrementalSales": 2004,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 862,
      "IncrementalSales": 1957,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 1104,
      "IncrementalSales": 2024,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 1346,
      "IncrementalSales": 2142,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 1334,
      "IncrementalSales": 2156,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 1281,
      "IncrementalSales": 2163,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 989,
      "IncrementalSales": 927,
      "channel": "Channel008",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 2039,
      "IncrementalSales": 4383,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 1987,
      "IncrementalSales": 4724,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 2080,
      "IncrementalSales": 4825,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 2065,
      "IncrementalSales": 4868,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 1761,
      "IncrementalSales": 4617,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 1144,
      "IncrementalSales": 3648,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 1366,
      "IncrementalSales": 4193,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 1079,
      "IncrementalSales": 3751,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 913,
      "IncrementalSales": 1618,
      "channel": "Channel009",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run001",
      "spend": 1168,
      "IncrementalSales": 1316,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run001",
      "spend": 1839,
      "IncrementalSales": 1842,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run001",
      "spend": 3636,
      "IncrementalSales": 2109,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 1030,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 647,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 472,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run001",
      "spend": 0,
      "IncrementalSales": 169,
      "channel": "Channel010",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run005",
      "spend": 15228,
      "IncrementalSales": 3495,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run005",
      "spend": 15647,
      "IncrementalSales": 4104,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run005",
      "spend": 14884,
      "IncrementalSales": 4201,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run005",
      "spend": 13751,
      "IncrementalSales": 4186,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run005",
      "spend": 12546,
      "IncrementalSales": 4126,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run005",
      "spend": 9208,
      "IncrementalSales": 3833,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run005",
      "spend": 8880,
      "IncrementalSales": 3710,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run005",
      "spend": 7762,
      "IncrementalSales": 3585,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run005",
      "spend": 5772,
      "IncrementalSales": 1537,
      "channel": "Channel013",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run005",
      "spend": 16723,
      "IncrementalSales": 4329,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run005",
      "spend": 15850,
      "IncrementalSales": 4972,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run005",
      "spend": 15318,
      "IncrementalSales": 5118,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run005",
      "spend": 14469,
      "IncrementalSales": 5114,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run005",
      "spend": 15774,
      "IncrementalSales": 5189,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run005",
      "spend": 16159,
      "IncrementalSales": 5301,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run005",
      "spend": 15676,
      "IncrementalSales": 5279,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run005",
      "spend": 14398,
      "IncrementalSales": 5212,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run005",
      "spend": 12522,
      "IncrementalSales": 2302,
      "channel": "Channel014",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run005",
      "spend": 13295,
      "IncrementalSales": 4334,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run005",
      "spend": 12659,
      "IncrementalSales": 4970,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run005",
      "spend": 14914,
      "IncrementalSales": 5350,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run005",
      "spend": 15101,
      "IncrementalSales": 5380,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run005",
      "spend": 13932,
      "IncrementalSales": 5309,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run005",
      "spend": 4096,
      "IncrementalSales": 3474,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run005",
      "spend": 7850,
      "IncrementalSales": 4088,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run005",
      "spend": 7769,
      "IncrementalSales": 4108,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run005",
      "spend": 10088,
      "IncrementalSales": 1331,
      "channel": "Channel016",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    },
    {
      "id": "WeeklySpend001",
      "week": 1,
      "run": "Run005",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 1,
      "startDate": "02 January 2023",
      "endDate": "08 January 2023"
    },
    {
      "id": "WeeklySpend002",
      "week": 2,
      "run": "Run005",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 2,
      "startDate": "09 January 2023",
      "endDate": "15 January 2023"
    },
    {
      "id": "WeeklySpend003",
      "week": 3,
      "run": "Run005",
      "spend": 0,
      "IncrementalSales": 0,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 3,
      "startDate": "16 January 2023",
      "endDate": "22 January 2023"
    },
    {
      "id": "WeeklySpend004",
      "week": 4,
      "run": "Run005",
      "spend": 118,
      "IncrementalSales": 40,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 4,
      "startDate": "23 January 2023",
      "endDate": "29 January 2023"
    },
    {
      "id": "WeeklySpend005",
      "week": 5,
      "run": "Run005",
      "spend": 1390,
      "IncrementalSales": 406,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 5,
      "startDate": "30 January 2023",
      "endDate": "05 February 2023"
    },
    {
      "id": "WeeklySpend006",
      "week": 6,
      "run": "Run005",
      "spend": 4087,
      "IncrementalSales": 570,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 6,
      "startDate": "06 February 2023",
      "endDate": "12 February 2023"
    },
    {
      "id": "WeeklySpend007",
      "week": 7,
      "run": "Run005",
      "spend": 3050,
      "IncrementalSales": 573,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 7,
      "startDate": "13 February 2023",
      "endDate": "19 February 2023"
    },
    {
      "id": "WeeklySpend008",
      "week": 8,
      "run": "Run005",
      "spend": 1995,
      "IncrementalSales": 540,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 8,
      "startDate": "20 February 2023",
      "endDate": "26 February 2023"
    },
    {
      "id": "WeeklySpend009",
      "week": 9,
      "run": "Run005",
      "spend": 1770,
      "IncrementalSales": 181,
      "channel": "Channel017",
      "timeGranularity": "WEEKLY",
      "timeValue": 9,
      "startDate": "27 February 2023",
      "endDate": "04 March 2023"
    }
  ],
  channelImpactOnSales: [
    {
      id: "CIO001",
      run: "Run001",
      channel: "Channel001",
      kpiValue: 332205,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO002",
      run: "Run001",
      channel: "Channel002",
      kpiValue: 266081,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO003",
      run: "Run001",
      channel: "Channel003",
      kpiValue: 206417,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO004",
      run: "Run001",
      channel: "Channel004",
      kpiValue: 4248,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO005",
      run: "Run001",
      channel: "Channel005",
      kpiValue: 4472,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO006",
      run: "Run001",
      channel: "Channel006",
      kpiValue: 50945,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO007",
      run: "Run001",
      channel: "Channel007",
      kpiValue: 66123,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO008",
      run: "Run001",
      channel: "Channel008",
      kpiValue: 18281,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO009",
      run: "Run001",
      channel: "Channel009",
      kpiValue: 39746,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO010",
      run: "Run001",
      channel: "Channel010",
      kpiValue: 8097,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO011",
      run: "Run005",
      channel: "Channel011",
      kpiValue: 120214,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO012",
      run: "Run005",
      channel: "Channel012",
      kpiValue: 78172,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO013",
      run: "Run005",
      channel: "Channel013",
      kpiValue: 33895,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO014",
      run: "Run005",
      channel: "Channel014",
      kpiValue: 44277,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO015",
      run: "Run005",
      channel: "Channel015",
      kpiValue: 42042,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO016",
      run: "Run005",
      channel: "Channel016",
      kpiValue: 39653,
      kpiDef: "KpiDef002",
    },
    {
      id: "CIO017",
      run: "Run005",
      channel: "Channel017",
      kpiValue: 2389,
      kpiDef: "KpiDef002",
    }
  ],
  WeeklySpends: [
    {
      id: "WeeklySpend001",
      week: 1,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend002",
      week: 2,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend003",
      week: 3,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend004",
      week: 4,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend005",
      week: 5,
      revenue: 3488.98413369077,
      run: "Run001",
      spend: 3000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend006",
      week: 6,
      revenue: 5476.595543586441,
      run: "Run001",
      spend: 3000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend007",
      week: 7,
      revenue: 5814.437974972319,
      run: "Run001",
      spend: 3000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend008",
      week: 8,
      revenue: 6097.5648583155,
      run: "Run001",
      spend: 3000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend009",
      week: 9,
      revenue: 6032.24690262063,
      run: "Run001",
      spend: 3000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend010",
      week: 10,
      revenue: 6954.06466476739,
      run: "Run001",
      spend: 3000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend011",
      week: 11,
      revenue: 4117.51810479575,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend012",
      week: 12,
      revenue: 131664.069782313,
      run: "Run001",
      spend: 199000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend013",
      week: 13,
      revenue: 194032.286348201,
      run: "Run001",
      spend: 191000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend014",
      week: 14,
      revenue: 128924.214581042,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend015",
      week: 15,
      revenue: 191179.111296515,
      run: "Run001",
      spend: 194000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend016",
      week: 16,
      revenue: 125761.028171937,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend017",
      week: 17,
      revenue: 155644.78599017,
      run: "Run001",
      spend: 194000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend018",
      week: 18,
      revenue: 184425.17064651,
      run: "Run001",
      spend: 231000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend019",
      week: 19,
      revenue: 130188.813334754,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend020",
      week: 20,
      revenue: 154270.93326774702,
      run: "Run001",
      spend: 178000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend021",
      week: 21,
      revenue: 199584.659307042,
      run: "Run001",
      spend: 158000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend022",
      week: 22,
      revenue: 132681.565180289,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend023",
      week: 23,
      revenue: 283109.6987584809,
      run: "Run001",
      spend: 259000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend024",
      week: 24,
      revenue: 222181.545080335,
      run: "Run001",
      spend: 110000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend025",
      week: 25,
      revenue: 275737.945702302,
      run: "Run001",
      spend: 242000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend026",
      week: 26,
      revenue: 341366.93362879404,
      run: "Run001",
      spend: 242000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend027",
      week: 27,
      revenue: 422416.9007452435,
      run: "Run001",
      spend: 266000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend028",
      week: 28,
      revenue: 321429.4349045252,
      run: "Run001",
      spend: 148000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend029",
      week: 29,
      revenue: 354930.8429967549,
      run: "Run001",
      spend: 227000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend030",
      week: 30,
      revenue: 412946.1071704983,
      run: "Run001",
      spend: 149000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend031",
      week: 31,
      revenue: 305649.050061177,
      run: "Run001",
      spend: 50000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend032",
      week: 32,
      revenue: 399491.5552981529,
      run: "Run001",
      spend: 159000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend033",
      week: 33,
      revenue: 303480.0919913797,
      run: "Run001",
      spend: 95000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend034",
      week: 34,
      revenue: 197269.1862688998,
      run: "Run001",
      spend: 50000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend035",
      week: 35,
      revenue: 213301.2770350035,
      run: "Run001",
      spend: 142000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend036",
      week: 36,
      revenue: 238146.12546746095,
      run: "Run001",
      spend: 201000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend037",
      week: 37,
      revenue: 164991.0087052991,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend038",
      week: 38,
      revenue: 97797.01041225388,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend039",
      week: 39,
      revenue: 61937.97654478806,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend040",
      week: 40,
      revenue: 48562.150354462574,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend041",
      week: 41,
      revenue: 36429.87896545595,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend042",
      week: 42,
      revenue: 29781.864008936518,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend043",
      week: 43,
      revenue: 24914.41102329525,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend044",
      week: 44,
      revenue: 22622.298768504577,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend045",
      week: 45,
      revenue: 18509.967688499175,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend046",
      week: 46,
      revenue: 15047.44283568144,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend047",
      week: 47,
      revenue: 11435.336387603282,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend048",
      week: 48,
      revenue: 9355.846315794168,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend049",
      week: 49,
      revenue: 8242.57415979205,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend050",
      week: 50,
      revenue: 7687.336874924665,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend051",
      week: 51,
      revenue: 7531.264881010841,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend052",
      week: 52,
      revenue: 7442.033433986702,
      run: "Run001",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend053",
      week: 1,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend054",
      week: 2,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend055",
      week: 3,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend056",
      week: 4,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend057",
      week: 5,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend058",
      week: 6,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend059",
      week: 7,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend060",
      week: 8,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend061",
      week: 9,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend062",
      week: 10,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend063",
      week: 11,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend064",
      week: 12,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend065",
      week: 13,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend066",
      week: 14,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend067",
      week: 15,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend068",
      week: 16,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend069",
      week: 17,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend070",
      week: 18,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend071",
      week: 19,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend072",
      week: 20,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend073",
      week: 21,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend074",
      week: 22,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend075",
      week: 23,
      revenue: 10120.8315909195,
      run: "Run001",
      spend: 10000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend076",
      week: 24,
      revenue: 14839.8980685194,
      run: "Run001",
      spend: 10000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend077",
      week: 25,
      revenue: 22840.5700610516,
      run: "Run001",
      spend: 20000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend078",
      week: 26,
      revenue: 27511.5495040785,
      run: "Run001",
      spend: 20000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend079",
      week: 27,
      revenue: 27406.0899913676,
      run: "Run001",
      spend: 18000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend080",
      week: 28,
      revenue: 28933.0114859337,
      run: "Run001",
      spend: 18000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend081",
      week: 29,
      revenue: 33793.995808946805,
      run: "Run001",
      spend: 18000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend082",
      week: 30,
      revenue: 35746.486873045396,
      run: "Run001",
      spend: 18000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend083",
      week: 31,
      revenue: 22088.643168921,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend084",
      week: 32,
      revenue: 11471.131374439601,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend085",
      week: 33,
      revenue: 4126.260959930821,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend086",
      week: 34,
      revenue: 2032.2183306971601,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend087",
      week: 35,
      revenue: 922.0494713852529,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend088",
      week: 36,
      revenue: 466.016913852713,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend089",
      week: 37,
      revenue: 223.571735379854,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend090",
      week: 38,
      revenue: 99.9675861222102,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend091",
      week: 39,
      revenue: 47.1180567991822,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend092",
      week: 40,
      revenue: 22.4991537953083,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend093",
      week: 41,
      revenue: 10.349251007831999,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend094",
      week: 42,
      revenue: 4.98427953953344,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend095",
      week: 43,
      revenue: 2.60751996285724,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend096",
      week: 44,
      revenue: 1.11799962031719,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend097",
      week: 45,
      revenue: 0.563626873874619,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend098",
      week: 46,
      revenue: 0.27538335094582,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend099",
      week: 47,
      revenue: 0.151300492332309,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend100",
      week: 48,
      revenue: 0.0892027622089285,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend101",
      week: 49,
      revenue: 0.055430473396390005,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend102",
      week: 50,
      revenue: 0.032900919994039296,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend103",
      week: 51,
      revenue: 0.023255922850797202,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend104",
      week: 52,
      revenue: 0.00844387356749669,
      run: "Run001",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend105",
      week: 1,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend106",
      week: 2,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend107",
      week: 3,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend108",
      week: 4,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend109",
      week: 5,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend110",
      week: 6,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend111",
      week: 7,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend112",
      week: 8,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend113",
      week: 9,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend114",
      week: 10,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend115",
      week: 11,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend116",
      week: 12,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend117",
      week: 13,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend118",
      week: 14,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend119",
      week: 15,
      revenue: 30916.428591884698,
      run: "Run001",
      spend: 11000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend120",
      week: 16,
      revenue: 35694.100966144,
      run: "Run001",
      spend: 11000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend121",
      week: 17,
      revenue: 28313.1062584247,
      run: "Run001",
      spend: 11000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend122",
      week: 18,
      revenue: 33340.69084984918,
      run: "Run001",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend123",
      week: 19,
      revenue: 37241.2446618603,
      run: "Run001",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend124",
      week: 20,
      revenue: 35684.47606585805,
      run: "Run001",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend125",
      week: 21,
      revenue: 41440.04460975826,
      run: "Run001",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend126",
      week: 22,
      revenue: 41721.64498852569,
      run: "Run001",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend127",
      week: 23,
      revenue: 67877.73726911521,
      run: "Run001",
      spend: 43000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend128",
      week: 24,
      revenue: 108577.87365690159,
      run: "Run001",
      spend: 57000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend129",
      week: 25,
      revenue: 141761.6300189151,
      run: "Run001",
      spend: 115000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend130",
      week: 26,
      revenue: 150471.19641432722,
      run: "Run001",
      spend: 115000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend131",
      week: 27,
      revenue: 153588.68238584453,
      run: "Run001",
      spend: 104000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend132",
      week: 28,
      revenue: 145692.69901011602,
      run: "Run001",
      spend: 43000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend133",
      week: 29,
      revenue: 137141.3326786169,
      run: "Run001",
      spend: 42000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend134",
      week: 30,
      revenue: 133102.0480869449,
      run: "Run001",
      spend: 42000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend135",
      week: 31,
      revenue: 96448.04079639507,
      run: "Run001",
      spend: 7000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend136",
      week: 32,
      revenue: 63732.64741320055,
      run: "Run001",
      spend: 7000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend137",
      week: 33,
      revenue: 25490.61728891359,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend138",
      week: 34,
      revenue: 10197.450438187052,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend139",
      week: 35,
      revenue: 63699.67472241783,
      run: "Run001",
      spend: 21000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend140",
      week: 36,
      revenue: 72390.28505409803,
      run: "Run001",
      spend: 16000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend141",
      week: 37,
      revenue: 77718.64970973069,
      run: "Run001",
      spend: 21000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend142",
      week: 38,
      revenue: 58708.27444478999,
      run: "Run001",
      spend: 5000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend143",
      week: 39,
      revenue: 42817.31742145386,
      run: "Run001",
      spend: 5000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend144",
      week: 40,
      revenue: 37311.26595109487,
      run: "Run001",
      spend: 5000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend145",
      week: 41,
      revenue: 28853.485735860595,
      run: "Run001",
      spend: 5000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend146",
      week: 42,
      revenue: 17552.62110260167,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend147",
      week: 43,
      revenue: 8316.186998007375,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend148",
      week: 44,
      revenue: 3808.3093224641825,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend149",
      week: 45,
      revenue: 1587.970866251601,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend150",
      week: 46,
      revenue: 618.527267143897,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend151",
      week: 47,
      revenue: 259.5932952148905,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend152",
      week: 48,
      revenue: 107.07313552797976,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend153",
      week: 49,
      revenue: 48.20035338117777,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend154",
      week: 50,
      revenue: 23.623077151402796,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend155",
      week: 51,
      revenue: 14.019255844703556,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend156",
      week: 52,
      revenue: 6.866272477162598,
      run: "Run001",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend157",
      week: 1,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend158",
      week: 2,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend159",
      week: 3,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend160",
      week: 4,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend161",
      week: 5,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend162",
      week: 6,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend163",
      week: 7,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend164",
      week: 8,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend165",
      week: 9,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend166",
      week: 10,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend167",
      week: 11,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend168",
      week: 12,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend169",
      week: 13,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend170",
      week: 14,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend171",
      week: 15,
      revenue: 27588.5292700233,
      run: "Run001",
      spend: 18000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend172",
      week: 16,
      revenue: 35833.2766163021,
      run: "Run001",
      spend: 18000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend173",
      week: 17,
      revenue: 29546.1249848515,
      run: "Run001",
      spend: 18000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend174",
      week: 18,
      revenue: 32645.29330616492,
      run: "Run001",
      spend: 28000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend175",
      week: 19,
      revenue: 34443.5708773268,
      run: "Run001",
      spend: 25000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend176",
      week: 20,
      revenue: 31751.825497479822,
      run: "Run001",
      spend: 25000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend177",
      week: 21,
      revenue: 22632.07121643955,
      run: "Run001",
      spend: 38000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend178",
      week: 22,
      revenue: 17480.0595376632,
      run: "Run001",
      spend: 43000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend179",
      week: 23,
      revenue: 19242.601884670952,
      run: "Run001",
      spend: 49000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend180",
      week: 24,
      revenue: 18019.18305123809,
      run: "Run001",
      spend: 48924,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend181",
      week: 25,
      revenue: 19427.749131855348,
      run: "Run001",
      spend: 49000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend182",
      week: 26,
      revenue: 24436.230796103686,
      run: "Run001",
      spend: 49000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend183",
      week: 27,
      revenue: 97466.14403568595,
      run: "Run001",
      spend: 89000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend184",
      week: 28,
      revenue: 99239.3555747192,
      run: "Run001",
      spend: 90000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend185",
      week: 29,
      revenue: 102924.23599743895,
      run: "Run001",
      spend: 96000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend186",
      week: 30,
      revenue: 118425.93303634526,
      run: "Run001",
      spend: 101000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend187",
      week: 31,
      revenue: 99388.3064486118,
      run: "Run001",
      spend: 37000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend188",
      week: 32,
      revenue: 103745.06742061157,
      run: "Run001",
      spend: 37000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend189",
      week: 33,
      revenue: 97713.39697462688,
      run: "Run001",
      spend: 24500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend190",
      week: 34,
      revenue: 74463.92702581496,
      run: "Run001",
      spend: 40000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend191",
      week: 35,
      revenue: 69555.88525583375,
      run: "Run001",
      spend: 34000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend192",
      week: 36,
      revenue: 55339.377905873065,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend193",
      week: 37,
      revenue: 36718.45307551276,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend194",
      week: 38,
      revenue: 19600.397344958423,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend195",
      week: 39,
      revenue: 8494.58124713705,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend196",
      week: 40,
      revenue: 4152.706586344372,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend197",
      week: 41,
      revenue: 1971.410022822252,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend198",
      week: 42,
      revenue: 958.7715928395675,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend199",
      week: 43,
      revenue: 477.4348080974332,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend200",
      week: 44,
      revenue: 259.89907963614286,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend201",
      week: 45,
      revenue: 126.16050642286677,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend202",
      week: 46,
      revenue: 61.051620507605364,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend203",
      week: 47,
      revenue: 27.666245697385662,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend204",
      week: 48,
      revenue: 13.737149642724937,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend205",
      week: 49,
      revenue: 7.4247952726434265,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend206",
      week: 50,
      revenue: 4.300902422729773,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend207",
      week: 51,
      revenue: 2.609047699812974,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend208",
      week: 52,
      revenue: 1.6208979382870925,
      run: "Run001",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend209",
      week: 1,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend210",
      week: 2,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend211",
      week: 3,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend212",
      week: 4,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend213",
      week: 5,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend214",
      week: 6,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend215",
      week: 7,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend216",
      week: 8,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend217",
      week: 9,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend218",
      week: 10,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend219",
      week: 11,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend220",
      week: 12,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend221",
      week: 13,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend222",
      week: 14,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend223",
      week: 15,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend224",
      week: 16,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend225",
      week: 17,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend226",
      week: 18,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend227",
      week: 19,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend228",
      week: 20,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend229",
      week: 21,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend230",
      week: 22,
      revenue: 1734.64020041864,
      run: "Run001",
      spend: 5000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend231",
      week: 23,
      revenue: 2031.6964653671298,
      run: "Run001",
      spend: 5000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend232",
      week: 24,
      revenue: 2138.5313788335498,
      run: "Run001",
      spend: 5000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend233",
      week: 25,
      revenue: 2457.67154985627,
      run: "Run001",
      spend: 5000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend234",
      week: 26,
      revenue: 525.096672552644,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend235",
      week: 27,
      revenue: 78.9717149374207,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend236",
      week: 28,
      revenue: 9.4279552016925,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend237",
      week: 29,
      revenue: 1.38650925046638,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend238",
      week: 30,
      revenue: 0.235668222343748,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend239",
      week: 31,
      revenue: 0.030377298254655103,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend240",
      week: 32,
      revenue: 0.0056091375712174,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend241",
      week: 33,
      revenue: 0.000756227725401635,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend242",
      week: 34,
      revenue: 0.00008325177583900221,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend243",
      week: 35,
      revenue: 0.0000139878553803569,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend244",
      week: 36,
      revenue: 0.0000019954616389944003,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend245",
      week: 37,
      revenue: 2.8300202501034097e-7,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend246",
      week: 38,
      revenue: 5.37154827658709e-8,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend247",
      week: 39,
      revenue: 5.9456277249091904e-9,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend248",
      week: 40,
      revenue: 1.00172950687803e-9,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend249",
      week: 41,
      revenue: 1.2997563026051898e-10,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend250",
      week: 42,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend251",
      week: 43,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend252",
      week: 44,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend253",
      week: 45,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend254",
      week: 46,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend255",
      week: 47,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend256",
      week: 48,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend257",
      week: 49,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend258",
      week: 50,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend259",
      week: 51,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend260",
      week: 52,
      revenue: 0,
      run: "Run001",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend261",
      week: 1,
      revenue: 158618.4472818667,
      run: "Run004",
      spend: 202500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend262",
      week: 2,
      revenue: 156844.22687353997,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend263",
      week: 3,
      revenue: 287011.20973544643,
      run: "Run004",
      spend: 153750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend264",
      week: 4,
      revenue: 272184.139415276,
      run: "Run004",
      spend: 150000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend265",
      week: 5,
      revenue: 133478.58564188122,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend266",
      week: 6,
      revenue: 155685.65426317658,
      run: "Run004",
      spend: 93750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend267",
      week: 7,
      revenue: 84513.04825422728,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend268",
      week: 8,
      revenue: 238379.88424061247,
      run: "Run004",
      spend: 304425,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend269",
      week: 9,
      revenue: 227936.667950961,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend270",
      week: 10,
      revenue: 193518.0971399132,
      run: "Run004",
      spend: 78750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend271",
      week: 11,
      revenue: 328444.9425993316,
      run: "Run004",
      spend: 183750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend272",
      week: 12,
      revenue: 138140.9688293328,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend273",
      week: 13,
      revenue: 152024.77305096848,
      run: "Run004",
      spend: 97500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend274",
      week: 14,
      revenue: 97091.07700585693,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend275",
      week: 15,
      revenue: 178450.3241004201,
      run: "Run004",
      spend: 187500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend276",
      week: 16,
      revenue: 207971.9144106856,
      run: "Run004",
      spend: 172500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend277",
      week: 17,
      revenue: 113261.86109209122,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend278",
      week: 18,
      revenue: 83214.61245718422,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend279",
      week: 19,
      revenue: 66481.0138470556,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend280",
      week: 20,
      revenue: 56056.48224054903,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend281",
      week: 21,
      revenue: 123963.25134928488,
      run: "Run004",
      spend: 82500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend282",
      week: 22,
      revenue: 201350.08059833333,
      run: "Run004",
      spend: 225000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend283",
      week: 23,
      revenue: 237856.54847247843,
      run: "Run004",
      spend: 112500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend284",
      week: 24,
      revenue: 223281.70596598205,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend285",
      week: 25,
      revenue: 136410.53898154455,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend286",
      week: 26,
      revenue: 223512.2666440436,
      run: "Run004",
      spend: 86250,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend287",
      week: 27,
      revenue: 452667.1597691495,
      run: "Run004",
      spend: 410351.00000000023,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend288",
      week: 28,
      revenue: 343961.2461777798,
      run: "Run004",
      spend: 78750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend289",
      week: 29,
      revenue: 412972.085005921,
      run: "Run004",
      spend: 180000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend290",
      week: 30,
      revenue: 383949.58735124645,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend291",
      week: 31,
      revenue: 482538.51521947724,
      run: "Run004",
      spend: 326250,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend292",
      week: 32,
      revenue: 586851.8729574976,
      run: "Run004",
      spend: 168750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend293",
      week: 33,
      revenue: 502196.5730747338,
      run: "Run004",
      spend: 150000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend294",
      week: 34,
      revenue: 446944.3094294445,
      run: "Run004",
      spend: 408750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend295",
      week: 35,
      revenue: 336184.1875035644,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend296",
      week: 36,
      revenue: 248059.28972387096,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend297",
      week: 37,
      revenue: 235703.12204449004,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend298",
      week: 38,
      revenue: 223981.74252061849,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend299",
      week: 39,
      revenue: 114101.64607525161,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend300",
      week: 40,
      revenue: 133834.80247095524,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend301",
      week: 41,
      revenue: 79159.18492497074,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend302",
      week: 42,
      revenue: 55202.67500938522,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend303",
      week: 43,
      revenue: 40331.1962188684,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend304",
      week: 44,
      revenue: 88054.43522881056,
      run: "Run004",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend305",
      week: 45,
      revenue: 53399.137034246036,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend306",
      week: 46,
      revenue: 32186.074486880698,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend307",
      week: 47,
      revenue: 21688.857562623063,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend308",
      week: 48,
      revenue: 15779.013502021007,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend309",
      week: 49,
      revenue: 136367.37615509162,
      run: "Run004",
      spend: 185320,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend310",
      week: 50,
      revenue: 75583.75277640142,
      run: "Run004",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend311",
      week: 51,
      revenue: 241825.78447163847,
      run: "Run004",
      spend: 272186,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend312",
      week: 52,
      revenue: 210451.91179574488,
      run: "Run004",
      spend: 86250,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend313",
      week: 1,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend314",
      week: 2,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend315",
      week: 3,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend316",
      week: 4,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend317",
      week: 5,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend318",
      week: 6,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend319",
      week: 7,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend320",
      week: 8,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend321",
      week: 9,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend322",
      week: 10,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend323",
      week: 11,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend324",
      week: 12,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend325",
      week: 13,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend326",
      week: 14,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend327",
      week: 15,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend328",
      week: 16,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend329",
      week: 17,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend330",
      week: 18,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend331",
      week: 19,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend332",
      week: 20,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend333",
      week: 21,
      revenue: 0,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend334",
      week: 22,
      revenue: 10496.6171730259,
      run: "Run004",
      spend: 10500,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend335",
      week: 23,
      revenue: 5442.22755292451,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend336",
      week: 24,
      revenue: 2764.05418073085,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend337",
      week: 25,
      revenue: 1243.2463880298299,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend338",
      week: 26,
      revenue: 631.378469686951,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend339",
      week: 27,
      revenue: 306.538751687611,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend340",
      week: 28,
      revenue: 159.81557975682801,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend341",
      week: 29,
      revenue: 14929.2344893642,
      run: "Run004",
      spend: 14092,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend342",
      week: 30,
      revenue: 21169.7514412486,
      run: "Run004",
      spend: 12500,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend343",
      week: 31,
      revenue: 37518.0365733414,
      run: "Run004",
      spend: 23000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend344",
      week: 32,
      revenue: 20211.358620293802,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend345",
      week: 33,
      revenue: 7406.78045680362,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend346",
      week: 34,
      revenue: 3682.20719839695,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend347",
      week: 35,
      revenue: 1678.53099633041,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend348",
      week: 36,
      revenue: 850.347633824772,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend349",
      week: 37,
      revenue: 408.433928391285,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend350",
      week: 38,
      revenue: 182.73394083756799,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend351",
      week: 39,
      revenue: 86.153901834001,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend352",
      week: 40,
      revenue: 41.1450457944222,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend353",
      week: 41,
      revenue: 18.9274531487365,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend354",
      week: 42,
      revenue: 9.11594293942306,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend355",
      week: 43,
      revenue: 4.76908235709879,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend356",
      week: 44,
      revenue: 2.04480935417717,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend357",
      week: 45,
      revenue: 1.03087225892024,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend358",
      week: 46,
      revenue: 0.503676672864046,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend359",
      week: 47,
      revenue: 0.276729205882731,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend360",
      week: 48,
      revenue: 0.163152302596843,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend361",
      week: 49,
      revenue: 0.10138264495310799,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend362",
      week: 50,
      revenue: 0.060175974812869404,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend363",
      week: 51,
      revenue: 12931.1578302272,
      run: "Run004",
      spend: 10000,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend364",
      week: 52,
      revenue: 4814.98457172611,
      run: "Run004",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend365",
      week: 1,
      revenue: 69232.03944264939,
      run: "Run004",
      spend: 39000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend366",
      week: 2,
      revenue: 43347.791962469375,
      run: "Run004",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend367",
      week: 3,
      revenue: 56550.35601757288,
      run: "Run004",
      spend: 24500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend368",
      week: 4,
      revenue: 39457.690846819656,
      run: "Run004",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend369",
      week: 5,
      revenue: 50339.0379321505,
      run: "Run004",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend370",
      week: 6,
      revenue: 61598.9893900836,
      run: "Run004",
      spend: 21228.000000000102,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend371",
      week: 7,
      revenue: 47863.41594962703,
      run: "Run004",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend372",
      week: 8,
      revenue: 58515.7241480007,
      run: "Run004",
      spend: 21500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend373",
      week: 9,
      revenue: 47482.04764158617,
      run: "Run004",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend374",
      week: 10,
      revenue: 63881.6616108545,
      run: "Run004",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend375",
      week: 11,
      revenue: 69851.7577394444,
      run: "Run004",
      spend: 20500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend376",
      week: 12,
      revenue: 52129.8641837351,
      run: "Run004",
      spend: 12600,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend377",
      week: 13,
      revenue: 65793.7461618384,
      run: "Run004",
      spend: 23000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend378",
      week: 14,
      revenue: 65313.4976664597,
      run: "Run004",
      spend: 17000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend379",
      week: 15,
      revenue: 109792.5262755514,
      run: "Run004",
      spend: 48900,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend380",
      week: 16,
      revenue: 83676.036800685,
      run: "Run004",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend381",
      week: 17,
      revenue: 45330.40896231102,
      run: "Run004",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend382",
      week: 18,
      revenue: 69357.4988495904,
      run: "Run004",
      spend: 36200,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend383",
      week: 19,
      revenue: 76387.5914856495,
      run: "Run004",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend384",
      week: 20,
      revenue: 69680.6646605786,
      run: "Run004",
      spend: 15500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend385",
      week: 21,
      revenue: 110554.89293123758,
      run: "Run004",
      spend: 48000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend386",
      week: 22,
      revenue: 113751.88707820931,
      run: "Run004",
      spend: 26000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend387",
      week: 23,
      revenue: 119085.51836734722,
      run: "Run004",
      spend: 39500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend388",
      week: 24,
      revenue: 115254.95109653822,
      run: "Run004",
      spend: 25500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend389",
      week: 25,
      revenue: 114703.69782751905,
      run: "Run004",
      spend: 46500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend390",
      week: 26,
      revenue: 101960.03447951422,
      run: "Run004",
      spend: 14000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend391",
      week: 27,
      revenue: 115347.33167729658,
      run: "Run004",
      spend: 40400,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend392",
      week: 28,
      revenue: 102760.03518034688,
      run: "Run004",
      spend: 15000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend393",
      week: 29,
      revenue: 127624.78684528025,
      run: "Run004",
      spend: 50218,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend394",
      week: 30,
      revenue: 144957.70724362068,
      run: "Run004",
      spend: 40000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend395",
      week: 31,
      revenue: 147140.6646229756,
      run: "Run004",
      spend: 30500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend396",
      week: 32,
      revenue: 154584.0598531814,
      run: "Run004",
      spend: 49600,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend397",
      week: 33,
      revenue: 112604.78575453353,
      run: "Run004",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend398",
      week: 34,
      revenue: 91042.5726792467,
      run: "Run004",
      spend: 26989.000000000102,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend399",
      week: 35,
      revenue: 86125.53200043195,
      run: "Run004",
      spend: 26200,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend400",
      week: 36,
      revenue: 97333.76619166491,
      run: "Run004",
      spend: 21500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend401",
      week: 37,
      revenue: 94258.18958956239,
      run: "Run004",
      spend: 24500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend402",
      week: 38,
      revenue: 69851.84139320819,
      run: "Run004",
      spend: 12600,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend403",
      week: 39,
      revenue: 67557.93281089311,
      run: "Run004",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend404",
      week: 40,
      revenue: 65036.93913512613,
      run: "Run004",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend405",
      week: 41,
      revenue: 51086.24864653326,
      run: "Run004",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend406",
      week: 42,
      revenue: 61571.0490672206,
      run: "Run004",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend407",
      week: 43,
      revenue: 50592.44267941215,
      run: "Run004",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend408",
      week: 44,
      revenue: 60436.639670078424,
      run: "Run004",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend409",
      week: 45,
      revenue: 53432.61482883284,
      run: "Run004",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend410",
      week: 46,
      revenue: 42554.174185732576,
      run: "Run004",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend411",
      week: 47,
      revenue: 53380.69429255357,
      run: "Run004",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend412",
      week: 48,
      revenue: 61918.75392181448,
      run: "Run004",
      spend: 34000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend413",
      week: 49,
      revenue: 62231.63132867521,
      run: "Run004",
      spend: 14000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend414",
      week: 50,
      revenue: 75047.11692461962,
      run: "Run004",
      spend: 25000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend415",
      week: 51,
      revenue: 127537.52610134709,
      run: "Run004",
      spend: 49000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend416",
      week: 52,
      revenue: 108934.76378205392,
      run: "Run004",
      spend: 45551,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend417",
      week: 1,
      revenue: 38763.081206661394,
      run: "Run004",
      spend: 43500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend418",
      week: 2,
      revenue: 18505.3096211401,
      run: "Run004",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend419",
      week: 3,
      revenue: 21929.6158229177,
      run: "Run004",
      spend: 26000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend420",
      week: 4,
      revenue: 26369.489044820897,
      run: "Run004",
      spend: 24400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend421",
      week: 5,
      revenue: 17138.8092195881,
      run: "Run004",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend422",
      week: 6,
      revenue: 25329.761583825602,
      run: "Run004",
      spend: 25800,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend423",
      week: 7,
      revenue: 28572.9644028279,
      run: "Run004",
      spend: 24400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend424",
      week: 8,
      revenue: 17928.5380573461,
      run: "Run004",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend425",
      week: 9,
      revenue: 30044.1547589252,
      run: "Run004",
      spend: 29200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend426",
      week: 10,
      revenue: 19380.937999575297,
      run: "Run004",
      spend: 14000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend427",
      week: 11,
      revenue: 28846.5049611996,
      run: "Run004",
      spend: 29400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend428",
      week: 12,
      revenue: 32491.714142708504,
      run: "Run004",
      spend: 14400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend429",
      week: 13,
      revenue: 31072.1464337209,
      run: "Run004",
      spend: 25500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend430",
      week: 14,
      revenue: 31106.4612189979,
      run: "Run004",
      spend: 23500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend431",
      week: 15,
      revenue: 32056.162173175104,
      run: "Run004",
      spend: 23000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend432",
      week: 16,
      revenue: 50429.820164686804,
      run: "Run004",
      spend: 35400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend433",
      week: 17,
      revenue: 24810.9976793157,
      run: "Run004",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend434",
      week: 18,
      revenue: 28882.7585470356,
      run: "Run004",
      spend: 23891,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend435",
      week: 19,
      revenue: 31728.7067628728,
      run: "Run004",
      spend: 24000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend436",
      week: 20,
      revenue: 37192.393580873104,
      run: "Run004",
      spend: 23800,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend437",
      week: 21,
      revenue: 48096.4489090902,
      run: "Run004",
      spend: 31200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend438",
      week: 22,
      revenue: 49314.103278517796,
      run: "Run004",
      spend: 28000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend439",
      week: 23,
      revenue: 45492.5096490464,
      run: "Run004",
      spend: 27200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend440",
      week: 24,
      revenue: 47212.5244546339,
      run: "Run004",
      spend: 29400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend441",
      week: 25,
      revenue: 52929.6788783832,
      run: "Run004",
      spend: 26200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend442",
      week: 26,
      revenue: 57213.4826872213,
      run: "Run004",
      spend: 26200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend443",
      week: 27,
      revenue: 64705.6992556398,
      run: "Run004",
      spend: 30000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend444",
      week: 28,
      revenue: 62661.410550748995,
      run: "Run004",
      spend: 28200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend445",
      week: 29,
      revenue: 63700.0029568432,
      run: "Run004",
      spend: 28300,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend446",
      week: 30,
      revenue: 74409.68161495439,
      run: "Run004",
      spend: 34200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend447",
      week: 31,
      revenue: 71060.7432071961,
      run: "Run004",
      spend: 31159,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend448",
      week: 32,
      revenue: 78055.8809539506,
      run: "Run004",
      spend: 32700,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend449",
      week: 33,
      revenue: 79056.6780650244,
      run: "Run004",
      spend: 31600,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend450",
      week: 34,
      revenue: 55282.766562872705,
      run: "Run004",
      spend: 12600,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend451",
      week: 35,
      revenue: 55207.889512917296,
      run: "Run004",
      spend: 27100,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend452",
      week: 36,
      revenue: 53876.5008004079,
      run: "Run004",
      spend: 29500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend453",
      week: 37,
      revenue: 52462.3613278863,
      run: "Run004",
      spend: 28500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend454",
      week: 38,
      revenue: 48782.877726929204,
      run: "Run004",
      spend: 27400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend455",
      week: 39,
      revenue: 26473.1136192089,
      run: "Run004",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend456",
      week: 40,
      revenue: 29279.2148686695,
      run: "Run004",
      spend: 23200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend457",
      week: 41,
      revenue: 29326.6020795413,
      run: "Run004",
      spend: 23800,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend458",
      week: 42,
      revenue: 18061.888194769002,
      run: "Run004",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend459",
      week: 43,
      revenue: 23931.051770988,
      run: "Run004",
      spend: 22600,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend460",
      week: 44,
      revenue: 30652.6489524469,
      run: "Run004",
      spend: 25600,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend461",
      week: 45,
      revenue: 29415.8429193987,
      run: "Run004",
      spend: 23000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend462",
      week: 46,
      revenue: 30355.7875837245,
      run: "Run004",
      spend: 25600,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend463",
      week: 47,
      revenue: 18116.4180088134,
      run: "Run004",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend464",
      week: 48,
      revenue: 22093.3360582009,
      run: "Run004",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend465",
      week: 49,
      revenue: 25735.4826886378,
      run: "Run004",
      spend: 22000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend466",
      week: 50,
      revenue: 30805.5656371057,
      run: "Run004",
      spend: 22000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend467",
      week: 51,
      revenue: 39962.064646109,
      run: "Run004",
      spend: 31400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend468",
      week: 52,
      revenue: 61131.788698918695,
      run: "Run004",
      spend: 48000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend469",
      week: 1,
      revenue: 14927.7174508955,
      run: "Run004",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend470",
      week: 2,
      revenue: 17499.2485990874,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend471",
      week: 3,
      revenue: 22571.6517037713,
      run: "Run004",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend472",
      week: 4,
      revenue: 9048.09570929992,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend473",
      week: 5,
      revenue: 15390.9913334507,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend474",
      week: 6,
      revenue: 18551.1178827013,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend475",
      week: 7,
      revenue: 7879.26362028301,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend476",
      week: 8,
      revenue: 14035.9674103781,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend477",
      week: 9,
      revenue: 6284.34334746086,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend478",
      week: 10,
      revenue: 17281.929736128903,
      run: "Run004",
      spend: 11500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend479",
      week: 11,
      revenue: 23898.1862863633,
      run: "Run004",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend480",
      week: 12,
      revenue: 8252.47165216337,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend481",
      week: 13,
      revenue: 16104.2354564846,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend482",
      week: 14,
      revenue: 19239.700219511702,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend483",
      week: 15,
      revenue: 20095.7184779185,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend484",
      week: 16,
      revenue: 21661.0315713901,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend485",
      week: 17,
      revenue: 8006.75317647093,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend486",
      week: 18,
      revenue: 14427.1572835892,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend487",
      week: 19,
      revenue: 18007.8349655868,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend488",
      week: 20,
      revenue: 7794.34023623919,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend489",
      week: 21,
      revenue: 21236.3903213228,
      run: "Run004",
      spend: 13290,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend490",
      week: 22,
      revenue: 24435.168864718602,
      run: "Run004",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend491",
      week: 23,
      revenue: 27688.779759594,
      run: "Run004",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend492",
      week: 24,
      revenue: 30417.496911821,
      run: "Run004",
      spend: 14000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend493",
      week: 25,
      revenue: 23482.5902097025,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend494",
      week: 26,
      revenue: 20928.6494435195,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend495",
      week: 27,
      revenue: 21495.235295976803,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend496",
      week: 28,
      revenue: 21904.215006425202,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend497",
      week: 29,
      revenue: 21520.9147777375,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend498",
      week: 30,
      revenue: 27711.3214478603,
      run: "Run004",
      spend: 12500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend499",
      week: 31,
      revenue: 27618.8077467513,
      run: "Run004",
      spend: 11500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend500",
      week: 32,
      revenue: 26639.599306973898,
      run: "Run004",
      spend: 10500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend501",
      week: 33,
      revenue: 22525.7199596459,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend502",
      week: 34,
      revenue: 8178.40722222941,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend503",
      week: 35,
      revenue: 14672.1177537029,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend504",
      week: 36,
      revenue: 26041.4838784572,
      run: "Run004",
      spend: 15000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend505",
      week: 37,
      revenue: 28183.0938764714,
      run: "Run004",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend506",
      week: 38,
      revenue: 10834.6384967626,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend507",
      week: 39,
      revenue: 15188.7701359991,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend508",
      week: 40,
      revenue: 18619.0364180196,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend509",
      week: 41,
      revenue: 7669.90808507838,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend510",
      week: 42,
      revenue: 16000.8823164944,
      run: "Run004",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend511",
      week: 43,
      revenue: 7275.06762662445,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend512",
      week: 44,
      revenue: 14951.3127539071,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend513",
      week: 45,
      revenue: 16953.0804199671,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend514",
      week: 46,
      revenue: 7078.54923197334,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend515",
      week: 47,
      revenue: 13300.1175926871,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend516",
      week: 48,
      revenue: 5680.13349078012,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend517",
      week: 49,
      revenue: 2380.61179800848,
      run: "Run004",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend518",
      week: 50,
      revenue: 11608.205603141,
      run: "Run004",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend519",
      week: 51,
      revenue: 26901.2091544887,
      run: "Run004",
      spend: 16000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend520",
      week: 52,
      revenue: 21966.5453296031,
      run: "Run004",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend521",
      week: 1,
      revenue: 146319.6816617383,
      run: "Run002",
      spend: 145500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend522",
      week: 2,
      revenue: 148640.77170548312,
      run: "Run002",
      spend: 70500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend523",
      week: 3,
      revenue: 247797.84987469323,
      run: "Run002",
      spend: 131000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend524",
      week: 4,
      revenue: 210468.1705223049,
      run: "Run002",
      spend: 99000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend525",
      week: 5,
      revenue: 126151.7275073811,
      run: "Run002",
      spend: 34500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend526",
      week: 6,
      revenue: 140097.5764179151,
      run: "Run002",
      spend: 69000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend527",
      week: 7,
      revenue: 112736.1539919923,
      run: "Run002",
      spend: 43500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend528",
      week: 8,
      revenue: 163232.0556626346,
      run: "Run002",
      spend: 108500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend529",
      week: 9,
      revenue: 157698.1764278192,
      run: "Run002",
      spend: 52000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend530",
      week: 10,
      revenue: 176420.10709454902,
      run: "Run002",
      spend: 98000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend531",
      week: 11,
      revenue: 250923.24715294328,
      run: "Run002",
      spend: 129500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend532",
      week: 12,
      revenue: 128551.5297186123,
      run: "Run002",
      spend: 39500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend533",
      week: 13,
      revenue: 105039.14925870941,
      run: "Run002",
      spend: 53000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend534",
      week: 14,
      revenue: 94541.57053755801,
      run: "Run002",
      spend: 39500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend535",
      week: 15,
      revenue: 90135.6996451137,
      run: "Run002",
      spend: 41000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend536",
      week: 16,
      revenue: 139243.1817475096,
      run: "Run002",
      spend: 98500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend537",
      week: 17,
      revenue: 102104.69802089201,
      run: "Run002",
      spend: 37500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend538",
      week: 18,
      revenue: 55043.338216702105,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend539",
      week: 19,
      revenue: 35040.70495264159,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend540",
      week: 20,
      revenue: 48114.7921066066,
      run: "Run002",
      spend: 22000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend541",
      week: 21,
      revenue: 101133.84443257871,
      run: "Run002",
      spend: 74500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend542",
      week: 22,
      revenue: 141386.0296025968,
      run: "Run002",
      spend: 100000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend543",
      week: 23,
      revenue: 179036.9649700583,
      run: "Run002",
      spend: 101000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend544",
      week: 24,
      revenue: 174889.8598530872,
      run: "Run002",
      spend: 76500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend545",
      week: 25,
      revenue: 129481.0007347051,
      run: "Run002",
      spend: 33500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend546",
      week: 26,
      revenue: 160556.8294260749,
      run: "Run002",
      spend: 59500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend547",
      week: 27,
      revenue: 358142.35954438837,
      run: "Run002",
      spend: 246096.380778881,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend548",
      week: 28,
      revenue: 318545.8661594509,
      run: "Run002",
      spend: 118500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend549",
      week: 29,
      revenue: 304573.6257814183,
      run: "Run002",
      spend: 106000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend550",
      week: 30,
      revenue: 329530.9083641647,
      run: "Run002",
      spend: 96500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend551",
      week: 31,
      revenue: 463280.80321966147,
      run: "Run002",
      spend: 235000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend552",
      week: 32,
      revenue: 541688.5303418442,
      run: "Run002",
      spend: 193500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend553",
      week: 33,
      revenue: 446860.4828394673,
      run: "Run002",
      spend: 117500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend554",
      week: 34,
      revenue: 374951.1523822409,
      run: "Run002",
      spend: 212228,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend555",
      week: 35,
      revenue: 315651.9922464226,
      run: "Run002",
      spend: 58500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend556",
      week: 36,
      revenue: 215713.6988559332,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend557",
      week: 37,
      revenue: 171909.9163721233,
      run: "Run002",
      spend: 35000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend558",
      week: 38,
      revenue: 147386.7282860059,
      run: "Run002",
      spend: 43000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend559",
      week: 39,
      revenue: 69158.9149520298,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend560",
      week: 40,
      revenue: 76174.13823902511,
      run: "Run002",
      spend: 30500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend561",
      week: 41,
      revenue: 50061.52667369683,
      run: "Run002",
      spend: 10000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend562",
      week: 42,
      revenue: 37348.9299628227,
      run: "Run002",
      spend: 10000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend563",
      week: 43,
      revenue: 24017.1055796176,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend564",
      week: 44,
      revenue: 35675.91923160121,
      run: "Run002",
      spend: 22000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend565",
      week: 45,
      revenue: 21926.0801967487,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend566",
      week: 46,
      revenue: 13833.137453546842,
      run: "Run002",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend567",
      week: 47,
      revenue: 19727.99661430117,
      run: "Run002",
      spend: 10000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend568",
      week: 48,
      revenue: 24662.35354661355,
      run: "Run002",
      spend: 13500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend569",
      week: 49,
      revenue: 78740.9765723945,
      run: "Run002",
      spend: 58000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend570",
      week: 50,
      revenue: 69671.34704740919,
      run: "Run002",
      spend: 38500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend571",
      week: 51,
      revenue: 162072.3093705529,
      run: "Run002",
      spend: 143500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend572",
      week: 52,
      revenue: 129147.044721513,
      run: "Run002",
      spend: 51500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend573",
      week: 1,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend574",
      week: 2,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend575",
      week: 3,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend576",
      week: 4,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend577",
      week: 5,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend578",
      week: 6,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend579",
      week: 7,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend580",
      week: 8,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend581",
      week: 9,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend582",
      week: 10,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend583",
      week: 11,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend584",
      week: 12,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend585",
      week: 13,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend586",
      week: 14,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend587",
      week: 15,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend588",
      week: 16,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend589",
      week: 17,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend590",
      week: 18,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend591",
      week: 19,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend592",
      week: 20,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend593",
      week: 21,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend594",
      week: 22,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend595",
      week: 23,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend596",
      week: 24,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend597",
      week: 25,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend598",
      week: 26,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend599",
      week: 27,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend600",
      week: 28,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend601",
      week: 29,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend602",
      week: 30,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend603",
      week: 31,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend604",
      week: 32,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend605",
      week: 33,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend606",
      week: 34,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend607",
      week: 35,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend608",
      week: 36,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend609",
      week: 37,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend610",
      week: 38,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend611",
      week: 39,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend612",
      week: 40,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend613",
      week: 41,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend614",
      week: 42,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend615",
      week: 43,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend616",
      week: 44,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend617",
      week: 45,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend618",
      week: 46,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend619",
      week: 47,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend620",
      week: 48,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend621",
      week: 49,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend622",
      week: 50,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend623",
      week: 51,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend624",
      week: 52,
      revenue: 0,
      run: "Run002",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend625",
      week: 1,
      revenue: 57546.027560589704,
      run: "Run002",
      spend: 25594,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend626",
      week: 2,
      revenue: 37140.1474407192,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend627",
      week: 3,
      revenue: 44684.0982020047,
      run: "Run002",
      spend: 11000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend628",
      week: 4,
      revenue: 25763.4377793315,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend629",
      week: 5,
      revenue: 32638.934755037193,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend630",
      week: 6,
      revenue: 53468.961072881895,
      run: "Run002",
      spend: 22500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend631",
      week: 7,
      revenue: 38629.4847008614,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend632",
      week: 8,
      revenue: 39672.9233202821,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend633",
      week: 9,
      revenue: 25489.46852577421,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend634",
      week: 10,
      revenue: 35480.45056413457,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend635",
      week: 11,
      revenue: 60311.0558117818,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend636",
      week: 12,
      revenue: 37630.444037428504,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend637",
      week: 13,
      revenue: 54377.3609101203,
      run: "Run002",
      spend: 21390.8868623431,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend638",
      week: 14,
      revenue: 56636.1770670429,
      run: "Run002",
      spend: 11000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend639",
      week: 15,
      revenue: 85796.607377868,
      run: "Run002",
      spend: 27000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend640",
      week: 16,
      revenue: 80173.9279760123,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend641",
      week: 17,
      revenue: 44923.58163467402,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend642",
      week: 18,
      revenue: 44145.166281754864,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend643",
      week: 19,
      revenue: 70035.5440913067,
      run: "Run002",
      spend: 30000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend644",
      week: 20,
      revenue: 66546.359673211,
      run: "Run002",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend645",
      week: 21,
      revenue: 96426.6212494287,
      run: "Run002",
      spend: 32500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend646",
      week: 22,
      revenue: 86074.1212109715,
      run: "Run002",
      spend: 10500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend647",
      week: 23,
      revenue: 91169.9368160957,
      run: "Run002",
      spend: 22146,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend648",
      week: 24,
      revenue: 108198.45803991119,
      run: "Run002",
      spend: 33000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend649",
      week: 25,
      revenue: 82835.93900369431,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend650",
      week: 26,
      revenue: 85529.3734083967,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend651",
      week: 27,
      revenue: 94343.7751432558,
      run: "Run002",
      spend: 21500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend652",
      week: 28,
      revenue: 91906.6411192604,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend653",
      week: 29,
      revenue: 114026.92799473618,
      run: "Run002",
      spend: 42083.9946293635,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend654",
      week: 30,
      revenue: 136667.90062518194,
      run: "Run002",
      spend: 35500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend655",
      week: 31,
      revenue: 136677.74215550016,
      run: "Run002",
      spend: 25000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend656",
      week: 32,
      revenue: 128355.33563504995,
      run: "Run002",
      spend: 26500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend657",
      week: 33,
      revenue: 99402.29465850042,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend658",
      week: 34,
      revenue: 81043.45973437889,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend659",
      week: 35,
      revenue: 68017.98309618852,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend660",
      week: 36,
      revenue: 85936.37644780784,
      run: "Run002",
      spend: 21000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend661",
      week: 37,
      revenue: 96698.36779066114,
      run: "Run002",
      spend: 30000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend662",
      week: 38,
      revenue: 60128.34938373539,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend663",
      week: 39,
      revenue: 48789.46094395636,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend664",
      week: 40,
      revenue: 58045.54156920555,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend665",
      week: 41,
      revenue: 38958.58702308172,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend666",
      week: 42,
      revenue: 41796.82822246076,
      run: "Run002",
      spend: 10500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend667",
      week: 43,
      revenue: 26639.959320251284,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend668",
      week: 44,
      revenue: 51512.048849695115,
      run: "Run002",
      spend: 20500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend669",
      week: 45,
      revenue: 47505.86473549689,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend670",
      week: 46,
      revenue: 30309.020532249986,
      run: "Run002",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend671",
      week: 47,
      revenue: 32024.74104198467,
      run: "Run002",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend672",
      week: 48,
      revenue: 43808.16559992575,
      run: "Run002",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend673",
      week: 49,
      revenue: 49787.67171720613,
      run: "Run002",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend674",
      week: 50,
      revenue: 62627.64258803266,
      run: "Run002",
      spend: 21000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend675",
      week: 51,
      revenue: 114868.19954962192,
      run: "Run002",
      spend: 38500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend676",
      week: 52,
      revenue: 91884.60065368706,
      run: "Run002",
      spend: 25500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend677",
      week: 1,
      revenue: 46510.363623308294,
      run: "Run002",
      spend: 27305.842406211203,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend678",
      week: 2,
      revenue: 33633.1080030328,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend679",
      week: 3,
      revenue: 35723.42238628686,
      run: "Run002",
      spend: 13500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend680",
      week: 4,
      revenue: 15678.47845322699,
      run: "Run002",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend681",
      week: 5,
      revenue: 22107.69543587136,
      run: "Run002",
      spend: 10500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend682",
      week: 6,
      revenue: 40178.491685672096,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend683",
      week: 7,
      revenue: 31522.025203843958,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend684",
      week: 8,
      revenue: 26786.3937656855,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend685",
      week: 9,
      revenue: 28375.3525661967,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend686",
      week: 10,
      revenue: 35192.5319793115,
      run: "Run002",
      spend: 13500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend687",
      week: 11,
      revenue: 54576.12689433609,
      run: "Run002",
      spend: 24000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend688",
      week: 12,
      revenue: 24613.5524176665,
      run: "Run002",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend689",
      week: 13,
      revenue: 29841.11120467718,
      run: "Run002",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend690",
      week: 14,
      revenue: 44882.339886379705,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend691",
      week: 15,
      revenue: 37251.4186477716,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend692",
      week: 16,
      revenue: 66275.7199079649,
      run: "Run002",
      spend: 27000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend693",
      week: 17,
      revenue: 27359.9736622876,
      run: "Run002",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend694",
      week: 18,
      revenue: 41306.454421436894,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend695",
      week: 19,
      revenue: 37956.8102612877,
      run: "Run002",
      spend: 11000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend696",
      week: 20,
      revenue: 47906.131015099396,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend697",
      week: 21,
      revenue: 67728.4951389522,
      run: "Run002",
      spend: 24000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend698",
      week: 22,
      revenue: 71917.54706560989,
      run: "Run002",
      spend: 21500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend699",
      week: 23,
      revenue: 73135.1964555548,
      run: "Run002",
      spend: 22000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend700",
      week: 24,
      revenue: 78227.96358074181,
      run: "Run002",
      spend: 25000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend701",
      week: 25,
      revenue: 76056.6615491056,
      run: "Run002",
      spend: 23000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend702",
      week: 26,
      revenue: 73845.19760687041,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend703",
      week: 27,
      revenue: 80179.483829904,
      run: "Run002",
      spend: 21500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend704",
      week: 28,
      revenue: 79002.1568882367,
      run: "Run002",
      spend: 21000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend705",
      week: 29,
      revenue: 78428.8572893931,
      run: "Run002",
      spend: 20500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend706",
      week: 30,
      revenue: 98043.9963609557,
      run: "Run002",
      spend: 27000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend707",
      week: 31,
      revenue: 94596.58093350459,
      run: "Run002",
      spend: 23500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend708",
      week: 32,
      revenue: 101617.0511216286,
      run: "Run002",
      spend: 25500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend709",
      week: 33,
      revenue: 100048.80937021271,
      run: "Run002",
      spend: 26000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend710",
      week: 34,
      revenue: 45773.68781545201,
      run: "Run002",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend711",
      week: 35,
      revenue: 59203.6078657436,
      run: "Run002",
      spend: 20500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend712",
      week: 36,
      revenue: 72111.87561024481,
      run: "Run002",
      spend: 23000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend713",
      week: 37,
      revenue: 76496.1066405998,
      run: "Run002",
      spend: 22500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend714",
      week: 38,
      revenue: 63462.573311236905,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend715",
      week: 39,
      revenue: 41712.358708604006,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend716",
      week: 40,
      revenue: 38629.1077176552,
      run: "Run002",
      spend: 14000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend717",
      week: 41,
      revenue: 32084.099363308702,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend718",
      week: 42,
      revenue: 32971.1500151267,
      run: "Run002",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend719",
      week: 43,
      revenue: 28542.37076192392,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend720",
      week: 44,
      revenue: 42497.3748428229,
      run: "Run002",
      spend: 20000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend721",
      week: 45,
      revenue: 33548.4742098567,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend722",
      week: 46,
      revenue: 29091.0652755701,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend723",
      week: 47,
      revenue: 28198.89731473368,
      run: "Run002",
      spend: 11000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend724",
      week: 48,
      revenue: 13373.4249552361,
      run: "Run002",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend725",
      week: 49,
      revenue: 19093.220574491977,
      run: "Run002",
      spend: 10500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend726",
      week: 50,
      revenue: 26599.70648078793,
      run: "Run002",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend727",
      week: 51,
      revenue: 61810.58058265271,
      run: "Run002",
      spend: 27000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend728",
      week: 52,
      revenue: 75101.0030905837,
      run: "Run002",
      spend: 28678,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend729",
      week: 1,
      revenue: 13923.3990119216,
      run: "Run002",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend730",
      week: 2,
      revenue: 6245.83020616845,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend731",
      week: 3,
      revenue: 18592.3449768095,
      run: "Run002",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend732",
      week: 4,
      revenue: 7158.1730661099,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend733",
      week: 5,
      revenue: 14763.344160842,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend734",
      week: 6,
      revenue: 18307.5647426959,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend735",
      week: 7,
      revenue: 7755.6016649496,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend736",
      week: 8,
      revenue: 13998.1292624225,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend737",
      week: 9,
      revenue: 6264.72633218437,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend738",
      week: 10,
      revenue: 15754.8770145811,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend739",
      week: 11,
      revenue: 21233.4353972656,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend740",
      week: 12,
      revenue: 7149.92099979552,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend741",
      week: 13,
      revenue: 15691.8787614222,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend742",
      week: 14,
      revenue: 19081.4994652487,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend743",
      week: 15,
      revenue: 20034.759382437103,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend744",
      week: 16,
      revenue: 21635.561651102398,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend745",
      week: 17,
      revenue: 7995.28246436501,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend746",
      week: 18,
      revenue: 3580.47113878356,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend747",
      week: 19,
      revenue: 14161.2334418876,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend748",
      week: 20,
      revenue: 5889.84372136791,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend749",
      week: 21,
      revenue: 18707.0905004684,
      run: "Run002",
      spend: 11500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend750",
      week: 22,
      revenue: 22376.672505701103,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend751",
      week: 23,
      revenue: 25283.7013529438,
      run: "Run002",
      spend: 10500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend752",
      week: 24,
      revenue: 27593.4169550248,
      run: "Run002",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend753",
      week: 25,
      revenue: 11394.8532147438,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend754",
      week: 26,
      revenue: 16796.2740178517,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend755",
      week: 27,
      revenue: 19821.3539871062,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend756",
      week: 28,
      revenue: 23080.1593136271,
      run: "Run002",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend757",
      week: 29,
      revenue: 10970.135125340401,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend758",
      week: 30,
      revenue: 24778.2789136204,
      run: "Run002",
      spend: 13476.895323201701,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend759",
      week: 31,
      revenue: 24900.6690401751,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend760",
      week: 32,
      revenue: 25005.3055479601,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend761",
      week: 33,
      revenue: 21943.3677473168,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend762",
      week: 34,
      revenue: 7919.691898308901,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend763",
      week: 35,
      revenue: 3664.5934556367597,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend764",
      week: 36,
      revenue: 20156.4770756617,
      run: "Run002",
      spend: 13000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend765",
      week: 37,
      revenue: 26308.336243016,
      run: "Run002",
      spend: 12500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend766",
      week: 38,
      revenue: 9933.46925590712,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend767",
      week: 39,
      revenue: 4004.91727218357,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend768",
      week: 40,
      revenue: 14593.7064809777,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend769",
      week: 41,
      revenue: 5770.17690857663,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend770",
      week: 42,
      revenue: 14432.846869938601,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend771",
      week: 43,
      revenue: 6454.08297781,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend772",
      week: 44,
      revenue: 14691.9386766569,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend773",
      week: 45,
      revenue: 6476.4316537616305,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend774",
      week: 46,
      revenue: 2437.6032939122297,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend775",
      week: 47,
      revenue: 11891.6891063076,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend776",
      week: 48,
      revenue: 4996.34056747313,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend777",
      week: 49,
      revenue: 2079.5099521543298,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend778",
      week: 50,
      revenue: 888.552176175446,
      run: "Run002",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend779",
      week: 51,
      revenue: 21997.924646221003,
      run: "Run002",
      spend: 15000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend780",
      week: 52,
      revenue: 19456.757860906,
      run: "Run002",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend781",
      week: 1,
      revenue: 154576.5507641948,
      run: "Run003",
      spend: 191250,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend782",
      week: 2,
      revenue: 91712.9221333242,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend783",
      week: 3,
      revenue: 285365.4051816727,
      run: "Run003",
      spend: 191476,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend784",
      week: 4,
      revenue: 138247.7607385545,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend785",
      week: 5,
      revenue: 70432.87964133587,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend786",
      week: 6,
      revenue: 123982.34447161383,
      run: "Run003",
      spend: 86250,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend787",
      week: 7,
      revenue: 68216.58784925412,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend788",
      week: 8,
      revenue: 59220.004780480755,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend789",
      week: 9,
      revenue: 152085.4302561018,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend790",
      week: 10,
      revenue: 145544.2456471356,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend791",
      week: 11,
      revenue: 273958.5630834428,
      run: "Run003",
      spend: 150000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend792",
      week: 12,
      revenue: 108793.07752790471,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend793",
      week: 13,
      revenue: 121050.96256567848,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend794",
      week: 14,
      revenue: 73699.12211050365,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend795",
      week: 15,
      revenue: 163723.78449548647,
      run: "Run003",
      spend: 172500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend796",
      week: 16,
      revenue: 128932.0932001979,
      run: "Run003",
      spend: 90000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend797",
      week: 17,
      revenue: 74393.0188406693,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend798",
      week: 18,
      revenue: 58883.14148566988,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend799",
      week: 19,
      revenue: 50486.4550211609,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend800",
      week: 20,
      revenue: 45643.67563575535,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend801",
      week: 21,
      revenue: 44279.50859247695,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend802",
      week: 22,
      revenue: 118854.9613609207,
      run: "Run003",
      spend: 82500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend803",
      week: 23,
      revenue: 168690.42824252817,
      run: "Run003",
      spend: 101250,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend804",
      week: 24,
      revenue: 104240.25830064263,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend805",
      week: 25,
      revenue: 59289.800321590345,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend806",
      week: 26,
      revenue: 157217.20730336037,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend807",
      week: 27,
      revenue: 407537.85833388905,
      run: "Run003",
      spend: 360000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend808",
      week: 28,
      revenue: 310338.01180639915,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend809",
      week: 29,
      revenue: 274579.8601848292,
      run: "Run003",
      spend: 78750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend810",
      week: 30,
      revenue: 329700.9443557627,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend811",
      week: 31,
      revenue: 440969.2984233563,
      run: "Run003",
      spend: 293257,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend812",
      week: 32,
      revenue: 566415.8916696656,
      run: "Run003",
      spend: 168750,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend813",
      week: 33,
      revenue: 405258.2502327281,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend814",
      week: 34,
      revenue: 423844.30796700506,
      run: "Run003",
      spend: 367500,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend815",
      week: 35,
      revenue: 309024.20702666126,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend816",
      week: 36,
      revenue: 227841.5258973107,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend817",
      week: 37,
      revenue: 161386.58922970557,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend818",
      week: 38,
      revenue: 105227.94197293295,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend819",
      week: 39,
      revenue: 71760.49916965,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend820",
      week: 40,
      revenue: 56856.09190636527,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend821",
      week: 41,
      revenue: 42840.01379774291,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend822",
      week: 42,
      revenue: 34297.347616966596,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend823",
      week: 43,
      revenue: 28603.748824078495,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend824",
      week: 44,
      revenue: 23497.77455586694,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend825",
      week: 45,
      revenue: 18997.634908697113,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend826",
      week: 46,
      revenue: 15181.688288165016,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend827",
      week: 47,
      revenue: 12045.046768689723,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend828",
      week: 48,
      revenue: 10302.585416056834,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend829",
      week: 49,
      revenue: 35211.87743900071,
      run: "Run003",
      spend: 75000,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend830",
      week: 50,
      revenue: 34635.18771261821,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend831",
      week: 51,
      revenue: 209373.07782861462,
      run: "Run003",
      spend: 230930,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend832",
      week: 52,
      revenue: 131575.93440045477,
      run: "Run003",
      spend: 0,
      channel: "Channel001",
    },
    {
      id: "WeeklySpend833",
      week: 1,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend834",
      week: 2,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend835",
      week: 3,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend836",
      week: 4,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend837",
      week: 5,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend838",
      week: 6,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend839",
      week: 7,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend840",
      week: 8,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend841",
      week: 9,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend842",
      week: 10,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend843",
      week: 11,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend844",
      week: 12,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend845",
      week: 13,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend846",
      week: 14,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend847",
      week: 15,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend848",
      week: 16,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend849",
      week: 17,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend850",
      week: 18,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend851",
      week: 19,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend852",
      week: 20,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend853",
      week: 21,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend854",
      week: 22,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend855",
      week: 23,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend856",
      week: 24,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend857",
      week: 25,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend858",
      week: 26,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend859",
      week: 27,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend860",
      week: 28,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend861",
      week: 29,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend862",
      week: 30,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend863",
      week: 31,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend864",
      week: 32,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend865",
      week: 33,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend866",
      week: 34,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend867",
      week: 35,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend868",
      week: 36,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend869",
      week: 37,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend870",
      week: 38,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend871",
      week: 39,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend872",
      week: 40,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend873",
      week: 41,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend874",
      week: 42,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend875",
      week: 43,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend876",
      week: 44,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend877",
      week: 45,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend878",
      week: 46,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend879",
      week: 47,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend880",
      week: 48,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend881",
      week: 49,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend882",
      week: 50,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend883",
      week: 51,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend884",
      week: 52,
      revenue: 0,
      run: "Run003",
      spend: 0,
      channel: "Channel004",
    },
    {
      id: "WeeklySpend885",
      week: 1,
      revenue: 66689.93963375466,
      run: "Run003",
      spend: 35000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend886",
      week: 2,
      revenue: 40831.67163959944,
      run: "Run003",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend887",
      week: 3,
      revenue: 54795.90872249037,
      run: "Run003",
      spend: 22500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend888",
      week: 4,
      revenue: 37310.65909121258,
      run: "Run003",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend889",
      week: 5,
      revenue: 49979.60580282291,
      run: "Run003",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend890",
      week: 6,
      revenue: 51833.36944380817,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend891",
      week: 7,
      revenue: 42337.418178888096,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend892",
      week: 8,
      revenue: 55590.63090017886,
      run: "Run003",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend893",
      week: 9,
      revenue: 44930.06580080897,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend894",
      week: 10,
      revenue: 53948.544073881916,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend895",
      week: 11,
      revenue: 67269.3589918713,
      run: "Run003",
      spend: 22500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend896",
      week: 12,
      revenue: 50430.55573379318,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend897",
      week: 13,
      revenue: 55096.20599111696,
      run: "Run003",
      spend: 11000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend898",
      week: 14,
      revenue: 58383.8907187547,
      run: "Run003",
      spend: 16500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend899",
      week: 15,
      revenue: 104775.15354494749,
      run: "Run003",
      spend: 44078,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend900",
      week: 16,
      revenue: 80085.5054509141,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend901",
      week: 17,
      revenue: 42529.80628322742,
      run: "Run003",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend902",
      week: 18,
      revenue: 66125.9824831692,
      run: "Run003",
      spend: 32000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend903",
      week: 19,
      revenue: 72501.7754385679,
      run: "Run003",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend904",
      week: 20,
      revenue: 64945.679923177006,
      run: "Run003",
      spend: 13000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend905",
      week: 21,
      revenue: 103079.71468211009,
      run: "Run003",
      spend: 39000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend906",
      week: 22,
      revenue: 93139.3473339515,
      run: "Run003",
      spend: 14000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend907",
      week: 23,
      revenue: 110233.44202975108,
      run: "Run003",
      spend: 37000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend908",
      week: 24,
      revenue: 110195.17798036309,
      run: "Run003",
      spend: 25000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend909",
      week: 25,
      revenue: 103341.04003261539,
      run: "Run003",
      spend: 32000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend910",
      week: 26,
      revenue: 90486.89460085978,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend911",
      week: 27,
      revenue: 106386.8062114717,
      run: "Run003",
      spend: 36300,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend912",
      week: 28,
      revenue: 94493.1914043906,
      run: "Run003",
      spend: 12856,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend913",
      week: 29,
      revenue: 112562.92885578884,
      run: "Run003",
      spend: 37506,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend914",
      week: 30,
      revenue: 140891.71769295845,
      run: "Run003",
      spend: 38000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend915",
      week: 31,
      revenue: 129142.717893667,
      run: "Run003",
      spend: 20000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend916",
      week: 32,
      revenue: 149627.54961876615,
      run: "Run003",
      spend: 45721,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend917",
      week: 33,
      revenue: 97820.41904299633,
      run: "Run003",
      spend: 0,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend918",
      week: 34,
      revenue: 84851.17788256504,
      run: "Run003",
      spend: 27000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend919",
      week: 35,
      revenue: 73204.19130522471,
      run: "Run003",
      spend: 13800,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend920",
      week: 36,
      revenue: 91962.64495219247,
      run: "Run003",
      spend: 23000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend921",
      week: 37,
      revenue: 88006.77592617643,
      run: "Run003",
      spend: 21000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend922",
      week: 38,
      revenue: 66425.21672767437,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend923",
      week: 39,
      revenue: 67207.6574850161,
      run: "Run003",
      spend: 21500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend924",
      week: 40,
      revenue: 56222.91211882435,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend925",
      week: 41,
      revenue: 46363.71768787349,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend926",
      week: 42,
      revenue: 49635.600551296266,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend927",
      week: 43,
      revenue: 42773.318855128986,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend928",
      week: 44,
      revenue: 56958.19476650717,
      run: "Run003",
      spend: 22000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend929",
      week: 45,
      revenue: 51522.28969477252,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend930",
      week: 46,
      revenue: 41502.67636126731,
      run: "Run003",
      spend: 12000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend931",
      week: 47,
      revenue: 43843.40473934579,
      run: "Run003",
      spend: 10000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend932",
      week: 48,
      revenue: 57288.31967953671,
      run: "Run003",
      spend: 33500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend933",
      week: 49,
      revenue: 59056.37069337874,
      run: "Run003",
      spend: 13000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend934",
      week: 50,
      revenue: 72993.83325693334,
      run: "Run003",
      spend: 24500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend935",
      week: 51,
      revenue: 125086.52518770078,
      run: "Run003",
      spend: 47000,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend936",
      week: 52,
      revenue: 97879.59326117435,
      run: "Run003",
      spend: 26500,
      channel: "Channel005",
    },
    {
      id: "WeeklySpend937",
      week: 1,
      revenue: 58245.1571584119,
      run: "Run003",
      spend: 38800,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend938",
      week: 2,
      revenue: 40636.78472334921,
      run: "Run003",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend939",
      week: 3,
      revenue: 39013.31481868586,
      run: "Run003",
      spend: 12500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend940",
      week: 4,
      revenue: 35164.2543715581,
      run: "Run003",
      spend: 15600,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend941",
      week: 5,
      revenue: 34708.0730894923,
      run: "Run003",
      spend: 10500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend942",
      week: 6,
      revenue: 48764.2709116428,
      run: "Run003",
      spend: 24000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend943",
      week: 7,
      revenue: 37460.73432483,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend944",
      week: 8,
      revenue: 35624.6554968185,
      run: "Run003",
      spend: 11500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend945",
      week: 9,
      revenue: 36661.33556759247,
      run: "Run003",
      spend: 16200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend946",
      week: 10,
      revenue: 41215.389529532404,
      run: "Run003",
      spend: 13000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend947",
      week: 11,
      revenue: 59665.7685065245,
      run: "Run003",
      spend: 26700,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend948",
      week: 12,
      revenue: 43168.124401508,
      run: "Run003",
      spend: 13800,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend949",
      week: 13,
      revenue: 41291.9046462491,
      run: "Run003",
      spend: 11000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend950",
      week: 14,
      revenue: 55501.7254954249,
      run: "Run003",
      spend: 27400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend951",
      week: 15,
      revenue: 45638.374935843196,
      run: "Run003",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend952",
      week: 16,
      revenue: 76977.4468327993,
      run: "Run003",
      spend: 34200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend953",
      week: 17,
      revenue: 33977.399602548205,
      run: "Run003",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend954",
      week: 18,
      revenue: 45818.7950518506,
      run: "Run003",
      spend: 22000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend955",
      week: 19,
      revenue: 55434.9884810628,
      run: "Run003",
      spend: 23500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend956",
      week: 20,
      revenue: 46382.8349422397,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend957",
      week: 21,
      revenue: 74296.8528374743,
      run: "Run003",
      spend: 28900,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend958",
      week: 22,
      revenue: 79357.3906321583,
      run: "Run003",
      spend: 25200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend959",
      week: 23,
      revenue: 79357.0094040857,
      run: "Run003",
      spend: 24500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend960",
      week: 24,
      revenue: 81032.5111684197,
      run: "Run003",
      spend: 24735,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend961",
      week: 25,
      revenue: 79118.2639866079,
      run: "Run003",
      spend: 24400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend962",
      week: 26,
      revenue: 81036.96979123571,
      run: "Run003",
      spend: 25000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend963",
      week: 27,
      revenue: 89139.1178989609,
      run: "Run003",
      spend: 26200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend964",
      week: 28,
      revenue: 87503.7087691454,
      run: "Run003",
      spend: 24400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend965",
      week: 29,
      revenue: 88005.3338609094,
      run: "Run003",
      spend: 25000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend966",
      week: 30,
      revenue: 107490.014286816,
      run: "Run003",
      spend: 31046.999999999898,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend967",
      week: 31,
      revenue: 103598.68223860368,
      run: "Run003",
      spend: 27000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend968",
      week: 32,
      revenue: 110169.18967508181,
      run: "Run003",
      spend: 28800,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend969",
      week: 33,
      revenue: 108656.4211939465,
      run: "Run003",
      spend: 30900,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend970",
      week: 34,
      revenue: 65971.9694902765,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend971",
      week: 35,
      revenue: 75706.51746646171,
      run: "Run003",
      spend: 28000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend972",
      week: 36,
      revenue: 82417.7764777234,
      run: "Run003",
      spend: 24500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend973",
      week: 37,
      revenue: 84552.6409268273,
      run: "Run003",
      spend: 25100,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend974",
      week: 38,
      revenue: 71235.385340147,
      run: "Run003",
      spend: 24400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend975",
      week: 39,
      revenue: 35655.0671897789,
      run: "Run003",
      spend: 0,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend976",
      week: 40,
      revenue: 52123.820256737905,
      run: "Run003",
      spend: 25500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend977",
      week: 41,
      revenue: 37904.9213148196,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend978",
      week: 42,
      revenue: 38112.1810194455,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend979",
      week: 43,
      revenue: 45521.5630543559,
      run: "Run003",
      spend: 22000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend980",
      week: 44,
      revenue: 53161.5534560742,
      run: "Run003",
      spend: 24400,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend981",
      week: 45,
      revenue: 41302.8529818059,
      run: "Run003",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend982",
      week: 46,
      revenue: 34713.2817954083,
      run: "Run003",
      spend: 13200,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend983",
      week: 47,
      revenue: 30903.5116920221,
      run: "Run003",
      spend: 10000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend984",
      week: 48,
      revenue: 27991.15046018445,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend985",
      week: 49,
      revenue: 40457.1063464647,
      run: "Run003",
      spend: 22000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend986",
      week: 50,
      revenue: 37920.20207038558,
      run: "Run003",
      spend: 12000,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend987",
      week: 51,
      revenue: 69495.2302800286,
      run: "Run003",
      spend: 28500,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend988",
      week: 52,
      revenue: 88150.4049165418,
      run: "Run003",
      spend: 39700,
      channel: "Channel008",
    },
    {
      id: "WeeklySpend989",
      week: 1,
      revenue: 12880.764439646598,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend990",
      week: 2,
      revenue: 5719.46363775969,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend991",
      week: 3,
      revenue: 17367.543075691097,
      run: "Run003",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend992",
      week: 4,
      revenue: 6609.87630041075,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend993",
      week: 5,
      revenue: 2925.71966461172,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend994",
      week: 6,
      revenue: 14791.3421561676,
      run: "Run003",
      spend: 10500,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend995",
      week: 7,
      revenue: 6046.90761862704,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend996",
      week: 8,
      revenue: 2470.23432009241,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend997",
      week: 9,
      revenue: 989.134934721992,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend998",
      week: 10,
      revenue: 13934.792513042501,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend999",
      week: 11,
      revenue: 20497.0672910605,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1000",
      week: 12,
      revenue: 6856.20733554779,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1001",
      week: 13,
      revenue: 15583.625651385,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1002",
      week: 14,
      revenue: 7465.5847014578,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1003",
      week: 15,
      revenue: 16063.254174547801,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1004",
      week: 16,
      revenue: 20051.9051668836,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1005",
      week: 17,
      revenue: 7295.1612447917305,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1006",
      week: 18,
      revenue: 3244.7485179636997,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1007",
      week: 19,
      revenue: 14054.5770436144,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1008",
      week: 20,
      revenue: 5839.38136354881,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1009",
      week: 21,
      revenue: 17008.0827394886,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1010",
      week: 22,
      revenue: 21712.2983522865,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1011",
      week: 23,
      revenue: 24457.9368230634,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1012",
      week: 24,
      revenue: 26234.2711780254,
      run: "Run003",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1013",
      week: 25,
      revenue: 10700.9456670718,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1014",
      week: 26,
      revenue: 16586.938044122602,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1015",
      week: 27,
      revenue: 19740.820545030998,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1016",
      week: 28,
      revenue: 23050.4892755583,
      run: "Run003",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1017",
      week: 29,
      revenue: 10952.580204054098,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1018",
      week: 30,
      revenue: 23106.2678337174,
      run: "Run003",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1019",
      week: 31,
      revenue: 24249.279821592,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1020",
      week: 32,
      revenue: 25821.1080027751,
      run: "Run003",
      spend: 11000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1021",
      week: 33,
      revenue: 22231.524551178,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1022",
      week: 34,
      revenue: 8047.25598183324,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1023",
      week: 35,
      revenue: 3728.42175879383,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1024",
      week: 36,
      revenue: 19069.0134484378,
      run: "Run003",
      spend: 12000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1025",
      week: 37,
      revenue: 24584.116956739,
      run: "Run003",
      spend: 11244,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1026",
      week: 38,
      revenue: 9137.277421599008,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1027",
      week: 39,
      revenue: 3658.40353184609,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1028",
      week: 40,
      revenue: 14482.3346877015,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1029",
      week: 41,
      revenue: 5720.01669325274,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1030",
      week: 42,
      revenue: 14416.3948052783,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1031",
      week: 43,
      revenue: 6445.6289584017995,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1032",
      week: 44,
      revenue: 14689.2877065736,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1033",
      week: 45,
      revenue: 6475.08693533814,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1034",
      week: 46,
      revenue: 2437.06888737685,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1035",
      week: 47,
      revenue: 1006.2336475011599,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1036",
      week: 48,
      revenue: 380.06854490211003,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1037",
      week: 49,
      revenue: 151.394780160176,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1038",
      week: 50,
      revenue: 63.55057662564249,
      run: "Run003",
      spend: 0,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1039",
      week: 51,
      revenue: 21723.339163632598,
      run: "Run003",
      spend: 15000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1040",
      week: 52,
      revenue: 19366.475732193798,
      run: "Run003",
      spend: 10000,
      channel: "Channel009",
    },
    {
      id: "WeeklySpend1041",
    },
  ],
  RunKpis: [
    {
      id: "RunKpi001",
      run: "Run001",
      kpiDef: "KpiDef002",
      kpiValue: 10138029,
      usage: "usage1",
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi002",
      run: "Run001",
      kpiDef: "KpiDef016",
      kpiValue: 1.81,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi003",
      run: "Run002",
      kpiDef: "KpiDef002",
      kpiValue: 15182178,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi004",
      run: "Run002",
      kpiDef: "KpiDef016",
      kpiValue: 2.71,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi005",
      run: "Run003",
      kpiDef: "KpiDef002",
      kpiValue: 15866767,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi006",
      run: "Run003",
      kpiDef: "KpiDef016",
      kpiValue: 2.83,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi007",
      run: "Run004",
      kpiDef: "KpiDef002",
      kpiValue: 19828453,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
    {
      id: "RunKpi008",
      run: "Run004",
      kpiDef: "KpiDef016",
      kpiValue: 2.48,
      timeGranularity: "ALWAYS",
      timeValue: 1,
    },
  ],
  Brynthum: [
    {
      "id": "Budget001",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 22256,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget002",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 22163,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget003",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 18140,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget004",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 24736,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget005",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 16092,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget006",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 6397,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget007",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 6476,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget008",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 6728,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget009",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 2459,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 125447.65
    },
    {
      "id": "Budget010",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 266,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget011",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 227,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget012",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 300,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget013",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 240,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget014",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 275,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget015",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 254,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget016",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 268,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget017",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 279,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget018",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 184,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2291.94
    },
    {
      "id": "Budget019",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 260,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget020",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget021",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 304,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget022",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 246,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget023",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 279,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget024",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 288,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget025",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 267,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget026",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 271,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget027",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 185,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2328.68
    },
    {
      "id": "Budget028",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget029",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget030",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget031",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget032",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget033",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 3139,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget034",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 22416,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget035",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 23080,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget036",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 17792,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 66427.6
    },
    {
      "id": "Budget037",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 1278,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget038",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 1095,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget039",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 1094,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget040",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 862,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget041",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 1104,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget042",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 1346,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget043",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 1334,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget044",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 1281,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget045",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 989,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 10382.54
    },
    {
      "id": "Budget046",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 2039,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget047",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 1726,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget048",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 2341,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget049",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 2065,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget050",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 1761,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget051",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 1144,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget052",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 1366,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget053",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 1079,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget054",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 913,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 14434.83
    },
    {
      "id": "Budget055",
      "run": "Run001",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget056",
      "run": "Run001",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget057",
      "run": "Run001",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 1168,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget058",
      "run": "Run001",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 1839,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget059",
      "run": "Run001",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 3636,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget060",
      "run": "Run001",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget061",
      "run": "Run001",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget062",
      "run": "Run001",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget063",
      "run": "Run001",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 6642.71
    },
    {
      "id": "Budget064",
      "run": "Run005",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 15228,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget065",
      "run": "Run005",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 15647,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget066",
      "run": "Run005",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 14884,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget067",
      "run": "Run005",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 13751,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget068",
      "run": "Run005",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 12546,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget069",
      "run": "Run005",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 9208,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget070",
      "run": "Run005",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 8880,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget071",
      "run": "Run005",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 7762,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget072",
      "run": "Run005",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 5772,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 103675.8954
    },
    {
      "id": "Budget073",
      "run": "Run005",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 16723,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget074",
      "run": "Run005",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 15850,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget075",
      "run": "Run005",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 15318,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget076",
      "run": "Run005",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 14469,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget077",
      "run": "Run005",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 15774,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget078",
      "run": "Run005",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 16159,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget079",
      "run": "Run005",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 15676,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget080",
      "run": "Run005",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 14398,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget081",
      "run": "Run005",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 12522,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 136888.129
    },
    {
      "id": "Budget082",
      "run": "Run005",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 13295,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget083",
      "run": "Run005",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 12659,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget084",
      "run": "Run005",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 14914,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget085",
      "run": "Run005",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 15101,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget086",
      "run": "Run005",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 13932,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget087",
      "run": "Run005",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 4096,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget088",
      "run": "Run005",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 7850,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget089",
      "run": "Run005",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 7769,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget090",
      "run": "Run005",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 10088,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 99704.157
    },
    {
      "id": "Budget091",
      "run": "Run005",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget092",
      "run": "Run005",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget093",
      "run": "Run005",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 0,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget094",
      "run": "Run005",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 118,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget095",
      "run": "Run005",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 1390,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget096",
      "run": "Run005",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 4087,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget097",
      "run": "Run005",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 3050,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget098",
      "run": "Run005",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 1995,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget099",
      "run": "Run005",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 1770,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 12409.142
    },
    {
      "id": "Budget100",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget101",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget102",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget103",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget104",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget105",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget106",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget107",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget108",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 10065,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel003",
      "totalSpend": 90584.89638
    },
    {
      "id": "Budget109",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget110",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget111",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget112",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget113",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget114",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget115",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget116",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget117",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 228,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel004",
      "totalSpend": 2050.417631
    },
    {
      "id": "Budget118",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget119",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget120",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget121",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget122",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget123",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget124",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget125",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget126",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 232,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel005",
      "totalSpend": 2085.082315
    },
    {
      "id": "Budget127",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget128",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget129",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget130",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget131",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget132",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget133",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget134",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget135",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 5915,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel006",
      "totalSpend": 53233.99968
    },
    {
      "id": "Budget136",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget137",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget138",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget139",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget140",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget141",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget142",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget143",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget144",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 798,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel008",
      "totalSpend": 7181.362307
    },
    {
      "id": "Budget145",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget146",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget147",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget148",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget149",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget150",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget151",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget152",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget153",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 2091,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel009",
      "totalSpend": 18814.96235
    },
    {
      "id": "Budget154",
      "run": "Run004",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget155",
      "run": "Run004",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget156",
      "run": "Run004",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget157",
      "run": "Run004",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget158",
      "run": "Run004",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget159",
      "run": "Run004",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget160",
      "run": "Run004",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget161",
      "run": "Run004",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget162",
      "run": "Run004",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 889,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel010",
      "totalSpend": 8001.894812
    },
    {
      "id": "Budget163",
      "run": "Run008",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget164",
      "run": "Run008",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget165",
      "run": "Run008",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget166",
      "run": "Run008",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget167",
      "run": "Run008",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget168",
      "run": "Run008",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget169",
      "run": "Run008",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget170",
      "run": "Run008",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget171",
      "run": "Run008",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 8173,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel013",
      "totalSpend": 73560.79678
    },
    {
      "id": "Budget172",
      "run": "Run008",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget173",
      "run": "Run008",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget174",
      "run": "Run008",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget175",
      "run": "Run008",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget176",
      "run": "Run008",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget177",
      "run": "Run008",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget178",
      "run": "Run008",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget179",
      "run": "Run008",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget180",
      "run": "Run008",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 10648,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel014",
      "totalSpend": 95831.72087
    },
    {
      "id": "Budget181",
      "run": "Run008",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget182",
      "run": "Run008",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget183",
      "run": "Run008",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget184",
      "run": "Run008",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget185",
      "run": "Run008",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget186",
      "run": "Run008",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget187",
      "run": "Run008",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget188",
      "run": "Run008",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget189",
      "run": "Run008",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 11661,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel016",
      "totalSpend": 104950.2202
    },
    {
      "id": "Budget190",
      "run": "Run008",
      "dateFrom": "1/2/2023",
      "dateTo": "1/8/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 1,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget191",
      "run": "Run008",
      "dateFrom": "1/9/2023",
      "dateTo": "1/15/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 2,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget192",
      "run": "Run008",
      "dateFrom": "1/16/2023",
      "dateTo": "1/22/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 3,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget193",
      "run": "Run008",
      "dateFrom": "1/23/2023",
      "dateTo": "1/29/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 4,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget194",
      "run": "Run008",
      "dateFrom": "1/30/2023",
      "dateTo": "2/5/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 5,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget195",
      "run": "Run008",
      "dateFrom": "2/6/2023",
      "dateTo": "2/12/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 6,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget196",
      "run": "Run008",
      "dateFrom": "2/13/2023",
      "dateTo": "2/19/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 7,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget197",
      "run": "Run008",
      "dateFrom": "2/20/2023",
      "dateTo": "2/26/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 8,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    },
    {
      "id": "Budget198",
      "run": "Run008",
      "dateFrom": "2/27/2023",
      "dateTo": "3/4/2023",
      "spend": 907,
      "timeGranularity": "weekly",
      "timeByGranularity": 9,
      "analysisDimension": "CHANNEL",
      "analysisValue": "Channel017",
      "totalSpend": 8164.362307
    }
  ],
  BryntumMultiDimension: [
    {
      id: "Budget001",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget002",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget003",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget004",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget005",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget006",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget007",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget008",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget009",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget010",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget011",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget012",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget013",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget014",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget015",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget016",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget017",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget018",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget019",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget020",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget021",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget022",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget023",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget024",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget025",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget026",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget027",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget028",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget029",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget030",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget031",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget032",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget033",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget034",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget035",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget036",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget037",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget038",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget039",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget040",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget041",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget042",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget043",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget044",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget045",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget046",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget047",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget048",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget049",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget050",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget051",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget052",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
    },
    {
      id: "Budget053",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget054",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget055",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget056",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget057",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget058",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget059",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget060",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget061",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget062",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget063",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget064",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget065",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget066",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget067",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget068",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget069",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget070",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget071",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget072",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget073",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget074",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget075",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget076",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget077",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget078",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget079",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget080",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget081",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget082",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget083",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget084",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget085",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget086",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget087",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 8000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget088",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 8000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget089",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget090",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget091",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget092",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget093",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget094",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget095",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget096",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget097",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget098",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget099",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget100",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget101",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget102",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget103",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget104",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 209000,
    },
    {
      id: "Budget105",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 2331000,
    },
    {
      id: "Budget106",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget107",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget108",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget109",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 3000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget110",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 3000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget111",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 3000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget112",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 3000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget113",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 3000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget114",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 3000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget115",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget116",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 199000,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget117",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 191000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget118",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget119",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 194000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget120",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget121",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 194000,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget122",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 231000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget123",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget124",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 178000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget125",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 158000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget126",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget127",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 149000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget128",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget129",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 132000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget130",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 132000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget131",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 108000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget132",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget133",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 89000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget134",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget135",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget136",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 62000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget137",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget138",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget139",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 89000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget140",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 132000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget141",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget142",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget143",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget144",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget145",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget146",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget147",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget148",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget149",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget150",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget151",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget152",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget153",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget154",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget155",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget156",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2331000,
    },
    {
      id: "Budget157",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 2331000,
    },
    {
      id: "Budget158",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget159",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget160",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget161",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget162",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget163",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget164",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget165",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget166",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget167",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget168",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget169",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget170",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget171",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget172",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget173",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget174",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget175",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget176",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget177",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget178",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget179",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget180",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget181",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget182",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget183",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget184",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget185",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget186",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget187",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget188",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget189",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget190",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget191",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget192",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget193",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget194",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget195",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget196",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget197",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget198",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget199",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget200",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget201",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget202",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget203",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget204",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget205",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget206",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget207",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget208",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 102000,
    },
    {
      id: "Budget209",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget210",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget211",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget212",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget213",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget214",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget215",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget216",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget217",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget218",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget219",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget220",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget221",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget222",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget223",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget224",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget225",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget226",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget227",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget228",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget229",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget230",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget231",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget232",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget233",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget234",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget235",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget236",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget237",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget238",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget239",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget240",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget241",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget242",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget243",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget244",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget245",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget246",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget247",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget248",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget249",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget250",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget251",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget252",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget253",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget254",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget255",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget256",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget257",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget258",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget259",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget260",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
      totalSpend: 20000,
    },
    {
      id: "Budget261",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget262",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget263",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget264",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget265",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget266",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget267",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget268",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget269",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget270",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget271",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget272",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget273",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget274",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget275",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget276",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget277",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget278",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget279",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget280",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget281",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget282",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget283",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 9000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget284",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 9000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget285",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 9000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget286",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 9000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget287",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 7000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget288",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 7000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget289",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 6000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget290",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 6000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget291",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 7000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget292",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 7000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget293",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget294",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget295",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget296",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget297",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget298",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget299",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget300",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget301",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget302",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget303",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget304",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget305",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget306",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget307",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget308",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget309",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget310",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget311",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget312",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
      totalSpend: 76000,
    },
    {
      id: "Budget313",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget314",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget315",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget316",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget317",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget318",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget319",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget320",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget321",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget322",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget323",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget324",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget325",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget326",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget327",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget328",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget329",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget330",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget331",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget332",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget333",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget334",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget335",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 110000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget336",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 110000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget337",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 110000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget338",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 110000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget339",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 74000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget340",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 74000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget341",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 74000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget342",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 74000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget343",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 50000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget344",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 50000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget345",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 50000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget346",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 50000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget347",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget348",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget349",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget350",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget351",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget352",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget353",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget354",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget355",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget356",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget357",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget358",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget359",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget360",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget361",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget362",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget363",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget364",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 936000,
    },
    {
      id: "Budget365",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget366",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget367",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget368",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget369",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget370",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget371",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget372",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget373",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget374",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget375",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget376",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget377",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget378",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget379",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget380",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget381",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget382",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget383",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget384",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget385",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget386",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 5000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget387",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget388",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 14924,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget389",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget390",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget391",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 29000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget392",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 29000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget393",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 29000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget394",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 29000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget395",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 6000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget396",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 6000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget397",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 6000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget398",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 6000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget399",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget400",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget401",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget402",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget403",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget404",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget405",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget406",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget407",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget408",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget409",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget410",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget411",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget412",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget413",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget414",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget415",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget416",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
      totalSpend: 204924,
    },
    {
      id: "Budget417",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget418",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget419",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget420",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget421",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget422",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget423",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget424",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget425",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget426",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget427",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget428",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget429",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget430",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget431",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget432",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget433",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget434",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget435",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget436",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget437",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget438",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget439",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget440",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget441",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 60000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget442",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 60000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget443",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 60000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget444",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget445",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget446",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget447",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget448",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget449",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget450",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget451",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget452",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget453",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget454",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget455",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget456",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget457",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget458",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget459",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget460",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget461",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget462",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget463",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget464",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget465",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget466",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget467",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget468",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 230000,
    },
    {
      id: "Budget469",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget470",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget471",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget472",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget473",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget474",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget475",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget476",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget477",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget478",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget479",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget480",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget481",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget482",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget483",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget484",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget485",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget486",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget487",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget488",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget489",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget490",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget491",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget492",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget493",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget494",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget495",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget496",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget497",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget498",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget499",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget500",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget501",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget502",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget503",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget504",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget505",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget506",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget507",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget508",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget509",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget510",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget511",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget512",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget513",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget514",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget515",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget516",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget517",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget518",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget519",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget520",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
    },
    {
      id: "Budget521",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget522",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget523",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget524",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget525",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget526",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget527",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget528",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget529",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget530",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget531",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget532",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget533",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget534",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget535",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget536",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget537",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget538",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget539",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget540",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget541",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget542",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget543",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget544",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget545",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget546",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget547",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget548",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget549",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget550",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget551",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget552",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget553",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget554",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget555",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget556",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget557",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget558",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget559",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget560",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget561",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget562",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget563",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget564",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget565",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget566",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget567",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget568",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget569",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget570",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget571",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget572",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget573",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget574",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget575",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget576",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget577",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget578",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget579",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget580",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget581",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget582",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget583",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget584",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget585",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget586",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget587",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget588",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget589",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget590",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget591",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget592",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget593",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget594",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget595",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget596",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget597",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 20000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget598",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 20000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget599",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget600",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget601",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget602",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget603",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget604",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget605",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget606",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget607",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget608",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget609",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget610",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget611",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget612",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget613",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget614",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget615",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget616",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget617",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget618",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget619",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget620",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget621",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget622",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget623",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget624",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 132000,
    },
    {
      id: "Budget625",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget626",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget627",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget628",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget629",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget630",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget631",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget632",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget633",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget634",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget635",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget636",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget637",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget638",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget639",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget640",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget641",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget642",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget643",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget644",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget645",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget646",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget647",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget648",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget649",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget650",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget651",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget652",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget653",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget654",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget655",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget656",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget657",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget658",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget659",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget660",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 8000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget661",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 8000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget662",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget663",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget664",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget665",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget666",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget667",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget668",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget669",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget670",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget671",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget672",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget673",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget674",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget675",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget676",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 82000,
    },
    {
      id: "Budget677",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget678",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget679",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget680",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget681",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget682",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget683",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget684",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget685",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget686",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget687",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget688",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget689",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget690",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget691",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget692",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget693",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget694",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget695",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget696",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget697",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget698",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget699",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget700",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget701",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget702",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget703",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 84000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget704",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 74000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget705",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 64000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget706",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget707",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget708",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 47000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget709",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 45000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget710",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget711",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 53000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget712",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 69000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget713",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget714",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget715",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget716",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget717",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget718",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget719",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget720",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget721",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget722",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget723",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget724",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget725",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget726",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget727",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget728",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 436000,
    },
    {
      id: "Budget729",
      run: "Run001",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget730",
      run: "Run001",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget731",
      run: "Run001",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget732",
      run: "Run001",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget733",
      run: "Run001",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget734",
      run: "Run001",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget735",
      run: "Run001",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget736",
      run: "Run001",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget737",
      run: "Run001",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget738",
      run: "Run001",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget739",
      run: "Run001",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget740",
      run: "Run001",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget741",
      run: "Run001",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget742",
      run: "Run001",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget743",
      run: "Run001",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget744",
      run: "Run001",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget745",
      run: "Run001",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget746",
      run: "Run001",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget747",
      run: "Run001",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget748",
      run: "Run001",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget749",
      run: "Run001",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget750",
      run: "Run001",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget751",
      run: "Run001",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget752",
      run: "Run001",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget753",
      run: "Run001",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget754",
      run: "Run001",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget755",
      run: "Run001",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 35000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget756",
      run: "Run001",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 36000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget757",
      run: "Run001",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 42000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget758",
      run: "Run001",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 47000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget759",
      run: "Run001",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 31000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget760",
      run: "Run001",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 31000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget761",
      run: "Run001",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 18500,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget762",
      run: "Run001",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 34000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget763",
      run: "Run001",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 34000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget764",
      run: "Run001",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget765",
      run: "Run001",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget766",
      run: "Run001",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget767",
      run: "Run001",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget768",
      run: "Run001",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget769",
      run: "Run001",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget770",
      run: "Run001",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget771",
      run: "Run001",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget772",
      run: "Run001",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget773",
      run: "Run001",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget774",
      run: "Run001",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget775",
      run: "Run001",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget776",
      run: "Run001",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget777",
      run: "Run001",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget778",
      run: "Run001",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget779",
      run: "Run001",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget780",
      run: "Run001",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 308500,
    },
    {
      id: "Budget781",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget782",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget783",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget784",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget785",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget786",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget787",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget788",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget789",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget790",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget791",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget792",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget793",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget794",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget795",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget796",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget797",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget798",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget799",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget800",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget801",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget802",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget803",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget804",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget805",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget806",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget807",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget808",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget809",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget810",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 13476.8953232017,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget811",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget812",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget813",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget814",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget815",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget816",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget817",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget818",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget819",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget820",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget821",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget822",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget823",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget824",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget825",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget826",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget827",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget828",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget829",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget830",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget831",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget832",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 332976.895323202,
    },
    {
      id: "Budget833",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget834",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget835",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget836",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget837",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget838",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget839",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget840",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget841",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget842",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget843",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget844",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget845",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 11390.8868623431,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget846",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget847",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget848",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget849",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget850",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget851",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget852",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget853",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget854",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget855",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget856",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget857",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget858",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget859",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget860",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget861",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget862",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget863",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget864",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget865",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget866",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget867",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget868",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget869",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget870",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget871",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget872",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget873",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget874",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget875",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget876",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget877",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget878",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget879",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget880",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget881",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget882",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget883",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget884",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget885",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 101000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 359890.886862343,
    },
    {
      id: "Budget886",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 49000,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget887",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 75500,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget888",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 40000,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget889",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 34500,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget890",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 47000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget891",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget892",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 49000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget893",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget894",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 58500,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget895",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 68500,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget896",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget897",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 53000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget898",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 39500,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget899",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 41000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget900",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 59000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget901",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget902",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget903",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget904",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget905",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 53000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget906",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 63500,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget907",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 79000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget908",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 53000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget909",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget910",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget911",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 117242.380778881,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget912",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 68500,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget913",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 62500,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget914",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 50500,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget915",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 116000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget916",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 92500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget917",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 63500,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget918",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 140000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget919",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 17500,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget920",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget921",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 21000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget922",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget923",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget924",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget925",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget926",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget927",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget928",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget929",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget930",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget931",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget932",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget933",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget934",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget935",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 113500,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget936",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 51500,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1969742.38077888,
    },
    {
      id: "Budget937",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 14305.8424062112,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget938",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget939",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget940",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget941",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget942",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget943",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget944",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget945",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget946",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget947",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget948",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget949",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget950",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget951",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget952",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget953",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget954",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget955",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget956",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget957",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget958",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget959",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget960",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget961",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget962",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget963",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget964",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget965",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget966",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget967",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget968",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget969",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget970",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget971",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget972",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget973",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget974",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget975",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget976",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget977",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget978",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget979",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget980",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget981",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget982",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget983",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget984",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget985",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget986",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget987",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget988",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-01-01",
      spend: 16000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 501305.842406211,
    },
    {
      id: "Budget989",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget990",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget991",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget992",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget993",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget994",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget995",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget996",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget997",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget998",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget999",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1000",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1001",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1002",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1003",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1004",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1005",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1006",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1007",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1008",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1009",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1010",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1011",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1012",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1013",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1014",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1015",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1016",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1017",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1018",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1019",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1020",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1021",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1022",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1023",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1024",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1025",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1026",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1027",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1028",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1029",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1030",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1031",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1032",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1033",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1034",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1035",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1036",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1037",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1038",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1039",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1040",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1041",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1042",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1043",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1044",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1045",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1046",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1047",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1048",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1049",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1050",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1051",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1052",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1053",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1054",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1055",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1056",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1057",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1058",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1059",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1060",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1061",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1062",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1063",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1064",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1065",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1066",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1067",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1068",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1069",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1070",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1071",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1072",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1073",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1074",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1075",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1076",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1077",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1078",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1079",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1080",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1081",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1082",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1083",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1084",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1085",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1086",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1087",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1088",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1089",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1090",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1091",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1092",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1093",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 18500,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1094",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 21500,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1095",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 55500,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1096",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 44500,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1097",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1098",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1099",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 21500,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1100",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 44000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1101",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 52000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1102",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 39500,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1103",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 61000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1104",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 25500,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1105",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1106",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1107",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1108",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1109",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 27000,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1110",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1111",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1112",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1113",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 21500,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1114",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1115",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1116",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 23500,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1117",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 33500,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1118",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 59500,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1119",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 64000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1120",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 40000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1121",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 43500,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1122",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 46000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1123",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 45000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1124",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 53500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1125",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 44000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1126",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 42000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1127",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 41000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1128",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1129",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1130",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 43000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1131",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1132",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 20500,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1133",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1134",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1135",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1136",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1137",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1138",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1139",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1140",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1141",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 47000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1142",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 21500,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1143",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1144",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1237000,
    },
    {
      id: "Budget1145",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1146",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1147",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1148",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1149",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1150",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1151",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1152",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1153",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1154",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1155",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1156",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1157",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1158",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1159",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1160",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1161",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1162",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1163",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1164",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1165",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1166",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1167",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1168",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1169",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1170",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1171",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1172",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1173",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1174",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1175",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1176",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1177",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1178",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1179",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1180",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1181",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1182",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1183",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1184",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1185",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1186",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1187",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1188",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1189",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1190",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1191",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1192",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1193",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1194",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1195",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1196",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1197",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1198",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1199",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1200",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1201",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1202",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1203",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1204",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1205",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1206",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1207",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1208",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1209",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1210",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1211",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1212",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1213",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1214",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1215",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1216",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1217",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1218",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1219",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 10146,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1220",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1221",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1222",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1223",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1224",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1225",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1226",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1227",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1228",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1229",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1230",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1231",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1232",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1233",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1234",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1235",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1236",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1237",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1238",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1239",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1240",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1241",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1242",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1243",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1244",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1245",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1246",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1247",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 18500,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1248",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 252646,
    },
    {
      id: "Budget1249",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1250",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1251",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1252",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1253",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1254",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1255",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1256",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1257",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1258",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1259",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1260",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1261",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1262",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1263",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1264",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1265",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1266",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1267",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1268",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1269",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1270",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1271",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1272",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1273",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1274",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1275",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 26354,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1276",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1277",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1278",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1279",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 52500,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1280",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 30500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1281",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1282",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1283",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1284",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1285",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1286",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1287",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1288",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1289",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1290",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1291",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1292",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1293",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1294",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1295",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1296",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1297",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1298",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1299",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1300",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 109354,
    },
    {
      id: "Budget1301",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1302",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1303",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1304",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1305",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1306",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1307",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1308",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1309",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1310",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1311",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1312",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1313",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1314",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1315",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1316",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1317",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1318",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1319",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1320",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1321",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1322",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1323",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1324",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1325",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1326",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1327",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1328",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1329",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1330",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1331",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1332",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1333",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1334",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1335",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1336",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1337",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1338",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1339",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1340",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1341",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1342",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1343",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1344",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1345",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1346",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1347",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1348",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1349",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1350",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1351",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1352",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget1353",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1354",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1355",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1356",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1357",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1358",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1359",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1360",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1361",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1362",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1363",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1364",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1365",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1366",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1367",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1368",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1369",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1370",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1371",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1372",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1373",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1374",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1375",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1376",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1377",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1378",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1379",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1380",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1381",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1382",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1383",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1384",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1385",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1386",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1387",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1388",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1389",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1390",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1391",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1392",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1393",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1394",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1395",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1396",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1397",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1398",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1399",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1400",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1401",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1402",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1403",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1404",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget1405",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 12094,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1406",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1407",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1408",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1409",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1410",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1411",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1412",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1413",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1414",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1415",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1416",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1417",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1418",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1419",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1420",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1421",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1422",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1423",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1424",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1425",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1426",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1427",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1428",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1429",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1430",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1431",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1432",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1433",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1434",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1435",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1436",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1437",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1438",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1439",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1440",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1441",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1442",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1443",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1444",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1445",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1446",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1447",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1448",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1449",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1450",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1451",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1452",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1453",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1454",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1455",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1456",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 198594,
    },
    {
      id: "Budget1457",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 26000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1458",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1459",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1460",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1461",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1462",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1463",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1464",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1465",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1466",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1467",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1468",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1469",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1470",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1471",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1472",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 29500,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1473",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1474",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1475",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1476",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1477",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1478",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 22500,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1479",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1480",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1481",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1482",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1483",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 38500,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1484",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1485",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1486",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1487",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 21500,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1488",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1489",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1490",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 30228,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1491",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1492",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1493",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1494",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1495",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1496",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1497",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1498",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1499",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1500",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1501",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1502",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1503",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1504",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1505",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1506",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1507",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 30000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1508",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 290228,
    },
    {
      id: "Budget1509",
      run: "Run002",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1510",
      run: "Run002",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1511",
      run: "Run002",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1512",
      run: "Run002",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1513",
      run: "Run002",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1514",
      run: "Run002",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1515",
      run: "Run002",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1516",
      run: "Run002",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1517",
      run: "Run002",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1518",
      run: "Run002",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1519",
      run: "Run002",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1520",
      run: "Run002",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1521",
      run: "Run002",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1522",
      run: "Run002",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1523",
      run: "Run002",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1524",
      run: "Run002",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1525",
      run: "Run002",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1526",
      run: "Run002",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1527",
      run: "Run002",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1528",
      run: "Run002",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1529",
      run: "Run002",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1530",
      run: "Run002",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1531",
      run: "Run002",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1532",
      run: "Run002",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1533",
      run: "Run002",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1534",
      run: "Run002",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1535",
      run: "Run002",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1536",
      run: "Run002",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1537",
      run: "Run002",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1538",
      run: "Run002",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1539",
      run: "Run002",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1540",
      run: "Run002",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1541",
      run: "Run002",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1542",
      run: "Run002",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1543",
      run: "Run002",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1544",
      run: "Run002",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1545",
      run: "Run002",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1546",
      run: "Run002",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1547",
      run: "Run002",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1548",
      run: "Run002",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1549",
      run: "Run002",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1550",
      run: "Run002",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1551",
      run: "Run002",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1552",
      run: "Run002",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1553",
      run: "Run002",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1554",
      run: "Run002",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1555",
      run: "Run002",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1556",
      run: "Run002",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1557",
      run: "Run002",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1558",
      run: "Run002",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1559",
      run: "Run002",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1560",
      run: "Run002",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 12678,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 337178,
    },
    {
      id: "Budget1561",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1562",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1563",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1564",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1565",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1566",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1567",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1568",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1569",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1570",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1571",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1572",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1573",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1574",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1575",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1576",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1577",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1578",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1579",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1580",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1581",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1582",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1583",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1584",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1585",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1586",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1587",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1588",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1589",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1590",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1591",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1592",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1593",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1594",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1595",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1596",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1597",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 11244,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1598",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1599",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1600",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1601",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1602",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1603",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1604",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1605",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1606",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1607",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1608",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1609",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1610",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1611",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1612",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 285744,
    },
    {
      id: "Budget1613",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1614",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1615",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1616",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1617",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1618",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1619",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1620",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1621",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1622",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1623",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1624",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1625",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1626",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1627",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1628",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1629",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1630",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1631",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1632",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1633",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1634",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1635",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1636",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1637",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1638",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1639",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1640",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1641",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1642",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1643",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1644",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 12321,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1645",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1646",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1647",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1648",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1649",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1650",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1651",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1652",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1653",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1654",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1655",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1656",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1657",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1658",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1659",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1660",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1661",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1662",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1663",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1664",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1665",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 116250,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 347321,
    },
    {
      id: "Budget1666",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1667",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 116250,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1668",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1669",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1670",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 86250,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1671",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1672",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1673",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1674",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1675",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1676",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1677",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1678",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1679",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 82500,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1680",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1681",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1682",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1683",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1684",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1685",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1686",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 82500,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1687",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 101250,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1688",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1689",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1690",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1691",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 112500,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1692",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1693",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1694",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1695",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 127500,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1696",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 86250,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1697",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1698",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 142500,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1699",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1700",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1701",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1702",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1703",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1704",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1705",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1706",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1707",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1708",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1709",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1710",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1711",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1712",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1713",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1714",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1715",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 135716,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1716",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 1643216,
    },
    {
      id: "Budget1717",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1718",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1719",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1720",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1721",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1722",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1723",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1724",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1725",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1726",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1727",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1728",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1729",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1730",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1731",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1732",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1733",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1734",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1735",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1736",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1737",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1738",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1739",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1740",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1741",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1742",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1743",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1744",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1745",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1746",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 13046.9999999999,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1747",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1748",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1749",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1750",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1751",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1752",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1753",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1754",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1755",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1756",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1757",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1758",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1759",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1760",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1761",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1762",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1763",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1764",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1765",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1766",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1767",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 16500,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1768",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 460547,
    },
    {
      id: "Budget1769",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1770",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1771",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1772",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1773",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1774",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1775",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1776",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1777",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1778",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1779",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1780",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1781",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1782",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1783",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1784",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1785",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1786",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1787",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1788",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1789",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1790",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1791",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1792",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1793",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1794",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1795",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1796",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1797",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1798",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1799",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1800",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1801",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1802",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1803",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1804",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1805",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1806",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1807",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1808",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1809",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1810",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1811",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1812",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1813",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1814",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1815",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1816",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1817",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1818",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1819",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1820",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget1821",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1822",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1823",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1824",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1825",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1826",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1827",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1828",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1829",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1830",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1831",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1832",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1833",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1834",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1835",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1836",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1837",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1838",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1839",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1840",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1841",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1842",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1843",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1844",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1845",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1846",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1847",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1848",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1849",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1850",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1851",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1852",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1853",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1854",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1855",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1856",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1857",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1858",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1859",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1860",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1861",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1862",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1863",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1864",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1865",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1866",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1867",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1868",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1869",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1870",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1871",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1872",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget1873",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1874",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1875",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 75226,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1876",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1877",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1878",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1879",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1880",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1881",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1882",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1883",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1884",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1885",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1886",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1887",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1888",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1889",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1890",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1891",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1892",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1893",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1894",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1895",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1896",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1897",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1898",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1899",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1900",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1901",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1902",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1903",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1904",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 82500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1905",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1906",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1907",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1908",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1909",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1910",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1911",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1912",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1913",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1914",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1915",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1916",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1917",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1918",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1919",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1920",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1921",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1922",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1923",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1924",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 611476,
    },
    {
      id: "Budget1925",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1926",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1927",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1928",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1929",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1930",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1931",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1932",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1933",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1934",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1935",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1936",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1937",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1938",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1939",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1940",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1941",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1942",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1943",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1944",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1945",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1946",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1947",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1948",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1949",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1950",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1951",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1952",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1953",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1954",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1955",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1956",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1957",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1958",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1959",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1960",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1961",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1962",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1963",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1964",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1965",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1966",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1967",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1968",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1969",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1970",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1971",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1972",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1973",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1974",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1975",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1976",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget1977",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1978",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1979",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1980",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1981",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1982",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1983",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1984",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1985",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1986",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1987",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1988",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1989",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1990",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 16500,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1991",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1992",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1993",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1994",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1995",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1996",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1997",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1998",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget1999",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2000",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2001",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2002",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2003",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2004",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 12856,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2005",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2006",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2007",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 20000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2008",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 19000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2009",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2010",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2011",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2012",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2013",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2014",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2015",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2016",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2017",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2018",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2019",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2020",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2021",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2022",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2023",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2024",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2025",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2026",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2027",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 25000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2028",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 404856,
    },
    {
      id: "Budget2029",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2030",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2031",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2032",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2033",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2034",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2035",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2036",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2037",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2038",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2039",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2040",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2041",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2042",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2043",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 90000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2044",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2045",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2046",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2047",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2048",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2049",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2050",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2051",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2052",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2053",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2054",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2055",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 93750,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2056",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2057",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2058",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2059",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 90757,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2060",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2061",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2062",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2063",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2064",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2065",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2066",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2067",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2068",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2069",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2070",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2071",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2072",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2073",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2074",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2075",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2076",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2077",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2078",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2079",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2080",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 424507,
    },
    {
      id: "Budget2081",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2082",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2083",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2084",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2085",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2086",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2087",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2088",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2089",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2090",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2091",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2092",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2093",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2094",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2095",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2096",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2097",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2098",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2099",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2100",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2101",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2102",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2103",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2104",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2105",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2106",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2107",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2108",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2109",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2110",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2111",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2112",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2113",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2114",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2115",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2116",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2117",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2118",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2119",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2120",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2121",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2122",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2123",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2124",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2125",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2126",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2127",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2128",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2129",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2130",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2131",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2132",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2133",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2134",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2135",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2136",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2137",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2138",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2139",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2140",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2141",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2142",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2143",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2144",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2145",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2146",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2147",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2148",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2149",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2150",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2151",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2152",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2153",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2154",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2155",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2156",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2157",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2158",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2159",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2160",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2161",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2162",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2163",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2164",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2165",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2166",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2167",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2168",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2169",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2170",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2171",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2172",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2173",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2174",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2175",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2176",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2177",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2178",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2179",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2180",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2181",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2182",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2183",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2184",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
    },
    {
      id: "Budget2185",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2186",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2187",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2188",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2189",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2190",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2191",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2192",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2193",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2194",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2195",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2196",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2197",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2198",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2199",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 12078,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2200",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2201",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2202",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2203",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2204",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2205",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2206",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2207",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2208",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2209",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2210",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2211",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 13800,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2212",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2213",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2214",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2215",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2216",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2217",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2218",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2219",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 13800,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2220",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2221",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2222",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2223",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2224",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2225",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2226",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2227",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2228",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2229",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2230",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2231",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2232",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2233",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2234",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2235",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2236",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 270078,
    },
    {
      id: "Budget2237",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2238",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2239",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2240",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2241",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2242",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2243",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2244",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2245",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2246",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2247",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2248",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2249",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2250",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2251",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2252",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 90000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2253",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2254",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2255",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2256",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2257",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2258",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2259",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2260",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2261",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2262",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2263",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2264",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2265",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2266",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2267",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2268",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2269",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2270",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2271",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2272",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2273",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2274",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2275",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2276",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2277",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2278",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2279",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2280",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2281",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2282",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2283",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2284",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2285",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2286",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2287",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 95214,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2288",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 560214,
    },
    {
      id: "Budget2289",
      run: "Run003",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 25800,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2290",
      run: "Run003",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2291",
      run: "Run003",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2292",
      run: "Run003",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 15600,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2293",
      run: "Run003",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2294",
      run: "Run003",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2295",
      run: "Run003",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2296",
      run: "Run003",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2297",
      run: "Run003",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2298",
      run: "Run003",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2299",
      run: "Run003",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2300",
      run: "Run003",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 13800,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2301",
      run: "Run003",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2302",
      run: "Run003",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 17400,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2303",
      run: "Run003",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2304",
      run: "Run003",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 22200,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2305",
      run: "Run003",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2306",
      run: "Run003",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2307",
      run: "Run003",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2308",
      run: "Run003",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2309",
      run: "Run003",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2310",
      run: "Run003",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2311",
      run: "Run003",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2312",
      run: "Run003",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 12735,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2313",
      run: "Run003",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2314",
      run: "Run003",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2315",
      run: "Run003",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2316",
      run: "Run003",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2317",
      run: "Run003",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2318",
      run: "Run003",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2319",
      run: "Run003",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2320",
      run: "Run003",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 16800,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2321",
      run: "Run003",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 20400,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2322",
      run: "Run003",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2323",
      run: "Run003",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2324",
      run: "Run003",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2325",
      run: "Run003",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 12600,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2326",
      run: "Run003",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2327",
      run: "Run003",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2328",
      run: "Run003",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2329",
      run: "Run003",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2330",
      run: "Run003",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2331",
      run: "Run003",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2332",
      run: "Run003",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2333",
      run: "Run003",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2334",
      run: "Run003",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2335",
      run: "Run003",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2336",
      run: "Run003",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2337",
      run: "Run003",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2338",
      run: "Run003",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2339",
      run: "Run003",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2340",
      run: "Run003",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 25200,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 587535,
    },
    {
      id: "Budget2341",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2342",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2343",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2344",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2345",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2346",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2347",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2348",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2349",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2350",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2351",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2352",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2353",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2354",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2355",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2356",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2357",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2358",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2359",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2360",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2361",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 13290,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2362",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2363",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2364",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2365",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2366",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2367",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2368",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2369",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2370",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2371",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2372",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2373",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2374",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2375",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2376",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2377",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2378",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2379",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2380",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2381",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2382",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2383",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2384",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2385",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2386",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2387",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2388",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2389",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2390",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2391",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 16000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2392",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel009",
      totalSpend: 427290,
    },
    {
      id: "Budget2393",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2394",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2395",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2396",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2397",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2398",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2399",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2400",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2401",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2402",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2403",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2404",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2405",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2406",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2407",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2408",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2409",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2410",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2411",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2412",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2413",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2414",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2415",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2416",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2417",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2418",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2419",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2420",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2421",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2422",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2423",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2424",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2425",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2426",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 11489.0000000001,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2427",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2428",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2429",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2430",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2431",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2432",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2433",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2434",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2435",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2436",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2437",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2438",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2439",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2440",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2441",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2442",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2443",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2444",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 19500,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2445",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 120000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel005",
      totalSpend: 384989,
    },
    {
      id: "Budget2446",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2447",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2448",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2449",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2450",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 93750,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2451",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2452",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 79425,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2453",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2454",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2455",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 86250,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2456",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2457",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 97500,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2458",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2459",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2460",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 90000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2461",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2462",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2463",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2464",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2465",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 82500,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2466",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2467",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 112500,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2468",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2469",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2470",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2471",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 135000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2472",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2473",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 105000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2474",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2475",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 150000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2476",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 93750,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2477",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2478",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 165000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2479",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2480",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2481",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2482",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2483",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2484",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2485",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2486",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2487",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2488",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2489",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2490",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2491",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2492",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2493",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2494",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2495",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 150000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2496",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 86250,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel001",
      totalSpend: 2558175,
    },
    {
      id: "Budget2497",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 16500,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2498",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2499",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2500",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2501",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2502",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2503",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2504",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2505",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2506",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2507",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2508",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2509",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2510",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2511",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2512",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2513",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2514",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 10691,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2515",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2516",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2517",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2518",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2519",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2520",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2521",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2522",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2523",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2524",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2525",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2526",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2527",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2528",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2529",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2530",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2531",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2532",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 14500,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2533",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2534",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2535",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2536",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2537",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2538",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2539",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2540",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2541",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 11000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2542",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2543",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2544",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2545",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2546",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2547",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2548",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand001",
      Channel: "Channel008",
      totalSpend: 582691,
    },
    {
      id: "Budget2549",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2550",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2551",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2552",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2553",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2554",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2555",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2556",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2557",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2558",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2559",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2560",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2561",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2562",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2563",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2564",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2565",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2566",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2567",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2568",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2569",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2570",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2571",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2572",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2573",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2574",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2575",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2576",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2577",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2578",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2579",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2580",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2581",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2582",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2583",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2584",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2585",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2586",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2587",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2588",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2589",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2590",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2591",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2592",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2593",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2594",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2595",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2596",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2597",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2598",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2599",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2600",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel009",
    },
    {
      id: "Budget2601",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2602",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2603",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2604",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2605",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2606",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2607",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2608",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2609",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2610",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2611",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2612",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2613",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2614",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2615",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2616",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2617",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2618",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2619",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2620",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2621",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2622",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2623",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2624",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2625",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2626",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2627",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2628",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2629",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2630",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2631",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2632",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2633",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2634",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2635",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2636",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2637",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2638",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2639",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2640",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2641",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2642",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2643",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2644",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2645",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2646",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2647",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2648",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2649",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2650",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2651",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2652",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel005",
    },
    {
      id: "Budget2653",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2654",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2655",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2656",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2657",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2658",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2659",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2660",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2661",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2662",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2663",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 97500,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2664",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2665",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2666",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2667",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2668",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2669",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2670",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2671",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2672",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2673",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2674",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2675",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2676",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2677",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2678",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 86250,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2679",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 87851.0000000002,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2680",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2681",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2682",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2683",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2684",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2685",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2686",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2687",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2688",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2689",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2690",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2691",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2692",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2693",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2694",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2695",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2696",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2697",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2698",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2699",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2700",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2701",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2702",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2703",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2704",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel001",
      totalSpend: 1100351,
    },
    {
      id: "Budget2705",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2706",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2707",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2708",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2709",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2710",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2711",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2712",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2713",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2714",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2715",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2716",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2717",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2718",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2719",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2720",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2721",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2722",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2723",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2724",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2725",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2726",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2727",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2728",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2729",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2730",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2731",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2732",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2733",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2734",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2735",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2736",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2737",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2738",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2739",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2740",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2741",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2742",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2743",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2744",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2745",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2746",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2747",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2748",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2749",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2750",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2751",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2752",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2753",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2754",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2755",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2756",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand002",
      Channel: "Channel008",
    },
    {
      id: "Budget2757",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2758",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2759",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2760",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2761",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2762",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 11228.0000000001,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2763",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2764",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2765",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2766",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2767",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2768",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2769",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2770",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 17000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2771",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 24500,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2772",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2773",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2774",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2775",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2776",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2777",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 16000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2778",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 16000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2779",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2780",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2781",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2782",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2783",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 13000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2784",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2785",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2786",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2787",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 20500,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2788",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 22000,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2789",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2790",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 15500,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2791",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2792",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 11500,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2793",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 13500,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2794",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2795",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2796",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2797",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2798",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2799",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2800",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2801",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2802",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2803",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2804",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2805",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 14000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2806",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2807",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 27000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2808",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 532228,
    },
    {
      id: "Budget2809",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2810",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2811",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2812",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2813",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2814",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2815",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2816",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2817",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2818",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2819",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2820",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2821",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2822",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2823",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 112500,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2824",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2825",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2826",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2827",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2828",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2829",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2830",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2831",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2832",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2833",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2834",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2835",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 112500,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2836",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2837",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2838",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2839",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 101250,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2840",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2841",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2842",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 90000,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2843",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2844",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2845",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2846",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2847",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2848",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2849",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2850",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2851",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2852",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2853",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2854",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2855",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2856",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2857",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 106570,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2858",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2859",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2860",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel001",
      totalSpend: 672820,
    },
    {
      id: "Budget2861",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2862",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2863",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2864",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2865",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2866",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2867",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2868",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2869",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2870",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2871",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2872",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2873",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2874",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2875",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2876",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2877",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2878",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2879",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2880",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2881",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2882",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2883",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2884",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2885",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2886",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2887",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2888",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2889",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2890",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2891",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2892",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2893",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2894",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2895",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2896",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2897",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2898",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2899",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2900",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2901",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2902",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2903",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2904",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2905",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2906",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2907",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2908",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2909",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2910",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2911",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2912",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel008",
    },
    {
      id: "Budget2913",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2914",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2915",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2916",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2917",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2918",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2919",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2920",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2921",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2922",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2923",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2924",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2925",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2926",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2927",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2928",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2929",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2930",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2931",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2932",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2933",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2934",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 10500,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2935",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2936",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2937",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2938",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2939",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2940",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2941",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 14092,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2942",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 12500,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2943",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 23000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2944",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2945",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2946",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2947",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2948",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2949",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2950",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2951",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2952",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2953",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2954",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2955",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2956",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2957",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2958",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2959",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2960",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2961",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2962",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2963",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 10000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2964",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel004",
      totalSpend: 70092,
    },
    {
      id: "Budget2965",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2966",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand003",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2967",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2968",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2969",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2970",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2971",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2972",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2973",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2974",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2975",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2976",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 12600,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2977",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2978",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2979",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2980",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2981",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2982",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2983",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2984",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2985",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2986",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2987",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2988",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2989",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2990",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2991",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2992",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2993",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2994",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2995",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2996",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 15600,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2997",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2998",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget2999",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3000",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3001",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3002",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 12600,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3003",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3004",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3005",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3006",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3007",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3008",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3009",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3010",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3011",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3012",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3013",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3014",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3015",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3016",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 16051,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel005",
      totalSpend: 295051,
    },
    {
      id: "Budget3017",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 82500,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3018",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3019",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3020",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3021",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3022",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3023",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3024",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3025",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3026",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3027",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3028",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3029",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3030",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3031",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3032",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 82500,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3033",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3034",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3035",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3036",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3037",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3038",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3039",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3040",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3041",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3042",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3043",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3044",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3045",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3046",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3047",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 75000,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3048",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3049",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3050",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 78750,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3051",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3052",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3053",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3054",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3055",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3056",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3057",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3058",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3059",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3060",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3061",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3062",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3063",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3064",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3065",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3066",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3067",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 122186,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3068",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel001",
      totalSpend: 665936,
    },
    {
      id: "Budget3069",
      run: "Run004",
      dateFrom: "2023-01-02",
      dateTo: "2023-01-08",
      spend: 27000,
      timeGranularity: "weekly",
      timeByGranularity: 1,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3070",
      run: "Run004",
      dateFrom: "2023-01-09",
      dateTo: "2023-01-15",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 2,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3071",
      run: "Run004",
      dateFrom: "2023-01-16",
      dateTo: "2023-01-22",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 3,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3072",
      run: "Run004",
      dateFrom: "2023-01-23",
      dateTo: "2023-01-29",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 4,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3073",
      run: "Run004",
      dateFrom: "2023-01-30",
      dateTo: "2023-02-05",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 5,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3074",
      run: "Run004",
      dateFrom: "2023-02-06",
      dateTo: "2023-02-12",
      spend: 13800,
      timeGranularity: "weekly",
      timeByGranularity: 6,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3075",
      run: "Run004",
      dateFrom: "2023-02-13",
      dateTo: "2023-02-19",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 7,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3076",
      run: "Run004",
      dateFrom: "2023-02-20",
      dateTo: "2023-02-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 8,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3077",
      run: "Run004",
      dateFrom: "2023-02-27",
      dateTo: "2023-03-05",
      spend: 19200,
      timeGranularity: "weekly",
      timeByGranularity: 9,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3078",
      run: "Run004",
      dateFrom: "2023-03-06",
      dateTo: "2023-03-12",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 10,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3079",
      run: "Run004",
      dateFrom: "2023-03-13",
      dateTo: "2023-03-19",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 11,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3080",
      run: "Run004",
      dateFrom: "2023-03-20",
      dateTo: "2023-03-26",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 12,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3081",
      run: "Run004",
      dateFrom: "2023-03-27",
      dateTo: "2023-04-02",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 13,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3082",
      run: "Run004",
      dateFrom: "2023-04-03",
      dateTo: "2023-04-09",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 14,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3083",
      run: "Run004",
      dateFrom: "2023-04-10",
      dateTo: "2023-04-16",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 15,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3084",
      run: "Run004",
      dateFrom: "2023-04-17",
      dateTo: "2023-04-23",
      spend: 20400,
      timeGranularity: "weekly",
      timeByGranularity: 16,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3085",
      run: "Run004",
      dateFrom: "2023-04-24",
      dateTo: "2023-04-30",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 17,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3086",
      run: "Run004",
      dateFrom: "2023-05-01",
      dateTo: "2023-05-07",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 18,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3087",
      run: "Run004",
      dateFrom: "2023-05-08",
      dateTo: "2023-05-14",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 19,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3088",
      run: "Run004",
      dateFrom: "2023-05-15",
      dateTo: "2023-05-21",
      spend: 13800,
      timeGranularity: "weekly",
      timeByGranularity: 20,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3089",
      run: "Run004",
      dateFrom: "2023-05-22",
      dateTo: "2023-05-28",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 21,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3090",
      run: "Run004",
      dateFrom: "2023-05-29",
      dateTo: "2023-06-04",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 22,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3091",
      run: "Run004",
      dateFrom: "2023-06-05",
      dateTo: "2023-06-11",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 23,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3092",
      run: "Run004",
      dateFrom: "2023-06-12",
      dateTo: "2023-06-18",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 24,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3093",
      run: "Run004",
      dateFrom: "2023-06-19",
      dateTo: "2023-06-25",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 25,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3094",
      run: "Run004",
      dateFrom: "2023-06-26",
      dateTo: "2023-07-02",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 26,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3095",
      run: "Run004",
      dateFrom: "2023-07-03",
      dateTo: "2023-07-09",
      spend: 18000,
      timeGranularity: "weekly",
      timeByGranularity: 27,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3096",
      run: "Run004",
      dateFrom: "2023-07-10",
      dateTo: "2023-07-16",
      spend: 16200,
      timeGranularity: "weekly",
      timeByGranularity: 28,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3097",
      run: "Run004",
      dateFrom: "2023-07-17",
      dateTo: "2023-07-23",
      spend: 16800,
      timeGranularity: "weekly",
      timeByGranularity: 29,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3098",
      run: "Run004",
      dateFrom: "2023-07-24",
      dateTo: "2023-07-30",
      spend: 19200,
      timeGranularity: "weekly",
      timeByGranularity: 30,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3099",
      run: "Run004",
      dateFrom: "2023-07-31",
      dateTo: "2023-08-06",
      spend: 17159,
      timeGranularity: "weekly",
      timeByGranularity: 31,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3100",
      run: "Run004",
      dateFrom: "2023-08-07",
      dateTo: "2023-08-13",
      spend: 19200,
      timeGranularity: "weekly",
      timeByGranularity: 32,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3101",
      run: "Run004",
      dateFrom: "2023-08-14",
      dateTo: "2023-08-20",
      spend: 18600,
      timeGranularity: "weekly",
      timeByGranularity: 33,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3102",
      run: "Run004",
      dateFrom: "2023-08-21",
      dateTo: "2023-08-27",
      spend: 12600,
      timeGranularity: "weekly",
      timeByGranularity: 34,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3103",
      run: "Run004",
      dateFrom: "2023-08-28",
      dateTo: "2023-09-03",
      spend: 15600,
      timeGranularity: "weekly",
      timeByGranularity: 35,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3104",
      run: "Run004",
      dateFrom: "2023-09-04",
      dateTo: "2023-09-10",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 36,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3105",
      run: "Run004",
      dateFrom: "2023-09-11",
      dateTo: "2023-09-17",
      spend: 15000,
      timeGranularity: "weekly",
      timeByGranularity: 37,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3106",
      run: "Run004",
      dateFrom: "2023-09-18",
      dateTo: "2023-09-24",
      spend: 17400,
      timeGranularity: "weekly",
      timeByGranularity: 38,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3107",
      run: "Run004",
      dateFrom: "2023-09-25",
      dateTo: "2023-10-01",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 39,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3108",
      run: "Run004",
      dateFrom: "2023-10-02",
      dateTo: "2023-10-08",
      spend: 13200,
      timeGranularity: "weekly",
      timeByGranularity: 40,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3109",
      run: "Run004",
      dateFrom: "2023-10-09",
      dateTo: "2023-10-15",
      spend: 13800,
      timeGranularity: "weekly",
      timeByGranularity: 41,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3110",
      run: "Run004",
      dateFrom: "2023-10-16",
      dateTo: "2023-10-22",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 42,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3111",
      run: "Run004",
      dateFrom: "2023-10-23",
      dateTo: "2023-10-29",
      spend: 12600,
      timeGranularity: "weekly",
      timeByGranularity: 43,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3112",
      run: "Run004",
      dateFrom: "2023-10-30",
      dateTo: "2023-11-05",
      spend: 15600,
      timeGranularity: "weekly",
      timeByGranularity: 44,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3113",
      run: "Run004",
      dateFrom: "2023-11-06",
      dateTo: "2023-11-12",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 45,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3114",
      run: "Run004",
      dateFrom: "2023-11-13",
      dateTo: "2023-11-19",
      spend: 15600,
      timeGranularity: "weekly",
      timeByGranularity: 46,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3115",
      run: "Run004",
      dateFrom: "2023-11-20",
      dateTo: "2023-11-26",
      spend: 0,
      timeGranularity: "weekly",
      timeByGranularity: 47,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3116",
      run: "Run004",
      dateFrom: "2023-11-27",
      dateTo: "2023-12-03",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 48,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3117",
      run: "Run004",
      dateFrom: "2023-12-04",
      dateTo: "2023-12-10",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 49,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3118",
      run: "Run004",
      dateFrom: "2023-12-11",
      dateTo: "2023-12-17",
      spend: 12000,
      timeGranularity: "weekly",
      timeByGranularity: 50,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3119",
      run: "Run004",
      dateFrom: "2023-12-18",
      dateTo: "2023-12-24",
      spend: 14400,
      timeGranularity: "weekly",
      timeByGranularity: 51,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
    {
      id: "Budget3120",
      run: "Run004",
      dateFrom: "2023-12-25",
      dateTo: "2023-12-31",
      spend: 30000,
      timeGranularity: "weekly",
      timeByGranularity: 52,
      Market: "Market001",
      Brand: "Brand004",
      Channel: "Channel008",
      totalSpend: 680159,
    },
  ],
  AnalysisByDimension: [
    {
      id: "Analysis009",
      run: "Run001",
      analysisDimension: "Market",
      analysisValue: "Market001",
      spend: 227956,
      incrementalSales: 332205,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis010",
      run: "Run002",
      analysisDimension: "Market",
      analysisValue: "Market001",
      spend: 227956,
      incrementalSales: 339460,
      roi: -0.02137,
      spendPercentage: 0
    },
    {
      id: "Analysis011",
      run: "Run003",
      analysisDimension: "Market",
      analysisValue: "Market001",
      spend: 273547,
      incrementalSales: 343798,
      roi: 0.15953,
      spendPercentage: 0
    },
    {
      id: "Analysis012",
      run: "Run004",
      analysisDimension: "Market",
      analysisValue: "Market001",
      spend: 181953,
      incrementalSales: 332205,
      roi: -0.20181,
      spendPercentage: 0
    },
    {
      id: "Analysis013",
      run: "Run005",
      analysisDimension: "Market",
      analysisValue: "Market002",
      spend: 352677,
      incrementalSales: 120214,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis014",
      run: "Run006",
      analysisDimension: "Market",
      analysisValue: "Market002",
      spend: 352677,
      incrementalSales: 123301,
      roi: -0.02503,
      spendPercentage: 0
    },
    {
      id: "Analysis015",
      run: "Run007",
      analysisDimension: "Market",
      analysisValue: "Market002",
      spend: 423213,
      incrementalSales: 124738,
      roi: 0.15648,
      spendPercentage: 0
    },
    {
      id: "Analysis016",
      run: "Run008",
      analysisDimension: "Market",
      analysisValue: "Market002",
      spend: 282507,
      incrementalSales: 120214,
      roi: -0.19897,
      spendPercentage: 0
    },
    {
      id: "Analysis021",
      run: "Run001",
      analysisDimension: "Brand",
      analysisValue: "Brand001",
      spend: 227956,
      incrementalSales: 332205,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis022",
      run: "Run005",
      analysisDimension: "Brand",
      analysisValue: "Brand002",
      spend: 352677,
      incrementalSales: 120214,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis023",
      run: "Run002",
      analysisDimension: "Brand",
      analysisValue: "Brand001",
      spend: 227956,
      incrementalSales: 339460,
      roi: -0.02137,
      spendPercentage: 0
    },
    {
      id: "Analysis024",
      run: "Run006",
      analysisDimension: "Brand",
      analysisValue: "Brand002",
      spend: 352677,
      incrementalSales: 123301,
      roi: -0.02503,
      spendPercentage: 0
    },
    {
      id: "Analysis025",
      run: "Run003",
      analysisDimension: "Brand",
      analysisValue: "Brand001",
      spend: 273547,
      incrementalSales: 343798,
      roi: 0.15953,
      spendPercentage: 0
    },
    {
      id: "Analysis026",
      run: "Run007",
      analysisDimension: "Brand",
      analysisValue: "Brand002",
      spend: 423213,
      incrementalSales: 124738,
      roi: 0.15648,
      spendPercentage: 0
    },
    {
      id: "Analysis027",
      run: "Run004",
      analysisDimension: "Brand",
      analysisValue: "Brand001",
      spend: 181953,
      incrementalSales: 332205,
      roi: -0.20181,
      spendPercentage: 0
    },
    {
      id: "Analysis028",
      run: "Run008",
      analysisDimension: "Brand",
      analysisValue: "Brand002",
      spend: 282507,
      incrementalSales: 120214,
      roi: -0.19897,
      spendPercentage: 0
    },
    {
      id: "Analysis029",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel001",
      spend: 227956,
      incrementalSales: 332205,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis030",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel002",
      spend: 196214,
      incrementalSales: 266081,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis031",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel003",
      spend: 130052,
      incrementalSales: 206417,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis032",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel004",
      spend: 2311,
      incrementalSales: 4248,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis033",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel005",
      spend: 2343,
      incrementalSales: 4472,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis034",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel006",
      spend: 61508,
      incrementalSales: 50945,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis035",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel007",
      spend: 31742,
      incrementalSales: 66123,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis036",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel008",
      spend: 10310,
      incrementalSales: 18281,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis037",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel009",
      spend: 14545,
      incrementalSales: 39746,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis038",
      run: "Run001",
      analysisDimension: "Channel",
      analysisValue: "Channel010",
      spend: 6887,
      incrementalSales: 8097,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis039",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel001",
      spend: 227956,
      incrementalSales: 339460,
      roi: -0.02137,
      spendPercentage: 0
    },
    {
      id: "Analysis040",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel002",
      spend: 189514,
      incrementalSales: 271914,
      roi: -0.05487,
      spendPercentage: 0
    },
    {
      id: "Analysis041",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel003",
      spend: 104153,
      incrementalSales: 210920,
      roi: -0.21624,
      spendPercentage: 0
    },
    {
      id: "Analysis042",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel004",
      spend: 2791,
      incrementalSales: 4345,
      roi: 0.18067,
      spendPercentage: 0
    },
    {
      id: "Analysis043",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel005",
      spend: 2826,
      incrementalSales: 4582,
      roi: 0.17728,
      spendPercentage: 0
    },
    {
      id: "Analysis044",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel006",
      spend: 79744,
      incrementalSales: 52068,
      roi: 0.26851,
      spendPercentage: 0
    },
    {
      id: "Analysis045",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel007",
      spend: 38442,
      incrementalSales: 67546,
      roi: 0.18558,
      spendPercentage: 0
    },
    {
      id: "Analysis046",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel008",
      spend: 10656,
      incrementalSales: 18668,
      roi: 0.0121,
      spendPercentage: 0
    },
    {
      id: "Analysis047",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel009",
      spend: 18858,
      incrementalSales: 40595,
      roi: 0.26934,
      spendPercentage: 0
    },
    {
      id: "Analysis048",
      run: "Run002",
      analysisDimension: "Channel",
      analysisValue: "Channel010",
      spend: 8928,
      incrementalSales: 8282,
      roi: 0.26751,
      spendPercentage: 0
    },
    {
      id: "Analysis049",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel001",
      spend: 273547,
      incrementalSales: 343798,
      roi: 0.15953,
      spendPercentage: 0
    },
    {
      id: "Analysis050",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel002",
      spend: 232394,
      incrementalSales: 275367,
      roi: 0.14445,
      spendPercentage: 0
    },
    {
      id: "Analysis051",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel003",
      spend: 146617,
      incrementalSales: 213621,
      roi: 0.08935,
      spendPercentage: 0
    },
    {
      id: "Analysis052",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel004",
      spend: 2996,
      incrementalSales: 4396,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis053",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel005",
      spend: 3037,
      incrementalSales: 4628,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis054",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel006",
      spend: 79744,
      incrementalSales: 52723,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis055",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel007",
      spend: 41153,
      incrementalSales: 68431,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis056",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel008",
      spend: 13367,
      incrementalSales: 18919,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis057",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel009",
      spend: 18858,
      incrementalSales: 41133,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis058",
      run: "Run003",
      analysisDimension: "Channel",
      analysisValue: "Channel010",
      spend: 8928,
      incrementalSales: 8380,
      roi: 0.25276,
      spendPercentage: 0
    },
    {
      id: "Analysis059",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel001",
      spend: 181953,
      incrementalSales: 332205,
      roi: -0.20181,
      spendPercentage: 0
    },
    {
      id: "Analysis060",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel002",
      spend: 147954,
      incrementalSales: 266081,
      roi: -0.24595,
      spendPercentage: 0
    },
    {
      id: "Analysis061",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel003",
      spend: 53234,
      incrementalSales: 206417,
      roi: -0.59067,
      spendPercentage: 0
    },
    {
      id: "Analysis062",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel004",
      spend: 2050,
      incrementalSales: 4248,
      roi: -0.11268,
      spendPercentage: 0
    },
    {
      id: "Analysis063",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel005",
      spend: 2085,
      incrementalSales: 4472,
      roi: -0.11002,
      spendPercentage: 0
    },
    {
      id: "Analysis064",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel006",
      spend: 53234,
      incrementalSales: 50945,
      roi: -0.13452,
      spendPercentage: 0
    },
    {
      id: "Analysis065",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel007",
      spend: 33998,
      incrementalSales: 66123,
      roi: 0.07108,
      spendPercentage: 0
    },
    {
      id: "Analysis066",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel008",
      spend: 7181,
      incrementalSales: 18281,
      roi: -0.30347,
      spendPercentage: 0
    },
    {
      id: "Analysis067",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel009",
      spend: 18815,
      incrementalSales: 39746,
      roi: 0.29355,
      spendPercentage: 0
    },
    {
      id: "Analysis068",
      run: "Run004",
      analysisDimension: "Channel",
      analysisValue: "Channel010",
      spend: 8002,
      incrementalSales: 8097,
      roi: 0.16196,
      spendPercentage: 0
    },
    {
      id: "Analysis069",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel011",
      spend: 352677,
      incrementalSales: 120214,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis070",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel012",
      spend: 240564,
      incrementalSales: 78172,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis071",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel013",
      spend: 103676,
      incrementalSales: 33895,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis072",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel014",
      spend: 136888,
      incrementalSales: 44277,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis073",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel015",
      spend: 112113,
      incrementalSales: 42042,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis074",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel016",
      spend: 99704,
      incrementalSales: 39653,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis075",
      run: "Run005",
      analysisDimension: "Channel",
      analysisValue: "Channel017",
      spend: 12409,
      incrementalSales: 2389,
      roi: 0,
      spendPercentage: 0
    },
    {
      id: "Analysis076",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel011",
      spend: 352677,
      incrementalSales: 123301,
      roi: -0.02503,
      spendPercentage: 0
    },
    {
      id: "Analysis077",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel012",
      spend: 219330,
      incrementalSales: 80179,
      roi: -0.11109,
      spendPercentage: 0
    },
    {
      id: "Analysis078",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel013",
      spend: 105527,
      incrementalSales: 34766,
      roi: -0.00763,
      spendPercentage: 0
    },
    {
      id: "Analysis079",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel014",
      spend: 113803,
      incrementalSales: 45414,
      roi: -0.18945,
      spendPercentage: 0
    },
    {
      id: "Analysis080",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel015",
      spend: 133348,
      incrementalSales: 43121,
      roi: 0.15963,
      spendPercentage: 0
    },
    {
      id: "Analysis081",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel016",
      spend: 123093,
      incrementalSales: 40671,
      roi: 0.20368,
      spendPercentage: 0
    },
    {
      id: "Analysis082",
      run: "Run006",
      analysisDimension: "Channel",
      analysisValue: "Channel017",
      spend: 10254,
      incrementalSales: 2451,
      roi: -0.19436,
      spendPercentage: 0
    },
    {
      id: "Analysis083",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel011",
      spend: 423213,
      incrementalSales: 124738,
      roi: 0.15648,
      spendPercentage: 0
    },
    {
      id: "Analysis084",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel012",
      spend: 286586,
      incrementalSales: 81114,
      roi: 0.14811,
      spendPercentage: 0
    },
    {
      id: "Analysis085",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel013",
      spend: 136436,
      incrementalSales: 35171,
      roi: 0.26826,
      spendPercentage: 0
    },
    {
      id: "Analysis086",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel014",
      spend: 150150,
      incrementalSales: 45943,
      roi: 0.0571,
      spendPercentage: 0
    },
    {
      id: "Analysis087",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel015",
      spend: 136627,
      incrementalSales: 43624,
      roi: 0.17445,
      spendPercentage: 0
    },
    {
      id: "Analysis088",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel016",
      spend: 123094,
      incrementalSales: 41145,
      roi: 0.18981,
      spendPercentage: 0
    },
    {
      id: "Analysis089",
      run: "Run007",
      analysisDimension: "Channel",
      analysisValue: "Channel017",
      spend: 13533,
      incrementalSales: 2479,
      roi: 0.05104,
      spendPercentage: 0
    },
    {
      id: "Analysis090",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel011",
      spend: 282507,
      incrementalSales: 120214,
      roi: -0.19896,
      spendPercentage: 0
    },
    {
      id: "Analysis091",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel012",
      spend: 169393,
      incrementalSales: 78172,
      roi: -0.29585,
      spendPercentage: 0
    },
    {
      id: "Analysis092",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel013",
      spend: 73561,
      incrementalSales: 33895,
      roi: -0.29047,
      spendPercentage: 0
    },
    {
      id: "Analysis093",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel014",
      spend: 95832,
      incrementalSales: 44277,
      roi: -0.29993,
      spendPercentage: 0
    },
    {
      id: "Analysis094",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel015",
      spend: 113115,
      incrementalSales: 42042,
      roi: 0.00893,
      spendPercentage: 0
    },
    {
      id: "Analysis095",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel016",
      spend: 104950,
      incrementalSales: 39653,
      roi: 0.05262,
      spendPercentage: 0
    },
    {
      id: "Analysis096",
      run: "Run008",
      analysisDimension: "Channel",
      analysisValue: "Channel017",
      spend: 8164,
      incrementalSales: 2389,
      roi: -0.34207,
      spendPercentage: 0
    }
  ],
  salesByChannel: [
    {
      id: "CIOSK001",
      run: "Run004",
      channel: "Channel001",
      IncrementalSales: 14000000,
      timeGranularity: "monthly",
      timeByGranularity: 1,
    },
    {
      id: "CIOSK002",
      run: "Run004",
      channel: "Channel001",
      IncrementalSales: 18000000,
      timeGranularity: "monthly",
      timeByGranularity: 2,
      __EMPTY_2:
        'AD: THINK WE MUST HIDE THIS GRAPH SINCE STUART SAYS IT DOESN"T MAKE SENSE IN ITS CURRENT FORM',
    },
    {
      id: "CIOSK003",
      run: "Run004",
      channel: "Channel001",
      IncrementalSales: 30000000,
      timeGranularity: "monthly",
      timeByGranularity: 3,
    },
  ],
  channelImpactOnSalesAndKPIs: [
    {
      id: "CIOSK001",
      run: "Run004",
      channel: "Channel001",
      kpiValue: 0.12,
      kpiDef: "KpiDef001",
      IncrementalSales: 14000000,
      spend: 450000000,
    },
    {
      id: "CIOSK002",
      run: "Run004",
      channel: "Channel001",
      kpiValue: 0.18,
      kpiDef: "KpiDef001",
      IncrementalSales: 18000000,
      spend: 25000000,
      __EMPTY_1:
        "AD: THINK WE MUST HIDE THIS GRAPH SINCE WE DONT KNOW RECALL VALUES, and it was decided with the modellers to remoe this chart as not useful",
    },
    {
      id: "CIOSK003",
      run: "Run004",
      channel: "Channel001",
      kpiValue: 0.35,
      kpiDef: "KpiDef001",
      IncrementalSales: 30000000,
      spend: 20000000,
    },
  ],
  BudgetPerChannel: [
    {
      id: "BudgetPerChannel001",
      run: "Run001",
      budget: 3348495.668465526,
      channel: "Channel001",
    },
    {
      id: "BudgetPerChannel002",
      run: "Run001",
      budget: 56865.8650919651,
      channel: "Channel002",
    },
    {
      id: "BudgetPerChannel003",
      run: "Run001",
      budget: 94106.46478751037,
      channel: "Channel003",
    },
    {
      id: "BudgetPerChannel004",
      run: "Run001",
      budget: 528702.2070380948,
      channel: "Channel004",
    },
    {
      id: "BudgetPerChannel005",
      run: "Run001",
      budget: 528000.0000000034,
      channel: "Channel005",
    },
    {
      id: "BudgetPerChannel006",
      run: "Run001",
      budget: 312409.44,
      channel: "Channel006",
    },
    {
      id: "BudgetPerChannel007",
      run: "Run001",
      budget: 65000,
      channel: "Channel012",
    },
    {
      id: "BudgetPerChannel008",
      run: "Run001",
      budget: 90000,
      channel: "Channel013",
    },
    {
      id: "BudgetPerChannel009",
      run: "Run001",
      budget: 231941.45208131665,
      channel: "Channel014",
    },
  ],
  ByChannelAnalysis: [
    {
      id: "ByChannelAnalysis001",
      run: "Run001",
      channel: "Channel001",
      spend: 10000,
      income: 20000,
    },
  ],
  BudgetVsRoi: [
    {
      id: "BudgetVsRoi001",
      run: "Run001",
      budget: "Budget001",
      roi: "roi",
    },
  ],
  "Transformed Data": [
    {
      "IMPORTANT DETAIL":
        "In order to insert the PivotTable you selected, we had to organize your data in columns with a single header row.",
    },
    {
      __EMPTY: "Field1",
      __EMPTY_1: "Field2",
      __EMPTY_2: "Field3",
      __EMPTY_3: 1,
      __EMPTY_4: 2,
      __EMPTY_5: 3,
      __EMPTY_6: 4,
      __EMPTY_7: 5,
      __EMPTY_8: 6,
      __EMPTY_9: 7,
      __EMPTY_10: 8,
      __EMPTY_11: 9,
      __EMPTY_12: 10,
      __EMPTY_13: 11,
      __EMPTY_14: 12,
      __EMPTY_15: 13,
      __EMPTY_16: 14,
      __EMPTY_17: 15,
      __EMPTY_18: 16,
      __EMPTY_19: 17,
      __EMPTY_20: 18,
      __EMPTY_21: 19,
      __EMPTY_22: 20,
      __EMPTY_23: 21,
      __EMPTY_24: 22,
      __EMPTY_25: 23,
      __EMPTY_26: 24,
      __EMPTY_27: 25,
      __EMPTY_28: 26,
      __EMPTY_29: 27,
      __EMPTY_30: 28,
      __EMPTY_31: 29,
      __EMPTY_32: 30,
      __EMPTY_33: 31,
      __EMPTY_34: 32,
      __EMPTY_35: 33,
      __EMPTY_36: 34,
      __EMPTY_37: 35,
      __EMPTY_38: 36,
      __EMPTY_39: 37,
      __EMPTY_40: 38,
      __EMPTY_41: 39,
      __EMPTY_42: 40,
      __EMPTY_43: 41,
      __EMPTY_44: 42,
      __EMPTY_45: 43,
      __EMPTY_46: 44,
      __EMPTY_47: 45,
      __EMPTY_48: 46,
      __EMPTY_49: 47,
      __EMPTY_50: 48,
      __EMPTY_51: 49,
      __EMPTY_52: 50,
      __EMPTY_53: 51,
      __EMPTY_54: 52,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel009",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel005",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 11000,
      __EMPTY_18: 11000,
      __EMPTY_19: 11000,
      __EMPTY_20: 11000,
      __EMPTY_21: 13000,
      __EMPTY_22: 13000,
      __EMPTY_23: 5000,
      __EMPTY_24: 5000,
      __EMPTY_25: 5000,
      __EMPTY_26: 5000,
      __EMPTY_27: 13000,
      __EMPTY_28: 13000,
      __EMPTY_29: 11000,
      __EMPTY_30: 11000,
      __EMPTY_31: 11000,
      __EMPTY_32: 11000,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 8000,
      __EMPTY_38: 8000,
      __EMPTY_39: 13000,
      __EMPTY_40: 5000,
      __EMPTY_41: 5000,
      __EMPTY_42: 5000,
      __EMPTY_43: 5000,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel001",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 3000,
      __EMPTY_8: 3000,
      __EMPTY_9: 3000,
      __EMPTY_10: 3000,
      __EMPTY_11: 3000,
      __EMPTY_12: 3000,
      __EMPTY_13: 0,
      __EMPTY_14: 199000,
      __EMPTY_15: 191000,
      __EMPTY_16: 0,
      __EMPTY_17: 194000,
      __EMPTY_18: 0,
      __EMPTY_19: 194000,
      __EMPTY_20: 231000,
      __EMPTY_21: 0,
      __EMPTY_22: 178000,
      __EMPTY_23: 158000,
      __EMPTY_24: 0,
      __EMPTY_25: 149000,
      __EMPTY_26: 0,
      __EMPTY_27: 132000,
      __EMPTY_28: 132000,
      __EMPTY_29: 108000,
      __EMPTY_30: 0,
      __EMPTY_31: 89000,
      __EMPTY_32: 75000,
      __EMPTY_33: 0,
      __EMPTY_34: 62000,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 89000,
      __EMPTY_38: 132000,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel008",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 18000,
      __EMPTY_18: 18000,
      __EMPTY_19: 18000,
      __EMPTY_20: 18000,
      __EMPTY_21: 15000,
      __EMPTY_22: 15000,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel009",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel005",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 9000,
      __EMPTY_21: 9000,
      __EMPTY_22: 9000,
      __EMPTY_23: 17000,
      __EMPTY_24: 17000,
      __EMPTY_25: 19000,
      __EMPTY_26: 19000,
      __EMPTY_27: 19000,
      __EMPTY_28: 19000,
      __EMPTY_29: 15000,
      __EMPTY_30: 15000,
      __EMPTY_31: 15000,
      __EMPTY_32: 15000,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel001",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel008",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 10000,
      __EMPTY_21: 10000,
      __EMPTY_22: 10000,
      __EMPTY_23: 38000,
      __EMPTY_24: 38000,
      __EMPTY_25: 34000,
      __EMPTY_26: 34000,
      __EMPTY_27: 34000,
      __EMPTY_28: 34000,
      __EMPTY_29: 25000,
      __EMPTY_30: 25000,
      __EMPTY_31: 25000,
      __EMPTY_32: 25000,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel009",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 5000,
      __EMPTY_25: 5000,
      __EMPTY_26: 5000,
      __EMPTY_27: 5000,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel005",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 9000,
      __EMPTY_26: 9000,
      __EMPTY_27: 9000,
      __EMPTY_28: 9000,
      __EMPTY_29: 7000,
      __EMPTY_30: 7000,
      __EMPTY_31: 6000,
      __EMPTY_32: 6000,
      __EMPTY_33: 7000,
      __EMPTY_34: 7000,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel001",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 110000,
      __EMPTY_26: 110000,
      __EMPTY_27: 110000,
      __EMPTY_28: 110000,
      __EMPTY_29: 74000,
      __EMPTY_30: 74000,
      __EMPTY_31: 74000,
      __EMPTY_32: 74000,
      __EMPTY_33: 50000,
      __EMPTY_34: 50000,
      __EMPTY_35: 50000,
      __EMPTY_36: 50000,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel008",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 5000,
      __EMPTY_25: 15000,
      __EMPTY_26: 14924,
      __EMPTY_27: 15000,
      __EMPTY_28: 15000,
      __EMPTY_29: 29000,
      __EMPTY_30: 29000,
      __EMPTY_31: 29000,
      __EMPTY_32: 29000,
      __EMPTY_33: 6000,
      __EMPTY_34: 6000,
      __EMPTY_35: 6000,
      __EMPTY_36: 6000,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel005",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 10000,
      __EMPTY_26: 10000,
      __EMPTY_27: 60000,
      __EMPTY_28: 60000,
      __EMPTY_29: 60000,
      __EMPTY_30: 10000,
      __EMPTY_31: 10000,
      __EMPTY_32: 10000,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel001",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel008",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 0,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel004",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 10000,
      __EMPTY_26: 10000,
      __EMPTY_27: 20000,
      __EMPTY_28: 20000,
      __EMPTY_29: 18000,
      __EMPTY_30: 18000,
      __EMPTY_31: 18000,
      __EMPTY_32: 18000,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 0,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel005",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 14000,
      __EMPTY_27: 14000,
      __EMPTY_28: 14000,
      __EMPTY_29: 11000,
      __EMPTY_30: 0,
      __EMPTY_31: 0,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 0,
      __EMPTY_35: 0,
      __EMPTY_36: 0,
      __EMPTY_37: 13000,
      __EMPTY_38: 8000,
      __EMPTY_39: 8000,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel001",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 84000,
      __EMPTY_30: 74000,
      __EMPTY_31: 64000,
      __EMPTY_32: 0,
      __EMPTY_33: 0,
      __EMPTY_34: 47000,
      __EMPTY_35: 45000,
      __EMPTY_36: 0,
      __EMPTY_37: 53000,
      __EMPTY_38: 69000,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
    {
      __EMPTY: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel008",
      __EMPTY_3: 0,
      __EMPTY_4: 0,
      __EMPTY_5: 0,
      __EMPTY_6: 0,
      __EMPTY_7: 0,
      __EMPTY_8: 0,
      __EMPTY_9: 0,
      __EMPTY_10: 0,
      __EMPTY_11: 0,
      __EMPTY_12: 0,
      __EMPTY_13: 0,
      __EMPTY_14: 0,
      __EMPTY_15: 0,
      __EMPTY_16: 0,
      __EMPTY_17: 0,
      __EMPTY_18: 0,
      __EMPTY_19: 0,
      __EMPTY_20: 0,
      __EMPTY_21: 0,
      __EMPTY_22: 0,
      __EMPTY_23: 0,
      __EMPTY_24: 0,
      __EMPTY_25: 0,
      __EMPTY_26: 0,
      __EMPTY_27: 0,
      __EMPTY_28: 0,
      __EMPTY_29: 35000,
      __EMPTY_30: 36000,
      __EMPTY_31: 42000,
      __EMPTY_32: 47000,
      __EMPTY_33: 31000,
      __EMPTY_34: 31000,
      __EMPTY_35: 18500,
      __EMPTY_36: 34000,
      __EMPTY_37: 34000,
      __EMPTY_38: 0,
      __EMPTY_39: 0,
      __EMPTY_40: 0,
      __EMPTY_41: 0,
      __EMPTY_42: 0,
      __EMPTY_43: 0,
      __EMPTY_44: 0,
      __EMPTY_45: 0,
      __EMPTY_46: 0,
      __EMPTY_47: 0,
      __EMPTY_48: 0,
      __EMPTY_49: 0,
      __EMPTY_50: 0,
      __EMPTY_51: 0,
      __EMPTY_52: 0,
      __EMPTY_53: 0,
      __EMPTY_54: 0,
    },
  ],
  "Scenario Polestar data": [
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel009",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 11000,
      16: 11000,
      17: 11000,
      18: 11000,
      19: 13000,
      20: 13000,
      21: 5000,
      22: 5000,
      23: 5000,
      24: 5000,
      25: 13000,
      26: 13000,
      27: 11000,
      28: 11000,
      29: 11000,
      30: 11000,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 8000,
      36: 8000,
      37: 13000,
      38: 5000,
      39: 5000,
      40: 5000,
      41: 5000,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel005",
      TOTAL: 209000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 3000,
      6: 3000,
      7: 3000,
      8: 3000,
      9: 3000,
      10: 3000,
      11: 0,
      12: 199000,
      13: 191000,
      14: 0,
      15: 194000,
      16: 0,
      17: 194000,
      18: 231000,
      19: 0,
      20: 178000,
      21: 158000,
      22: 0,
      23: 149000,
      24: 0,
      25: 132000,
      26: 132000,
      27: 108000,
      28: 0,
      29: 89000,
      30: 75000,
      31: 0,
      32: 62000,
      33: 0,
      34: 0,
      35: 89000,
      36: 132000,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel001",
      TOTAL: 2331000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 18000,
      16: 18000,
      17: 18000,
      18: 18000,
      19: 15000,
      20: 15000,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel008",
      TOTAL: 102000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 5000,
      23: 5000,
      24: 5000,
      25: 5000,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel009",
      TOTAL: 20000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 9000,
      24: 9000,
      25: 9000,
      26: 9000,
      27: 7000,
      28: 7000,
      29: 6000,
      30: 6000,
      31: 7000,
      32: 7000,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel005",
      TOTAL: 76000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 110000,
      24: 110000,
      25: 110000,
      26: 110000,
      27: 74000,
      28: 74000,
      29: 74000,
      30: 74000,
      31: 50000,
      32: 50000,
      33: 50000,
      34: 50000,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel001",
      TOTAL: 936000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 5000,
      23: 15000,
      24: 14924,
      25: 15000,
      26: 15000,
      27: 29000,
      28: 29000,
      29: 29000,
      30: 29000,
      31: 6000,
      32: 6000,
      33: 6000,
      34: 6000,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel008",
      TOTAL: 204924,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 10000,
      24: 10000,
      25: 60000,
      26: 60000,
      27: 60000,
      28: 10000,
      29: 10000,
      30: 10000,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel005",
      TOTAL: 230000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel001",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 10000,
      24: 10000,
      25: 20000,
      26: 20000,
      27: 18000,
      28: 18000,
      29: 18000,
      30: 18000,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel004",
      TOTAL: 132000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 14000,
      25: 14000,
      26: 14000,
      27: 11000,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 13000,
      36: 8000,
      37: 8000,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel005",
      TOTAL: 82000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 84000,
      28: 74000,
      29: 64000,
      30: 0,
      31: 0,
      32: 47000,
      33: 45000,
      34: 0,
      35: 53000,
      36: 69000,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel001",
      TOTAL: 436000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 35000,
      28: 36000,
      29: 42000,
      30: 47000,
      31: 31000,
      32: 31000,
      33: 18500,
      34: 34000,
      35: 34000,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run001",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel008",
      TOTAL: 308500,
    },
    {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      11: 11,
      12: 12,
      13: 13,
      14: 14,
      15: 15,
      16: 16,
      17: 17,
      18: 18,
      19: 19,
      20: 20,
      21: 21,
      22: 22,
      23: 23,
      24: 24,
      25: 25,
      26: 26,
      27: 27,
      28: 28,
      29: 29,
      30: 30,
      31: 31,
      32: 32,
      33: 33,
      34: 34,
      35: 35,
      36: 36,
      37: 37,
      38: 38,
      39: 39,
      40: 40,
      41: 41,
      42: 42,
      43: 43,
      44: 44,
      45: 45,
      46: 46,
      47: 47,
      48: 48,
      49: 49,
      50: 50,
      51: 51,
      52: 52,
    },
    {
      1: 11000,
      2: 0,
      3: 12000,
      4: 0,
      5: 10000,
      6: 10000,
      7: 0,
      8: 10000,
      9: 0,
      10: 10000,
      11: 10000,
      12: 0,
      13: 10000,
      14: 10000,
      15: 10000,
      16: 10000,
      17: 0,
      18: 0,
      19: 10000,
      20: 0,
      21: 11500,
      22: 10000,
      23: 10500,
      24: 12000,
      25: 0,
      26: 10000,
      27: 10000,
      28: 12000,
      29: 0,
      30: 13476.895323201701,
      31: 10000,
      32: 10000,
      33: 10000,
      34: 0,
      35: 0,
      36: 13000,
      37: 12500,
      38: 0,
      39: 0,
      40: 10000,
      41: 0,
      42: 10000,
      43: 0,
      44: 10000,
      45: 0,
      46: 0,
      47: 10000,
      48: 0,
      49: 0,
      50: 0,
      51: 15000,
      52: 10000,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel009",
      TOTAL: 332976.89532320166,
    },
    {
      1: 13500,
      2: 0,
      3: 11000,
      4: 0,
      5: 10000,
      6: 10000,
      7: 0,
      8: 10000,
      9: 0,
      10: 10000,
      11: 10000,
      12: 0,
      13: 11390.8868623431,
      14: 0,
      15: 10000,
      16: 10000,
      17: 0,
      18: 10000,
      19: 10000,
      20: 0,
      21: 12000,
      22: 0,
      23: 12000,
      24: 10000,
      25: 10000,
      26: 0,
      27: 11500,
      28: 0,
      29: 10000,
      30: 10500,
      31: 10000,
      32: 12000,
      33: 0,
      34: 10000,
      35: 0,
      36: 10000,
      37: 10000,
      38: 0,
      39: 10000,
      40: 10000,
      41: 0,
      42: 10500,
      43: 0,
      44: 10000,
      45: 10000,
      46: 0,
      47: 10000,
      48: 10000,
      49: 0,
      50: 10000,
      51: 10000,
      52: 15500,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel005",
      TOTAL: 359890.8868623431,
    },
    {
      1: 101000,
      2: 49000,
      3: 75500,
      4: 40000,
      5: 34500,
      6: 47000,
      7: 22000,
      8: 49000,
      9: 0,
      10: 58500,
      11: 68500,
      12: 0,
      13: 53000,
      14: 39500,
      15: 41000,
      16: 59000,
      17: 10500,
      18: 0,
      19: 0,
      20: 0,
      21: 53000,
      22: 63500,
      23: 79000,
      24: 53000,
      25: 0,
      26: 0,
      27: 117242.380778881,
      28: 68500,
      29: 62500,
      30: 50500,
      31: 116000,
      32: 92500,
      33: 63500,
      34: 140000,
      35: 17500,
      36: 0,
      37: 21000,
      38: 0,
      39: 0,
      40: 10000,
      41: 0,
      42: 10000,
      43: 0,
      44: 22000,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 17000,
      51: 113500,
      52: 51500,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel001",
      TOTAL: 1969742.380778881,
    },
    {
      1: 14305.8424062112,
      2: 10000,
      3: 13500,
      4: 0,
      5: 10500,
      6: 10000,
      7: 10000,
      8: 10000,
      9: 0,
      10: 13500,
      11: 14000,
      12: 0,
      13: 12000,
      14: 10000,
      15: 10000,
      16: 13000,
      17: 0,
      18: 10000,
      19: 11000,
      20: 10000,
      21: 14000,
      22: 11500,
      23: 12000,
      24: 15000,
      25: 10000,
      26: 10000,
      27: 10000,
      28: 11000,
      29: 10000,
      30: 14000,
      31: 12500,
      32: 12500,
      33: 11000,
      34: 0,
      35: 10000,
      36: 13000,
      37: 12500,
      38: 10000,
      39: 10000,
      40: 14000,
      41: 0,
      42: 12000,
      43: 10000,
      44: 10000,
      45: 10000,
      46: 0,
      47: 11000,
      48: 0,
      49: 10500,
      50: 0,
      51: 17000,
      52: 16000,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel008",
      TOTAL: 501305.8424062112,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel009",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel005",
      TOTAL: 0,
    },
    {
      1: 18500,
      2: 21500,
      3: 55500,
      4: 44500,
      5: 0,
      6: 22000,
      7: 21500,
      8: 44000,
      9: 52000,
      10: 39500,
      11: 61000,
      12: 25500,
      13: 0,
      14: 0,
      15: 0,
      16: 10000,
      17: 27000,
      18: 0,
      19: 0,
      20: 22000,
      21: 21500,
      22: 14000,
      23: 22000,
      24: 23500,
      25: 33500,
      26: 59500,
      27: 64000,
      28: 40000,
      29: 43500,
      30: 46000,
      31: 45000,
      32: 53500,
      33: 44000,
      34: 42000,
      35: 41000,
      36: 0,
      37: 14000,
      38: 43000,
      39: 0,
      40: 20500,
      41: 10000,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 10000,
      48: 13500,
      49: 47000,
      50: 21500,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel001",
      TOTAL: 1237000,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 11000,
      15: 17000,
      16: 0,
      17: 0,
      18: 0,
      19: 10000,
      20: 12000,
      21: 10000,
      22: 10500,
      23: 10146,
      24: 13000,
      25: 0,
      26: 10000,
      27: 10000,
      28: 10000,
      29: 15000,
      30: 12000,
      31: 15000,
      32: 14500,
      33: 0,
      34: 10000,
      35: 0,
      36: 11000,
      37: 10000,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 12000,
      50: 11000,
      51: 18500,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel005",
      TOTAL: 252646,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 26354,
      28: 0,
      29: 0,
      30: 0,
      31: 52500,
      32: 30500,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel001",
      TOTAL: 109354,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel004",
      TOTAL: 0,
    },
    {
      1: 12094,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 12500,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 10000,
      12: 0,
      13: 10000,
      14: 0,
      15: 0,
      16: 10000,
      17: 0,
      18: 0,
      19: 10000,
      20: 0,
      21: 10500,
      22: 0,
      23: 0,
      24: 10000,
      25: 0,
      26: 10000,
      27: 0,
      28: 10000,
      29: 0,
      30: 13000,
      31: 0,
      32: 0,
      33: 10000,
      34: 0,
      35: 10000,
      36: 0,
      37: 10000,
      38: 0,
      39: 0,
      40: 10000,
      41: 0,
      42: 0,
      43: 0,
      44: 10500,
      45: 0,
      46: 0,
      47: 0,
      48: 10000,
      49: 0,
      50: 0,
      51: 10000,
      52: 10000,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel005",
      TOTAL: 198594,
    },
    {
      1: 26000,
      2: 0,
      3: 0,
      4: 14500,
      5: 0,
      6: 0,
      7: 0,
      8: 15500,
      9: 0,
      10: 0,
      11: 0,
      12: 14000,
      13: 0,
      14: 0,
      15: 0,
      16: 29500,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 22500,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 38500,
      28: 10000,
      29: 0,
      30: 0,
      31: 21500,
      32: 17000,
      33: 10000,
      34: 30228,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 11000,
      50: 0,
      51: 30000,
      52: 0,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel001",
      TOTAL: 290228,
    },
    {
      1: 13000,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 10000,
      7: 0,
      8: 0,
      9: 10000,
      10: 0,
      11: 10000,
      12: 0,
      13: 0,
      14: 10000,
      15: 0,
      16: 14000,
      17: 0,
      18: 10000,
      19: 0,
      20: 10000,
      21: 10000,
      22: 10000,
      23: 10000,
      24: 10000,
      25: 13000,
      26: 10000,
      27: 11500,
      28: 10000,
      29: 10500,
      30: 13000,
      31: 11000,
      32: 13000,
      33: 15000,
      34: 0,
      35: 10500,
      36: 10000,
      37: 10000,
      38: 10000,
      39: 0,
      40: 0,
      41: 10000,
      42: 0,
      43: 0,
      44: 10000,
      45: 0,
      46: 10000,
      47: 0,
      48: 0,
      49: 0,
      50: 10000,
      51: 10000,
      52: 12678,
      __EMPTY: "Run002",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel008",
      TOTAL: 337178,
    },
    {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      11: 11,
      12: 12,
      13: 13,
      14: 14,
      15: 15,
      16: 16,
      17: 17,
      18: 18,
      19: 19,
      20: 20,
      21: 21,
      22: 22,
      23: 23,
      24: 24,
      25: 25,
      26: 26,
      27: 27,
      28: 28,
      29: 29,
      30: 30,
      31: 31,
      32: 32,
      33: 33,
      34: 34,
      35: 35,
      36: 36,
      37: 37,
      38: 38,
      39: 39,
      40: 40,
      41: 41,
      42: 42,
      43: 43,
      44: 44,
      45: 45,
      46: 46,
      47: 47,
      48: 48,
      49: 49,
      50: 50,
      51: 51,
      52: 52,
    },
    {
      1: 10000,
      2: 0,
      3: 11000,
      4: 0,
      5: 0,
      6: 10500,
      7: 0,
      8: 0,
      9: 0,
      10: 10000,
      11: 10000,
      12: 0,
      13: 10000,
      14: 0,
      15: 10000,
      16: 10000,
      17: 0,
      18: 0,
      19: 10000,
      20: 0,
      21: 10000,
      22: 10000,
      23: 10000,
      24: 11000,
      25: 0,
      26: 10000,
      27: 10000,
      28: 12000,
      29: 0,
      30: 12000,
      31: 10000,
      32: 11000,
      33: 10000,
      34: 0,
      35: 0,
      36: 12000,
      37: 11244,
      38: 0,
      39: 0,
      40: 10000,
      41: 0,
      42: 10000,
      43: 0,
      44: 10000,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 15000,
      52: 10000,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel009",
      TOTAL: 285744,
    },
    {
      1: 13000,
      2: 0,
      3: 10500,
      4: 0,
      5: 10000,
      6: 10000,
      7: 0,
      8: 10000,
      9: 0,
      10: 10000,
      11: 10000,
      12: 0,
      13: 11000,
      14: 0,
      15: 10000,
      16: 10000,
      17: 0,
      18: 10000,
      19: 10000,
      20: 0,
      21: 12000,
      22: 0,
      23: 12000,
      24: 10000,
      25: 10000,
      26: 0,
      27: 11500,
      28: 0,
      29: 10000,
      30: 10500,
      31: 0,
      32: 12321,
      33: 0,
      34: 10000,
      35: 0,
      36: 10000,
      37: 10000,
      38: 0,
      39: 10000,
      40: 10000,
      41: 0,
      42: 10000,
      43: 0,
      44: 10000,
      45: 10000,
      46: 0,
      47: 10000,
      48: 10000,
      49: 0,
      50: 10000,
      51: 10000,
      52: 14500,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel005",
      TOTAL: 347321,
    },
    {
      1: 116250,
      2: 0,
      3: 116250,
      4: 0,
      5: 0,
      6: 86250,
      7: 0,
      8: 0,
      9: 0,
      10: 75000,
      11: 75000,
      12: 0,
      13: 75000,
      14: 0,
      15: 82500,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 82500,
      23: 101250,
      24: 0,
      25: 0,
      26: 0,
      27: 112500,
      28: 75000,
      29: 78750,
      30: 0,
      31: 127500,
      32: 86250,
      33: 75000,
      34: 142500,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 135716,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel001",
      TOTAL: 1643216,
    },
    {
      1: 13000,
      2: 10000,
      3: 12500,
      4: 0,
      5: 10500,
      6: 12000,
      7: 0,
      8: 11500,
      9: 0,
      10: 13000,
      11: 13500,
      12: 0,
      13: 11000,
      14: 10000,
      15: 10000,
      16: 12000,
      17: 0,
      18: 10000,
      19: 11500,
      20: 0,
      21: 14500,
      22: 12000,
      23: 12500,
      24: 12000,
      25: 10000,
      26: 10000,
      27: 10000,
      28: 10000,
      29: 10000,
      30: 13046.9999999999,
      31: 12000,
      32: 12000,
      33: 10500,
      34: 0,
      35: 10000,
      36: 12500,
      37: 12500,
      38: 10000,
      39: 0,
      40: 13500,
      41: 0,
      42: 12000,
      43: 10000,
      44: 10000,
      45: 10000,
      46: 0,
      47: 10000,
      48: 0,
      49: 10000,
      50: 0,
      51: 16500,
      52: 14500,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel008",
      TOTAL: 460546.9999999999,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel009",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel005",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 75226,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 75000,
      10: 0,
      11: 75000,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 75000,
      27: 78750,
      28: 0,
      29: 0,
      30: 75000,
      31: 0,
      32: 82500,
      33: 0,
      34: 75000,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel001",
      TOTAL: 611476,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 10000,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 10000,
      9: 0,
      10: 0,
      11: 12500,
      12: 0,
      13: 0,
      14: 16500,
      15: 22000,
      16: 0,
      17: 0,
      18: 10000,
      19: 10000,
      20: 13000,
      21: 15000,
      22: 14000,
      23: 13000,
      24: 15000,
      25: 10000,
      26: 12000,
      27: 11000,
      28: 12856,
      29: 17000,
      30: 15500,
      31: 20000,
      32: 19000,
      33: 0,
      34: 17000,
      35: 0,
      36: 13000,
      37: 11000,
      38: 0,
      39: 11500,
      40: 0,
      41: 0,
      42: 0,
      43: 10000,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 11500,
      49: 13000,
      50: 14500,
      51: 25000,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel005",
      TOTAL: 404856,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 90000,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 93750,
      28: 0,
      29: 0,
      30: 0,
      31: 90757,
      32: 0,
      33: 0,
      34: 75000,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 75000,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel001",
      TOTAL: 424507,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel004",
      TOTAL: 0,
    },
    {
      1: 12000,
      2: 0,
      3: 12000,
      4: 0,
      5: 12000,
      6: 0,
      7: 12000,
      8: 0,
      9: 12000,
      10: 0,
      11: 0,
      12: 12000,
      13: 0,
      14: 0,
      15: 12078,
      16: 0,
      17: 0,
      18: 12000,
      19: 0,
      20: 0,
      21: 12000,
      22: 0,
      23: 12000,
      24: 0,
      25: 12000,
      26: 0,
      27: 13800,
      28: 0,
      29: 0,
      30: 12000,
      31: 0,
      32: 14400,
      33: 0,
      34: 0,
      35: 13800,
      36: 0,
      37: 0,
      38: 12000,
      39: 0,
      40: 0,
      41: 12000,
      42: 0,
      43: 0,
      44: 12000,
      45: 0,
      46: 12000,
      47: 0,
      48: 12000,
      49: 0,
      50: 0,
      51: 12000,
      52: 12000,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel005",
      TOTAL: 270078,
    },
    {
      1: 75000,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 75000,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 90000,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 75000,
      28: 0,
      29: 0,
      30: 0,
      31: 75000,
      32: 0,
      33: 0,
      34: 75000,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 95214,
      52: 0,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel001",
      TOTAL: 560214,
    },
    {
      1: 25800,
      2: 0,
      3: 0,
      4: 15600,
      5: 0,
      6: 12000,
      7: 12000,
      8: 0,
      9: 16200,
      10: 0,
      11: 13200,
      12: 13800,
      13: 0,
      14: 17400,
      15: 0,
      16: 22200,
      17: 0,
      18: 12000,
      19: 12000,
      20: 12000,
      21: 14400,
      22: 13200,
      23: 12000,
      24: 12735,
      25: 14400,
      26: 15000,
      27: 16200,
      28: 14400,
      29: 15000,
      30: 18000,
      31: 15000,
      32: 16800,
      33: 20400,
      34: 12000,
      35: 18000,
      36: 12000,
      37: 12600,
      38: 14400,
      39: 0,
      40: 12000,
      41: 12000,
      42: 0,
      43: 12000,
      44: 14400,
      45: 0,
      46: 13200,
      47: 0,
      48: 12000,
      49: 12000,
      50: 12000,
      51: 12000,
      52: 25200,
      __EMPTY: "Run003",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel008",
      TOTAL: 587535,
    },
    {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      11: 11,
      12: 12,
      13: 13,
      14: 14,
      15: 15,
      16: 16,
      17: 17,
      18: 18,
      19: 19,
      20: 20,
      21: 21,
      22: 22,
      23: 23,
      24: 24,
      25: 25,
      26: 26,
      27: 27,
      28: 28,
      29: 29,
      30: 30,
      31: 31,
      32: 32,
      33: 33,
      34: 34,
      35: 35,
      36: 36,
      37: 37,
      38: 38,
      39: 39,
      40: 40,
      41: 41,
      42: 42,
      43: 43,
      44: 44,
      45: 45,
      46: 46,
      47: 47,
      48: 48,
      49: 49,
      50: 50,
      51: 51,
      52: 52,
    },
    {
      1: 12000,
      2: 10000,
      3: 12000,
      4: 0,
      5: 10000,
      6: 10000,
      7: 0,
      8: 10000,
      9: 0,
      10: 11500,
      11: 12000,
      12: 0,
      13: 10000,
      14: 10000,
      15: 10000,
      16: 10000,
      17: 0,
      18: 10000,
      19: 10000,
      20: 0,
      21: 13290,
      22: 11000,
      23: 12000,
      24: 14000,
      25: 10000,
      26: 10000,
      27: 10000,
      28: 10000,
      29: 10000,
      30: 12500,
      31: 11500,
      32: 10500,
      33: 10000,
      34: 0,
      35: 10000,
      36: 15000,
      37: 12000,
      38: 0,
      39: 10000,
      40: 10000,
      41: 0,
      42: 11000,
      43: 0,
      44: 10000,
      45: 10000,
      46: 0,
      47: 10000,
      48: 0,
      49: 0,
      50: 10000,
      51: 16000,
      52: 11000,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel009",
      TOTAL: 427290,
    },
    {
      1: 15000,
      2: 0,
      3: 12500,
      4: 0,
      5: 10000,
      6: 10000,
      7: 0,
      8: 11500,
      9: 0,
      10: 10000,
      11: 10000,
      12: 0,
      13: 13000,
      14: 0,
      15: 10000,
      16: 10000,
      17: 0,
      18: 10000,
      19: 10000,
      20: 0,
      21: 10000,
      22: 10000,
      23: 12000,
      24: 10000,
      25: 12000,
      26: 0,
      27: 13000,
      28: 0,
      29: 10000,
      30: 10000,
      31: 10000,
      32: 12000,
      33: 0,
      34: 11489.0000000001,
      35: 0,
      36: 10000,
      37: 11000,
      38: 0,
      39: 10000,
      40: 10000,
      41: 0,
      42: 12000,
      43: 0,
      44: 10000,
      45: 10000,
      46: 0,
      47: 10000,
      48: 10000,
      49: 0,
      50: 10000,
      51: 10000,
      52: 19500,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel005",
      TOTAL: 384989.0000000001,
    },
    {
      1: 120000,
      2: 75000,
      3: 78750,
      4: 75000,
      5: 0,
      6: 93750,
      7: 0,
      8: 79425,
      9: 0,
      10: 78750,
      11: 86250,
      12: 0,
      13: 97500,
      14: 0,
      15: 75000,
      16: 90000,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 82500,
      22: 75000,
      23: 112500,
      24: 75000,
      25: 0,
      26: 0,
      27: 135000,
      28: 78750,
      29: 105000,
      30: 0,
      31: 150000,
      32: 93750,
      33: 75000,
      34: 165000,
      35: 0,
      36: 0,
      37: 75000,
      38: 0,
      39: 0,
      40: 75000,
      41: 0,
      42: 0,
      43: 0,
      44: 75000,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 150000,
      52: 86250,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel001",
      TOTAL: 2558175,
    },
    {
      1: 16500,
      2: 10000,
      3: 14000,
      4: 10000,
      5: 10000,
      6: 12000,
      7: 10000,
      8: 12000,
      9: 10000,
      10: 14000,
      11: 15000,
      12: 0,
      13: 13500,
      14: 11500,
      15: 11000,
      16: 15000,
      17: 0,
      18: 10691,
      19: 12000,
      20: 10000,
      21: 15000,
      22: 13000,
      23: 14000,
      24: 15000,
      25: 10000,
      26: 10000,
      27: 12000,
      28: 12000,
      29: 11500,
      30: 15000,
      31: 14000,
      32: 13500,
      33: 13000,
      34: 0,
      35: 11500,
      36: 14500,
      37: 13500,
      38: 10000,
      39: 10000,
      40: 10000,
      41: 10000,
      42: 10000,
      43: 10000,
      44: 10000,
      45: 11000,
      46: 10000,
      47: 12000,
      48: 0,
      49: 10000,
      50: 10000,
      51: 17000,
      52: 18000,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand001",
      __EMPTY_2: "Channel008",
      TOTAL: 582691,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel009",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel005",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 75000,
      4: 75000,
      5: 0,
      6: 0,
      7: 0,
      8: 75000,
      9: 75000,
      10: 0,
      11: 97500,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 86250,
      27: 87851.0000000002,
      28: 0,
      29: 75000,
      30: 75000,
      31: 0,
      32: 75000,
      33: 75000,
      34: 75000,
      35: 0,
      36: 0,
      37: 0,
      38: 75000,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 78750,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel001",
      TOTAL: 1100351.0000000002,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand002",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 12000,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 11228.0000000001,
      7: 0,
      8: 10000,
      9: 0,
      10: 10000,
      11: 10500,
      12: 0,
      13: 10000,
      14: 17000,
      15: 24500,
      16: 0,
      17: 0,
      18: 13000,
      19: 12000,
      20: 15500,
      21: 16000,
      22: 16000,
      23: 15500,
      24: 15500,
      25: 12500,
      26: 14000,
      27: 13000,
      28: 15000,
      29: 18000,
      30: 18000,
      31: 20500,
      32: 22000,
      33: 10000,
      34: 15500,
      35: 10000,
      36: 11500,
      37: 13500,
      38: 0,
      39: 10000,
      40: 10000,
      41: 0,
      42: 10000,
      43: 12000,
      44: 0,
      45: 0,
      46: 0,
      47: 10000,
      48: 12000,
      49: 14000,
      50: 15000,
      51: 27000,
      52: 10000,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel005",
      TOTAL: 532228.0000000001,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 75000,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 112500,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 75000,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 112500,
      28: 0,
      29: 0,
      30: 0,
      31: 101250,
      32: 0,
      33: 0,
      34: 90000,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 106570,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel001",
      TOTAL: 672820,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 0,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel008",
      TOTAL: 0,
    },
    {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 10500,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 14092,
      30: 12500,
      31: 23000,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 10000,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand003",
      __EMPTY_2: "Channel004",
      TOTAL: 70092,
    },
    {
      1: 12000,
      2: 0,
      3: 12000,
      4: 0,
      5: 12000,
      6: 0,
      7: 12000,
      8: 0,
      9: 12000,
      10: 0,
      11: 0,
      12: 12600,
      13: 0,
      14: 0,
      15: 14400,
      16: 0,
      17: 0,
      18: 13200,
      19: 0,
      20: 0,
      21: 12000,
      22: 0,
      23: 12000,
      24: 0,
      25: 12000,
      26: 0,
      27: 14400,
      28: 0,
      29: 12000,
      30: 12000,
      31: 0,
      32: 15600,
      33: 0,
      34: 0,
      35: 16200,
      36: 0,
      37: 0,
      38: 12600,
      39: 0,
      40: 0,
      41: 12000,
      42: 0,
      43: 0,
      44: 12000,
      45: 0,
      46: 12000,
      47: 0,
      48: 12000,
      49: 0,
      50: 0,
      51: 12000,
      52: 16050.999999999998,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel005",
      TOTAL: 295051,
    },
    {
      1: 82500,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 75000,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 82500,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 75000,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 75000,
      28: 0,
      29: 0,
      30: 0,
      31: 75000,
      32: 0,
      33: 0,
      34: 78750,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
      41: 0,
      42: 0,
      43: 0,
      44: 0,
      45: 0,
      46: 0,
      47: 0,
      48: 0,
      49: 0,
      50: 0,
      51: 122186,
      52: 0,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel001",
      TOTAL: 665936,
    },
    {
      1: 27000,
      2: 0,
      3: 12000,
      4: 14400,
      5: 0,
      6: 13800,
      7: 14400,
      8: 0,
      9: 19200,
      10: 0,
      11: 14400,
      12: 14400,
      13: 12000,
      14: 12000,
      15: 12000,
      16: 20400,
      17: 0,
      18: 13200,
      19: 12000,
      20: 13800,
      21: 16200,
      22: 15000,
      23: 13200,
      24: 14400,
      25: 16200,
      26: 16200,
      27: 18000,
      28: 16200,
      29: 16800,
      30: 19200,
      31: 17159,
      32: 19200,
      33: 18600,
      34: 12600,
      35: 15600,
      36: 15000,
      37: 15000,
      38: 17400,
      39: 0,
      40: 13200,
      41: 13800,
      42: 0,
      43: 12600,
      44: 15600,
      45: 12000,
      46: 15600,
      47: 0,
      48: 12000,
      49: 12000,
      50: 12000,
      51: 14400,
      52: 30000,
      __EMPTY: "Run004",
      market: "Market001",
      __EMPTY_1: "Brand004",
      __EMPTY_2: "Channel008",
      TOTAL: 680159,
    },
  ],
  DiminishingReturns: [
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.05403,
      "incrementalCarConfigFinished": 104.7129
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.10806,
      "incrementalCarConfigFinished": 188.3729
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.16209,
      "incrementalCarConfigFinished": 257.068
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.21612,
      "incrementalCarConfigFinished": 316.2553
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.27015,
      "incrementalCarConfigFinished": 368.7543
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.32418,
      "incrementalCarConfigFinished": 416.2590
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.37821,
      "incrementalCarConfigFinished": 459.9168
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.43224,
      "incrementalCarConfigFinished": 500.5424
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.48627,
      "incrementalCarConfigFinished": 538.738
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.5403,
      "incrementalCarConfigFinished": 574.9383
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.59433,
      "incrementalCarConfigFinished": 609.4222
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.64836,
      "incrementalCarConfigFinished": 642.3320
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.7024,
      "incrementalCarConfigFinished": 673.6971
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.75643,
      "incrementalCarConfigFinished": 703.480
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.81046,
      "incrementalCarConfigFinished": 731.642
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.86449,
      "incrementalCarConfigFinished": 758.1
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.91852,
      "incrementalCarConfigFinished": 783.1120
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 0.97255,
      "incrementalCarConfigFinished": 806.557
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.02658,
      "incrementalCarConfigFinished": 828.623
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.08061,
      "incrementalCarConfigFinished": 849.437
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.13464,
      "incrementalCarConfigFinished": 869.1221
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.18867,
      "incrementalCarConfigFinished": 887.788
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.2427,
      "incrementalCarConfigFinished": 905.5367
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.29673,
      "incrementalCarConfigFinished": 922.463
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.35076,
      "incrementalCarConfigFinished": 938.641
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.40479,
      "incrementalCarConfigFinished": 954.1465
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.45882,
      "incrementalCarConfigFinished": 969.03
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.51285,
      "incrementalCarConfigFinished": 983.3584
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.56688,
      "incrementalCarConfigFinished": 997.164
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.62091,
      "incrementalCarConfigFinished": 1010.496
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.67494,
      "incrementalCarConfigFinished": 1023.390
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.72897,
      "incrementalCarConfigFinished": 1035.877
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.783,
      "incrementalCarConfigFinished": 1047.984
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.83703,
      "incrementalCarConfigFinished": 1059.740
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.89106,
      "incrementalCarConfigFinished": 1071.161
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.94509,
      "incrementalCarConfigFinished": 1082.275
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 1.99912,
      "incrementalCarConfigFinished": 1093.096
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.05316,
      "incrementalCarConfigFinished": 1103.644
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.10719,
      "incrementalCarConfigFinished": 1113.934
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.16122,
      "incrementalCarConfigFinished": 1123.978
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.21525,
      "incrementalCarConfigFinished": 1133.790
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.26928,
      "incrementalCarConfigFinished": 1143.385
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.32331,
      "incrementalCarConfigFinished": 1152.769
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.37734,
      "incrementalCarConfigFinished": 1161.956
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.43137,
      "incrementalCarConfigFinished": 1170.953
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.4854,
      "incrementalCarConfigFinished": 1179.76
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.53943,
      "incrementalCarConfigFinished": 1188.417
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.59346,
      "incrementalCarConfigFinished": 1196.897
   
    },
    {
      "channelName": "mdsp_promodel20cam73",
      "analysisDimension": "Channel",
      "analysisValue": "Channel003",
      "normalizedSpend": 2.64749,
      "incrementalCarConfigFinished": 1205.218
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.04113,
      "incrementalCarConfigFinished": 0.50
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.08226,
      "incrementalCarConfigFinished": 0.9690
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.12339,
      "incrementalCarConfigFinished": 1.4122
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.16452,
      "incrementalCarConfigFinished": 1.8388
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.20565,
      "incrementalCarConfigFinished": 2.2489
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.24678,
      "incrementalCarConfigFinished": 2.6405
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.28791,
      "incrementalCarConfigFinished": 3.0193
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.32904,
      "incrementalCarConfigFinished": 3.3853
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.37017,
      "incrementalCarConfigFinished": 3.7420
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.4113,
      "incrementalCarConfigFinished": 4.0859
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.45243,
      "incrementalCarConfigFinished": 4.4242
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.49356,
      "incrementalCarConfigFinished": 4.7497
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.53469,
      "incrementalCarConfigFinished": 5.0696
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.57583,
      "incrementalCarConfigFinished": 5.3804
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.61696,
      "incrementalCarConfigFinished": 5.6838
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.65809,
      "incrementalCarConfigFinished": 5.9799
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.69922,
      "incrementalCarConfigFinished": 6.2704
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.74035,
      "incrementalCarConfigFinished": 6.5536
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.78148,
      "incrementalCarConfigFinished": 6.8294
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.82261,
      "incrementalCarConfigFinished": 7.1016
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.86374,
      "incrementalCarConfigFinished": 7.3664
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.90487,
      "incrementalCarConfigFinished": 7.6275
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.946,
      "incrementalCarConfigFinished": 7.8812
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 0.98713,
      "incrementalCarConfigFinished": 8.1295
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.02826,
      "incrementalCarConfigFinished": 8.3740
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.06939,
      "incrementalCarConfigFinished": 8.6131
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.11052,
      "incrementalCarConfigFinished": 8.8485
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.15165,
      "incrementalCarConfigFinished": 9.0802
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.19278,
      "incrementalCarConfigFinished": 9.3063
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.23391,
      "incrementalCarConfigFinished": 9.5288
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.27504,
      "incrementalCarConfigFinished": 9.7477
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.31617,
      "incrementalCarConfigFinished": 9.9610
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.3573,
      "incrementalCarConfigFinished": 10.1724
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.39843,
      "incrementalCarConfigFinished": 10.3802
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.43956,
      "incrementalCarConfigFinished": 10.5843
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.48069,
      "incrementalCarConfigFinished": 10.7866
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.52182,
      "incrementalCarConfigFinished": 10.9815
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.56295,
      "incrementalCarConfigFinished": 11.1783
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.60408,
      "incrementalCarConfigFinished": 11.3695
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.64521,
      "incrementalCarConfigFinished": 11.5589
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.68635,
      "incrementalCarConfigFinished": 11.7428
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.72748,
      "incrementalCarConfigFinished": 11.9267
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.76861,
      "incrementalCarConfigFinished": 12.1087
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.80974,
      "incrementalCarConfigFinished": 12.2853
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.85087,
      "incrementalCarConfigFinished": 12.4581
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.892,
      "incrementalCarConfigFinished": 12.6310
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.93313,
      "incrementalCarConfigFinished": 12.8020
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 1.97426,
      "incrementalCarConfigFinished": 12.971
   
    },
    {
      "channelName": "mdsp_promodel20cam74",
      "analysisDimension": "Channel",
      "analysisValue": "Channel004",
      "normalizedSpend": 2.01539,
      "incrementalCarConfigFinished": 13.136
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.02858,
      "incrementalCarConfigFinished": 0.3971
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.05716,
      "incrementalCarConfigFinished": 0.7851
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.08574,
      "incrementalCarConfigFinished": 1.1566
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.11432,
      "incrementalCarConfigFinished": 1.5188
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.1429,
      "incrementalCarConfigFinished": 1.8645
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.17148,
      "incrementalCarConfigFinished": 2.2029
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.20006,
      "incrementalCarConfigFinished": 2.5320
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.22864,
      "incrementalCarConfigFinished": 2.8483
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.25722,
      "incrementalCarConfigFinished": 3.1517
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.2858,
      "incrementalCarConfigFinished": 3.4496
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.31438,
      "incrementalCarConfigFinished": 3.7402
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.34295,
      "incrementalCarConfigFinished": 4.0215
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.37153,
      "incrementalCarConfigFinished": 4.293
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.40011,
      "incrementalCarConfigFinished": 4.5584
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.42869,
      "incrementalCarConfigFinished": 4.8196
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.45727,
      "incrementalCarConfigFinished": 5.0715
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.48585,
      "incrementalCarConfigFinished": 5.3179
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.51443,
      "incrementalCarConfigFinished": 5.5569
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.54301,
      "incrementalCarConfigFinished": 5.7923
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.57159,
      "incrementalCarConfigFinished": 6.0222
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.60017,
      "incrementalCarConfigFinished": 6.2483
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.62875,
      "incrementalCarConfigFinished": 6.4690
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.65733,
      "incrementalCarConfigFinished": 6.6860
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.68591,
      "incrementalCarConfigFinished": 6.8956
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.71449,
      "incrementalCarConfigFinished": 7.1034
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.74307,
      "incrementalCarConfigFinished": 7.3075
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.77165,
      "incrementalCarConfigFinished": 7.50
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.80023,
      "incrementalCarConfigFinished": 7.7065
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.82881,
      "incrementalCarConfigFinished": 7.8978
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.85739,
      "incrementalCarConfigFinished": 8.085
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.88597,
      "incrementalCarConfigFinished": 8.2729
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.91455,
      "incrementalCarConfigFinished": 8.4568
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.94313,
      "incrementalCarConfigFinished": 8.6370
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 0.9717,
      "incrementalCarConfigFinished": 8.8154
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.00028,
      "incrementalCarConfigFinished": 8.9901
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.02886,
      "incrementalCarConfigFinished": 9.1629
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.05744,
      "incrementalCarConfigFinished": 9.3321
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.08602,
      "incrementalCarConfigFinished": 9.4976
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.1146,
      "incrementalCarConfigFinished": 9.6631
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.14318,
      "incrementalCarConfigFinished": 9.8267
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.17176,
      "incrementalCarConfigFinished": 9.9867
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.20034,
      "incrementalCarConfigFinished": 10.144
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.22892,
      "incrementalCarConfigFinished": 10.301
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.2575,
      "incrementalCarConfigFinished": 10.4556
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.28608,
      "incrementalCarConfigFinished": 10.6082
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.31466,
      "incrementalCarConfigFinished": 10.755
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.34324,
      "incrementalCarConfigFinished": 10.9043
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.37182,
      "incrementalCarConfigFinished": 11.0514
   
    },
    {
      "channelName": "mdsp_promodel20cam77",
      "analysisDimension": "Channel",
      "analysisValue": "Channel005",
      "normalizedSpend": 1.4004,
      "incrementalCarConfigFinished": 11.1967
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.09515,
      "incrementalCarConfigFinished": 11.4780
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.19031,
      "incrementalCarConfigFinished": 22.3437
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.28546,
      "incrementalCarConfigFinished": 32.692
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.38061,
      "incrementalCarConfigFinished": 42.582
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.47576,
      "incrementalCarConfigFinished": 52.0558
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.57092,
      "incrementalCarConfigFinished": 61.1415
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.66607,
      "incrementalCarConfigFinished": 69.8668
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.76122,
      "incrementalCarConfigFinished": 78.2611
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.85638,
      "incrementalCarConfigFinished": 86.3465
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 0.95153,
      "incrementalCarConfigFinished": 94.1469
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.04668,
      "incrementalCarConfigFinished": 101.6880
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.14183,
      "incrementalCarConfigFinished": 108.9864
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.23699,
      "incrementalCarConfigFinished": 116.0622
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.33214,
      "incrementalCarConfigFinished": 122.9285
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.42729,
      "incrementalCarConfigFinished": 129.601
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.52245,
      "incrementalCarConfigFinished": 136.0983
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.6176,
      "incrementalCarConfigFinished": 142.4239
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.71275,
      "incrementalCarConfigFinished": 148.5859
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.8079,
      "incrementalCarConfigFinished": 154.6007
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.90306,
      "incrementalCarConfigFinished": 160.4722
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 1.99821,
      "incrementalCarConfigFinished": 166.2075
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.09336,
      "incrementalCarConfigFinished": 171.8105
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.18852,
      "incrementalCarConfigFinished": 177.2902
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.28367,
      "incrementalCarConfigFinished": 182.6486
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.37882,
      "incrementalCarConfigFinished": 187.8930
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.47397,
      "incrementalCarConfigFinished": 193.0252
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.56913,
      "incrementalCarConfigFinished": 198.0471
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.66428,
      "incrementalCarConfigFinished": 202.9623
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.75943,
      "incrementalCarConfigFinished": 207.7819
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.85459,
      "incrementalCarConfigFinished": 212.4986
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 2.94974,
      "incrementalCarConfigFinished": 217.1196
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.04489,
      "incrementalCarConfigFinished": 221.648
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.14004,
      "incrementalCarConfigFinished": 226.0858
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.2352,
      "incrementalCarConfigFinished": 230.434
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.33035,
      "incrementalCarConfigFinished": 234.7026
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.4255,
      "incrementalCarConfigFinished": 238.8841
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.52066,
      "incrementalCarConfigFinished": 242.9866
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.61581,
      "incrementalCarConfigFinished": 247.0100
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.71096,
      "incrementalCarConfigFinished": 250.9598
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.80611,
      "incrementalCarConfigFinished": 254.8324
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.90127,
      "incrementalCarConfigFinished": 258.635
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 3.99642,
      "incrementalCarConfigFinished": 262.369
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.09157,
      "incrementalCarConfigFinished": 266.034
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.18673,
      "incrementalCarConfigFinished": 269.63
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.28188,
      "incrementalCarConfigFinished": 273.173
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.37703,
      "incrementalCarConfigFinished": 276.6466
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.47218,
      "incrementalCarConfigFinished": 280.061
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.56734,
      "incrementalCarConfigFinished": 283.4191
   
    },
    {
      "channelName": "mdsp_promodel1cam78",
      "analysisDimension": "Channel",
      "analysisValue": "Channel006",
      "normalizedSpend": 4.66249,
      "incrementalCarConfigFinished": 286.7180
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.03227,
      "incrementalCarConfigFinished": 1.5133
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.06453,
      "incrementalCarConfigFinished": 2.9403
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.0968,
      "incrementalCarConfigFinished": 4.3028
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.12906,
      "incrementalCarConfigFinished": 5.6139
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.16133,
      "incrementalCarConfigFinished": 6.8809
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.19359,
      "incrementalCarConfigFinished": 8.109
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.22586,
      "incrementalCarConfigFinished": 9.3045
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.25812,
      "incrementalCarConfigFinished": 10.4685
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.29039,
      "incrementalCarConfigFinished": 11.5994
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.32265,
      "incrementalCarConfigFinished": 12.7027
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.35492,
      "incrementalCarConfigFinished": 13.7766
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.38718,
      "incrementalCarConfigFinished": 14.8192
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.41945,
      "incrementalCarConfigFinished": 15.8324
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.45171,
      "incrementalCarConfigFinished": 16.8180
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.48398,
      "incrementalCarConfigFinished": 17.776
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.51624,
      "incrementalCarConfigFinished": 18.7139
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.54851,
      "incrementalCarConfigFinished": 19.6241
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.58077,
      "incrementalCarConfigFinished": 20.5141
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.61304,
      "incrementalCarConfigFinished": 21.3839
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.6453,
      "incrementalCarConfigFinished": 22.235
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.67757,
      "incrementalCarConfigFinished": 23.0664
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.70983,
      "incrementalCarConfigFinished": 23.882
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.7421,
      "incrementalCarConfigFinished": 24.682
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.77436,
      "incrementalCarConfigFinished": 25.4698
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.80663,
      "incrementalCarConfigFinished": 26.240
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.83889,
      "incrementalCarConfigFinished": 26.9960
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.87116,
      "incrementalCarConfigFinished": 27.7426
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.90342,
      "incrementalCarConfigFinished": 28.474
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.93569,
      "incrementalCarConfigFinished": 29.1934
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 0.96795,
      "incrementalCarConfigFinished": 29.9051
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.00022,
      "incrementalCarConfigFinished": 30.6038
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.03248,
      "incrementalCarConfigFinished": 31.291
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.06475,
      "incrementalCarConfigFinished": 31.9701
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.09701,
      "incrementalCarConfigFinished": 32.635
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.12928,
      "incrementalCarConfigFinished": 33.2959
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.16154,
      "incrementalCarConfigFinished": 33.9450
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.19381,
      "incrementalCarConfigFinished": 34.5831
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.22607,
      "incrementalCarConfigFinished": 35.2156
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.25834,
      "incrementalCarConfigFinished": 35.8372
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.2906,
      "incrementalCarConfigFinished": 36.451
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.32287,
      "incrementalCarConfigFinished": 37.0582
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.35513,
      "incrementalCarConfigFinished": 37.65
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.3874,
      "incrementalCarConfigFinished": 38.2442
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.41966,
      "incrementalCarConfigFinished": 38.8253
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.45193,
      "incrementalCarConfigFinished": 39.4027
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.4842,
      "incrementalCarConfigFinished": 39.9691
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.51646,
      "incrementalCarConfigFinished": 40.5281
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.54873,
      "incrementalCarConfigFinished": 41.0816
   
    },
    {
      "channelName": "mdsp_socmodel20cam92",
      "analysisDimension": "Channel",
      "analysisValue": "Channel008",
      "normalizedSpend": 1.58099,
      "incrementalCarConfigFinished": 41.625
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.03492,
      "incrementalCarConfigFinished": 12.3239
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.06984,
      "incrementalCarConfigFinished": 24.6423
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.10475,
      "incrementalCarConfigFinished": 36.9736
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.13967,
      "incrementalCarConfigFinished": 49.2938
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.17459,
      "incrementalCarConfigFinished": 61.5755
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.20951,
      "incrementalCarConfigFinished": 73.7799
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.24443,
      "incrementalCarConfigFinished": 85.8611
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.27934,
      "incrementalCarConfigFinished": 97.7841
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.31426,
      "incrementalCarConfigFinished": 109.5141
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.34918,
      "incrementalCarConfigFinished": 121.0308
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.3841,
      "incrementalCarConfigFinished": 132.3066
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.41902,
      "incrementalCarConfigFinished": 143.3286
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.45393,
      "incrementalCarConfigFinished": 154.076
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.48885,
      "incrementalCarConfigFinished": 164.5231
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.52377,
      "incrementalCarConfigFinished": 174.6496
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.55869,
      "incrementalCarConfigFinished": 184.4341
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.59361,
      "incrementalCarConfigFinished": 193.8711
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.62852,
      "incrementalCarConfigFinished": 202.958
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.66344,
      "incrementalCarConfigFinished": 211.702
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.69836,
      "incrementalCarConfigFinished": 220.1059
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.73328,
      "incrementalCarConfigFinished": 228.1765
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.7682,
      "incrementalCarConfigFinished": 235.9162
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.80311,
      "incrementalCarConfigFinished": 243.3433
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.83803,
      "incrementalCarConfigFinished": 250.4633
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.87295,
      "incrementalCarConfigFinished": 257.2836
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.90787,
      "incrementalCarConfigFinished": 263.8152
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.94279,
      "incrementalCarConfigFinished": 270.0764
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 0.9777,
      "incrementalCarConfigFinished": 276.071
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.01262,
      "incrementalCarConfigFinished": 281.8138
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.04754,
      "incrementalCarConfigFinished": 287.32
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.08246,
      "incrementalCarConfigFinished": 292.6096
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.11737,
      "incrementalCarConfigFinished": 297.6848
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.15229,
      "incrementalCarConfigFinished": 302.5578
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.18721,
      "incrementalCarConfigFinished": 307.2395
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.22213,
      "incrementalCarConfigFinished": 311.7465
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.25705,
      "incrementalCarConfigFinished": 316.0843
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.29196,
      "incrementalCarConfigFinished": 320.2640
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.32688,
      "incrementalCarConfigFinished": 324.294
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.3618,
      "incrementalCarConfigFinished": 328.1839
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.39672,
      "incrementalCarConfigFinished": 331.9388
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.43164,
      "incrementalCarConfigFinished": 335.566
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.46655,
      "incrementalCarConfigFinished": 339.075
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.50147,
      "incrementalCarConfigFinished": 342.4717
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.53639,
      "incrementalCarConfigFinished": 345.7614
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.57131,
      "incrementalCarConfigFinished": 348.9481
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.60623,
      "incrementalCarConfigFinished": 352.037
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.64114,
      "incrementalCarConfigFinished": 355.036
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.67606,
      "incrementalCarConfigFinished": 357.9474
   
    },
    {
      "channelName": "mdsp_socmodel20cam93",
      "analysisDimension": "Channel",
      "analysisValue": "Channel009",
      "normalizedSpend": 1.71098,
      "incrementalCarConfigFinished": 360.775
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 0.30826,
      "incrementalCarConfigFinished": 67.8845
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 0.61651,
      "incrementalCarConfigFinished": 93.5695
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 0.92477,
      "incrementalCarConfigFinished": 114.0671
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 1.23302,
      "incrementalCarConfigFinished": 131.3651
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 1.54128,
      "incrementalCarConfigFinished": 145.9894
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 1.84953,
      "incrementalCarConfigFinished": 158.521
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 2.15779,
      "incrementalCarConfigFinished": 169.4439
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 2.46604,
      "incrementalCarConfigFinished": 179.0960
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 2.7743,
      "incrementalCarConfigFinished": 187.7073
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 3.08255,
      "incrementalCarConfigFinished": 195.4396
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 3.39081,
      "incrementalCarConfigFinished": 202.4180
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 3.69907,
      "incrementalCarConfigFinished": 208.7400
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 4.00732,
      "incrementalCarConfigFinished": 214.4919
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 4.31558,
      "incrementalCarConfigFinished": 219.7473
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 4.62383,
      "incrementalCarConfigFinished": 224.5724
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 4.93209,
      "incrementalCarConfigFinished": 229.0169
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 5.24034,
      "incrementalCarConfigFinished": 233.1322
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 5.5486,
      "incrementalCarConfigFinished": 236.9589
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 5.85685,
      "incrementalCarConfigFinished": 240.5207
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 6.16511,
      "incrementalCarConfigFinished": 243.8527
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 6.47337,
      "incrementalCarConfigFinished": 246.9824
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 6.78162,
      "incrementalCarConfigFinished": 249.924
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 7.08988,
      "incrementalCarConfigFinished": 252.6957
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 7.39813,
      "incrementalCarConfigFinished": 255.3124
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 7.70639,
      "incrementalCarConfigFinished": 257.787
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 8.01464,
      "incrementalCarConfigFinished": 260.1375
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 8.3229,
      "incrementalCarConfigFinished": 262.3625
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 8.63115,
      "incrementalCarConfigFinished": 264.480
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 8.93941,
      "incrementalCarConfigFinished": 266.498
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 9.24766,
      "incrementalCarConfigFinished": 268.4196
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 9.55592,
      "incrementalCarConfigFinished": 270.2548
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 9.86418,
      "incrementalCarConfigFinished": 272.0072
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 10.17243,
      "incrementalCarConfigFinished": 273.68
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 10.48069,
      "incrementalCarConfigFinished": 275.2951
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 10.78894,
      "incrementalCarConfigFinished": 276.8397
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 11.0972,
      "incrementalCarConfigFinished": 278.3218
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 11.40545,
      "incrementalCarConfigFinished": 279.7451
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 11.71371,
      "incrementalCarConfigFinished": 281.116
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 12.02196,
      "incrementalCarConfigFinished": 282.4353
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 12.33022,
      "incrementalCarConfigFinished": 283.709
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 12.63847,
      "incrementalCarConfigFinished": 284.9380
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 12.94673,
      "incrementalCarConfigFinished": 286.1259
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 13.25499,
      "incrementalCarConfigFinished": 287.2733
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 13.56324,
      "incrementalCarConfigFinished": 288.3803
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 13.8715,
      "incrementalCarConfigFinished": 289.452
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 14.17975,
      "incrementalCarConfigFinished": 290.4913
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 14.48801,
      "incrementalCarConfigFinished": 291.497
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 14.79626,
      "incrementalCarConfigFinished": 292.4699
   
    },
    {
      "channelName": "mdsp_socmodel1cam94",
      "analysisDimension": "Channel",
      "analysisValue": "Channel010",
      "normalizedSpend": 15.10452,
      "incrementalCarConfigFinished": 293.4132
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.10378,
      "incrementalCarConfigFinished": 23.805
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.20756,
      "incrementalCarConfigFinished": 45.2216
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.31134,
      "incrementalCarConfigFinished": 64.6699
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.41513,
      "incrementalCarConfigFinished": 82.5364
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.51891,
      "incrementalCarConfigFinished": 99.136
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.62269,
      "incrementalCarConfigFinished": 114.6499
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.72647,
      "incrementalCarConfigFinished": 129.1828
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.83025,
      "incrementalCarConfigFinished": 142.8303
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 0.93403,
      "incrementalCarConfigFinished": 155.6797
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.03782,
      "incrementalCarConfigFinished": 167.8143
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.1416,
      "incrementalCarConfigFinished": 179.3110
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.24538,
      "incrementalCarConfigFinished": 190.2372
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.34916,
      "incrementalCarConfigFinished": 200.6513
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.45294,
      "incrementalCarConfigFinished": 210.6023
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.55672,
      "incrementalCarConfigFinished": 220.1293
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.66051,
      "incrementalCarConfigFinished": 229.2645
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.76429,
      "incrementalCarConfigFinished": 238.0383
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.86807,
      "incrementalCarConfigFinished": 246.4715
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 1.97185,
      "incrementalCarConfigFinished": 254.5890
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.07563,
      "incrementalCarConfigFinished": 262.406
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.17941,
      "incrementalCarConfigFinished": 269.9418
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.2832,
      "incrementalCarConfigFinished": 277.2107
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.38698,
      "incrementalCarConfigFinished": 284.22
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.49076,
      "incrementalCarConfigFinished": 291.0056
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.59454,
      "incrementalCarConfigFinished": 297.5549
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.69832,
      "incrementalCarConfigFinished": 303.8878
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.8021,
      "incrementalCarConfigFinished": 310.0132
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 2.90588,
      "incrementalCarConfigFinished": 315.9423
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.00967,
      "incrementalCarConfigFinished": 321.6798
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.11345,
      "incrementalCarConfigFinished": 327.2387
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.21723,
      "incrementalCarConfigFinished": 332.6212
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.32101,
      "incrementalCarConfigFinished": 337.8371
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.42479,
      "incrementalCarConfigFinished": 342.8935
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.52857,
      "incrementalCarConfigFinished": 347.7937
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.63236,
      "incrementalCarConfigFinished": 352.5464
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.73614,
      "incrementalCarConfigFinished": 357.1580
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.83992,
      "incrementalCarConfigFinished": 361.6303
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 3.9437,
      "incrementalCarConfigFinished": 365.9727
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.04748,
      "incrementalCarConfigFinished": 370.1877
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.15126,
      "incrementalCarConfigFinished": 374.2817
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.25505,
      "incrementalCarConfigFinished": 378.25
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.35883,
      "incrementalCarConfigFinished": 382.1228
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.46261,
      "incrementalCarConfigFinished": 385.8802
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.56639,
      "incrementalCarConfigFinished": 389.5327
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.67017,
      "incrementalCarConfigFinished": 393.0874
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.77395,
      "incrementalCarConfigFinished": 396.5436
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.87773,
      "incrementalCarConfigFinished": 399.9075
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 4.98152,
      "incrementalCarConfigFinished": 403.1834
   
    },
    {
      "channelName": "mdsp_socmodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel016",
      "normalizedSpend": 5.0853,
      "incrementalCarConfigFinished": 406.3735
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.08614,
      "incrementalCarConfigFinished": 0.7572
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.17228,
      "incrementalCarConfigFinished": 1.4760
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.25842,
      "incrementalCarConfigFinished": 2.1499
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.34455,
      "incrementalCarConfigFinished": 2.7790
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.43069,
      "incrementalCarConfigFinished": 3.365
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.51683,
      "incrementalCarConfigFinished": 3.9177
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.60297,
      "incrementalCarConfigFinished": 4.4377
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.68911,
      "incrementalCarConfigFinished": 4.932
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.77525,
      "incrementalCarConfigFinished": 5.4017
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.86138,
      "incrementalCarConfigFinished": 5.8481
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 0.94752,
      "incrementalCarConfigFinished": 6.2728
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.03366,
      "incrementalCarConfigFinished": 6.6799
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.1198,
      "incrementalCarConfigFinished": 7.0685
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.20594,
      "incrementalCarConfigFinished": 7.4427
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.29208,
      "incrementalCarConfigFinished": 7.8009
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.37821,
      "incrementalCarConfigFinished": 8.1455
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.46435,
      "incrementalCarConfigFinished": 8.477
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.55049,
      "incrementalCarConfigFinished": 8.7970
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.63663,
      "incrementalCarConfigFinished": 9.1039
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.72277,
      "incrementalCarConfigFinished": 9.4028
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.80891,
      "incrementalCarConfigFinished": 9.6913
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.89505,
      "incrementalCarConfigFinished": 9.9685
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 1.98118,
      "incrementalCarConfigFinished": 10.2394
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.06732,
      "incrementalCarConfigFinished": 10.5014
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.15346,
      "incrementalCarConfigFinished": 10.7562
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.2396,
      "incrementalCarConfigFinished": 11.002
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.32574,
      "incrementalCarConfigFinished": 11.243
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.41188,
      "incrementalCarConfigFinished": 11.4766
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.49801,
      "incrementalCarConfigFinished": 11.7042
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.58415,
      "incrementalCarConfigFinished": 11.9262
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.67029,
      "incrementalCarConfigFinished": 12.141
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.75643,
      "incrementalCarConfigFinished": 12.3533
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.84257,
      "incrementalCarConfigFinished": 12.5584
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 2.92871,
      "incrementalCarConfigFinished": 12.7588
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.01484,
      "incrementalCarConfigFinished": 12.9551
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.10098,
      "incrementalCarConfigFinished": 13.1466
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.18712,
      "incrementalCarConfigFinished": 13.3349
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.27326,
      "incrementalCarConfigFinished": 13.5176
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.3594,
      "incrementalCarConfigFinished": 13.6963
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.44554,
      "incrementalCarConfigFinished": 13.8726
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.53168,
      "incrementalCarConfigFinished": 14.0441
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.61781,
      "incrementalCarConfigFinished": 14.2124
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.70395,
      "incrementalCarConfigFinished": 14.3783
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.79009,
      "incrementalCarConfigFinished": 14.5401
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.87623,
      "incrementalCarConfigFinished": 14.6980
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 3.96237,
      "incrementalCarConfigFinished": 14.8543
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 4.04851,
      "incrementalCarConfigFinished": 15.0065
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 4.13464,
      "incrementalCarConfigFinished": 15.1564
   
    },
    {
      "channelName": "mdsp_socmodel11&18cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel017",
      "normalizedSpend": 4.22078,
      "incrementalCarConfigFinished": 15.3038
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.04079,
      "incrementalCarConfigFinished": 5.7631
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.08159,
      "incrementalCarConfigFinished": 11.2763
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.12238,
      "incrementalCarConfigFinished": 16.5811
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.16318,
      "incrementalCarConfigFinished": 21.704
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.20397,
      "incrementalCarConfigFinished": 26.6699
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.24477,
      "incrementalCarConfigFinished": 31.4907
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.28556,
      "incrementalCarConfigFinished": 36.1793
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.32636,
      "incrementalCarConfigFinished": 40.7461
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.36715,
      "incrementalCarConfigFinished": 45.2007
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.40794,
      "incrementalCarConfigFinished": 49.5448
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.44874,
      "incrementalCarConfigFinished": 53.7854
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.48953,
      "incrementalCarConfigFinished": 57.9259
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.53033,
      "incrementalCarConfigFinished": 61.9678
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.57112,
      "incrementalCarConfigFinished": 65.9176
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.61192,
      "incrementalCarConfigFinished": 69.7752
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.65271,
      "incrementalCarConfigFinished": 73.5463
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.69351,
      "incrementalCarConfigFinished": 77.2324
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.7343,
      "incrementalCarConfigFinished": 80.8392
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.7751,
      "incrementalCarConfigFinished": 84.3659
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.81589,
      "incrementalCarConfigFinished": 87.8188
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.85668,
      "incrementalCarConfigFinished": 91.198
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.89748,
      "incrementalCarConfigFinished": 94.5100
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.93827,
      "incrementalCarConfigFinished": 97.7546
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 0.97907,
      "incrementalCarConfigFinished": 100.935
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.01986,
      "incrementalCarConfigFinished": 104.0554
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.06066,
      "incrementalCarConfigFinished": 107.1165
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.10145,
      "incrementalCarConfigFinished": 110.1207
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.14225,
      "incrementalCarConfigFinished": 113.0728
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.18304,
      "incrementalCarConfigFinished": 115.9713
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.22383,
      "incrementalCarConfigFinished": 118.8200
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.26463,
      "incrementalCarConfigFinished": 121.619
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.30542,
      "incrementalCarConfigFinished": 124.3716
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.34622,
      "incrementalCarConfigFinished": 127.0769
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.38701,
      "incrementalCarConfigFinished": 129.7382
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.42781,
      "incrementalCarConfigFinished": 132.3545
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.4686,
      "incrementalCarConfigFinished": 134.9276
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.5094,
      "incrementalCarConfigFinished": 137.4566
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.55019,
      "incrementalCarConfigFinished": 139.9447
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.59099,
      "incrementalCarConfigFinished": 142.3896
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.63178,
      "incrementalCarConfigFinished": 144.7960
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.67257,
      "incrementalCarConfigFinished": 147.1623
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.71337,
      "incrementalCarConfigFinished": 149.4886
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.75416,
      "incrementalCarConfigFinished": 151.7772
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.79496,
      "incrementalCarConfigFinished": 154.0290
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.83575,
      "incrementalCarConfigFinished": 156.2430
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.87655,
      "incrementalCarConfigFinished": 158.4227
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.91734,
      "incrementalCarConfigFinished": 160.5670
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.95814,
      "incrementalCarConfigFinished": 162.677
   
    },
    {
      "channelName": "mdsp_seamodel1cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel013",
      "normalizedSpend": 1.99893,
      "incrementalCarConfigFinished": 164.7556
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0,
      "incrementalCarConfigFinished": 0  
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.02927,
      "incrementalCarConfigFinished": 3.2566
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.05854,
      "incrementalCarConfigFinished": 6.3794
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.0878,
      "incrementalCarConfigFinished": 9.3868
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.11707,
      "incrementalCarConfigFinished": 12.2932
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.14634,
      "incrementalCarConfigFinished": 15.1059
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.17561,
      "incrementalCarConfigFinished": 17.8312
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.20487,
      "incrementalCarConfigFinished": 20.4764
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.23414,
      "incrementalCarConfigFinished": 23.0495
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.26341,
      "incrementalCarConfigFinished": 25.552
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.29268,
      "incrementalCarConfigFinished": 27.9929
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.32195,
      "incrementalCarConfigFinished": 30.3745
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.35121,
      "incrementalCarConfigFinished": 32.701
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.38048,
      "incrementalCarConfigFinished": 34.9765
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.40975,
      "incrementalCarConfigFinished": 37.2018
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.43902,
      "incrementalCarConfigFinished": 39.3807
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.46829,
      "incrementalCarConfigFinished": 41.5178
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.49755,
      "incrementalCarConfigFinished": 43.6125
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.52682,
      "incrementalCarConfigFinished": 45.6679
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.55609,
      "incrementalCarConfigFinished": 47.6873
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.58536,
      "incrementalCarConfigFinished": 49.6706
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.61462,
      "incrementalCarConfigFinished": 51.6202
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.64389,
      "incrementalCarConfigFinished": 53.5370
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.67316,
      "incrementalCarConfigFinished": 55.4233
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.70243,
      "incrementalCarConfigFinished": 57.2784
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.7317,
      "incrementalCarConfigFinished": 59.1055
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.76096,
      "incrementalCarConfigFinished": 60.9053
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.79023,
      "incrementalCarConfigFinished": 62.6770
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.8195,
      "incrementalCarConfigFinished": 64.4239
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.84877,
      "incrementalCarConfigFinished": 66.1468
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.87803,
      "incrementalCarConfigFinished": 67.8440
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.9073,
      "incrementalCarConfigFinished": 69.5188
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.93657,
      "incrementalCarConfigFinished": 71.1711
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.96584,
      "incrementalCarConfigFinished": 72.8010
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 0.99511,
      "incrementalCarConfigFinished": 74.4109
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.02437,
      "incrementalCarConfigFinished": 75.9992
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.05364,
      "incrementalCarConfigFinished": 77.5666
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.08291,
      "incrementalCarConfigFinished": 79.116
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.11218,
      "incrementalCarConfigFinished": 80.6461
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.14145,
      "incrementalCarConfigFinished": 82.1590
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.17071,
      "incrementalCarConfigFinished": 83.6519
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.19998,
      "incrementalCarConfigFinished": 85.127
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.22925,
      "incrementalCarConfigFinished": 86.5864
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.25852,
      "incrementalCarConfigFinished": 88.0288
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.28778,
      "incrementalCarConfigFinished": 89.453
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.31705,
      "incrementalCarConfigFinished": 90.8631
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.34632,
      "incrementalCarConfigFinished": 92.2582
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.37559,
      "incrementalCarConfigFinished": 93.6365
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.40486,
      "incrementalCarConfigFinished": 95.0004
   
    },
    {
      "channelName": "mdsp_seamodel2-27cam",
      "analysisDimension": "Channel",
      "analysisValue": "Channel014",
      "normalizedSpend": 1.43412,
      "incrementalCarConfigFinished": 96.3498
   
    }
  ],
  graphNotes: [
    {
        projectId: 'Project001',
        graphPage: 'ScenarioSalesImpact',
        commentBy: "Doug O'Reilly",
        comment: "@Neal Xie - are completed car configurations giving us the best optimizations?",
        isLastComment: false
    },
    {
        projectId: 'Project001',
        graphPage: 'ScenarioSalesImpact',
        commentBy: "Neal Xie",
        comment: "Yes.  We ran multiple optimizations against a range of KPIs. The limited data set presented some challenges and Modeling against completed car configurations gave us the most reliable results.",
        isLastComment: true
    },
    {
        projectId: 'Project001',
        graphPage: 'SalesContribution',
        commentBy: "Neal Xie",
        comment: "Note that the curves are built against the campaign/channel designators as delineated in the original data",
        isLastComment: true
    },
    {
        projectId: 'Project001',
        graphPage: 'MetricPerformanceByScenario',
        commentBy: "Doug O'Reilly",
        comment: "Seems like we can clearly drive more configurations - even at a lower budget",
        isLastComment: false
    },
    {
        projectId: 'Project001',
        graphPage: 'MetricPerformanceByScenario',
        commentBy: "Neal Xie",
        comment: "If the goals for completed configurations were met (we don't know) then they overspent significantly",
        isLastComment: true
    },
    {
        projectId: 'Project001',
        graphPage: 'ChannelImpactOnSales',
        commentBy: "Doug O'Reilly",
        comment: "I'm interested to see how this plays out in the optimized plan",
        isLastComment: true
    },
    {
        projectId: 'Project001',
        graphPage: 'WeeklyRevenue',
        commentBy: "Doug O'Reilly",
        comment: "I'd love to know the original strategy on the pacing. It would help to understand what they were trying to achieve",
        isLastComment: true
    },
    {
        projectId: 'Project001',
        graphPage: 'BryntumTables',
        commentBy: "Neal Xie",
        comment: "The plan would potentially yield even better results if we could truly account for seasonality.",
        isLastComment: false
    },
    {
        projectId: 'Project001',
        graphPage: 'BryntumTables',
        commentBy: "Doug O'Reilly",
        comment: "The spikes that we missed in the models suggest at least one key component was missing in the data but it is hard to judge what that might be",
        isLastComment: true
    },
    {
        projectId: 'Project002',
        graphPage: 'ScenarioSalesImpact',
        commentBy: "Doug O'Reilly",
        comment: "The results don't look as strong as Market A",
        isLastComment: false
    },
    {
        projectId: 'Project002',
        graphPage: 'ScenarioSalesImpact',
        commentBy: "Neal Xie",
        comment: "The models are not as strong",
        isLastComment: false
    },
    {
        projectId: 'Project002',
        graphPage: 'ScenarioSalesImpact',
        commentBy: "Doug O'Reilly",
        comment: "Any insights into the differences across markets?",
        isLastComment: false
    },
    {
        projectId: 'Project002',
        graphPage: 'ScenarioSalesImpact',
        commentBy: "Neal Xie",
        comment: "We are looking at the SHAP values to understand feature importance within the models.",
        isLastComment: true
    },
    {
        projectId: 'Project002',
        graphPage: 'SalesContribution',
        commentBy: "Neal Xie",
        comment: "The curves are for the bucketed campaigns after we addressed the collinearity issues",
        isLastComment: false
    },
    {
        projectId: 'Project002',
        graphPage: 'SalesContribution',
        commentBy: "Doug O'Reilly",
        comment: "Thanks - we need to document and detail that approach",
        isLastComment: true
    },
    {
        projectId: 'Project002',
        graphPage: 'MetricPerformanceByScenario',
        commentBy: "Neal Xie",
        comment: "Despite the differences in the models across market the conclusion is similar in both cases that the campaigns weren't as efficient as possible.",
        isLastComment: false
    },
    {
        projectId: 'Project002',
        graphPage: 'MetricPerformanceByScenario',
        commentBy: "Doug O'Reilly",
        comment: "Yes - that does look consistent",
        isLastComment: true
    },
    {
        projectId: 'Project002',
        graphPage: 'ChannelImpactOnSales',
        commentBy: "Neal Xie",
        comment: "We can break out the buckets to their component parts but the overall result is pointing to overspend",
        isLastComment: true
    },
    {
        projectId: 'Project002',
        graphPage: 'WeeklyRevenue',
        commentBy: "Doug O'Reilly",
        comment: "The problems with the audiences in Market B cloud this picture quite a bit",
        isLastComment: true
    },
    {
        projectId: 'Project002',
        graphPage: 'BryntumTables',
        commentBy: "Doug O'Reilly",
        comment: "That is a significant delta between the base and the optimized scenario",
        isLastComment: true
    },
  ]
};
