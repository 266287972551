export const getClientLogoImage = async (imageUrl) => {
  const storage = localStorage.getItem("okta-token-storage");
  const token = storage && JSON.parse(storage)?.accessToken?.accessToken;
  const logoUrlResponse = await fetch(imageUrl, {
    method: "GET",
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const imageBlob = await logoUrlResponse.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  return imageObjectURL;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const nestingFn = (array, childrenKeyName = "children") => {
  /**
   * nestingArrayElementsFn :-
   * This function nests a given element at its apt place in nested array structure except if the
   * element was supposed to be added at th top level of array nesting. That has to be done after
   * the point at which this function was triggered. That is being done in "nestingFn".
   *
   * @param {*} arr: this is the parent array in the nested structure of which we want to add a
   * new element.
   * @param {*} elementToRelocate: the new element we want to add to arr
   * @returns After having added the new element at its apt place in the nested arr structure,
   *  this function return a boolean which represents whether the element was relocated or not.
   */
  const nestingArrayElementsFn = (arr, elementToRelocate) => {
    // "relocated" represents if elementToRelocate was placed/added in the arr in this function
    // and not whether arr's elements were added/relocated in elementToRelocate's children array
    // or not.
    let relocated = false;
    let elementsToRemoveAfterLoop = [];
    arr.forEach((arrElement, index) => {
      if (elementToRelocate.parent === arrElement.value) {
        arrElement[childrenKeyName] = arrElement[childrenKeyName]
          ? [...arrElement[childrenKeyName], elementToRelocate]
          : [elementToRelocate];
        relocated = true;
        return;
      }

      if (elementToRelocate.value === arrElement.parent) {
        elementToRelocate[childrenKeyName] = elementToRelocate[childrenKeyName]
          ? [...elementToRelocate[childrenKeyName], arrElement]
          : [arrElement];
        elementsToRemoveAfterLoop.push(index);
        return;
      }

      if (arrElement?.[childrenKeyName]?.length) {
        relocated = nestingArrayElementsFn(
          arrElement[childrenKeyName],
          elementToRelocate
        );
        return;
      }
    });

    while (elementsToRemoveAfterLoop.length) {
      arr.splice(elementsToRemoveAfterLoop.pop(), 1);
    }
    return relocated;
  };

  let result = array?.reduce((newArr, element) => {
    if (newArr.length === 0) newArr.push(element);
    else {
      let isRelocated = nestingArrayElementsFn(newArr, element);
      if (!isRelocated) newArr.push(element);
    }
    return newArr;
  }, []);
  return result;
};
