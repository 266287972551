const cmsListing = {
    userCreate: 'User created',
    userUpdate: 'User updated',
    userCreateSuccessful: `User created Successfully!!!.`,
    userUpdateSuccessful: `User updated Successfully!`,
    success: 'success',
    nameText: 'Please enter information',
    clientPortal: 'Client portal',
    nameLabel: 'Name',
    namePlaceholder: 'Name',
    departmentLabel: 'Department',
    departmentPlaceholder: 'Department',
    countryLabel: 'Country',
    countryPlaceholder: 'Country',
    emailPlaceholder:"Email",
    appTitle: 'Client App',
    appSubTitle: 'Please ignore copy',
    createButton: 'Create client',
    addUser: 'Add User',
    userDeleteText: 'User deleted.',
    userDeleteMsg: 'User deleted Successfully!!!.',
    userDeleteContent1: `You can't delete`,
    userDeleteContent2: 'He will get deleted when mutation works!!!.`',
    emailLabel: 'Email',
    emailRequiredErrorMsg: 'Email is required',
    emailInvalidErrorMsg: 'Email is invalid',
    nameErrorMsg: 'Name is required',
    nameValidationErrorMsg: `Name should not contain any digit`,
    departmentValidationErrorMsg: `Department should not contain any digit`,
    deptErrorMsg: 'department is required',
    countryErrorMsg: 'country is required',
    nameDuplicateMessage: 'Name already exist please enter another name',
    emailDuplicateMessage: 'Email already exist please enter another email',
    errorMessage:'Oops! Some Error Occurred',
    testIdName :'nameField',
    testIdEmail :'emailField',
    testIdInput :'text-input',
    testIdAddbtn :'addUserBtn',
    dialogError:'Are you sure you want to delete this user ?',
    secondaryActionLabel:'Delete',
    primaryActionLabel:'Close',
    modalHead:"modalHead",
    modalBody:"modalBody",
    modalFooter:"modalFooter",
    addBtn:"addBtn",
    cancel: 'Cancel',
    continue: 'Continue',
    saveAndClose: 'Save and Close',
    projectName: 'Project name',
    brief: 'Brief',
    markets: 'Market/s',
    brandsAndProducts: 'Brand/s and product/s',
    channels: 'Channel/s',
    reviewSettings: 'Review settings',
    BPL_sidenavigation_title_businessPlanner: 'Business planner',
};

export default cmsListing;
