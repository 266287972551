import * as A_GNMOT from './mock';
import * as NFT_CLIENT084 from './NFT_CLIENT084/mock';
import * as T_KRHNZ from './T_KRHNZ/mock';
import * as A_VWG from './A_VWG/mock';
import * as A_PAJO from './A_PAJO/mock';
import * as A_ROMG from './A_ROMG/mock';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    NFT_CLIENT084, // Volkswagan
    A_GNMOT, // General Motors
    T_KRHNZ, // Kraft Heinz
    A_VWG, // VW Audi
    A_PAJO, // Papa Jones
    A_ROMG, // Rocket Mortgage
}
