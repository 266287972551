import { useContext } from "react";
import { Navigation } from "@dentsu-ui/components";
import { NavLink } from "react-router-dom";
import cmsListing from "../../cms/Listing";
import { ProjectContext } from "../../context/ProjectProvider";

const SideNavigation = () => {
  const { clientCode } = useContext(ProjectContext);
  return (
    <Navigation as={NavLink}>
      <Navigation.Section
        title={cmsListing.BPL_sidenavigation_title_businessPlanner}
        items={[
          {
            label: "Projects",
            url: `/projects?clientCode=${clientCode}`,
            isDisabled: false,
            exact: true,
          },
        ]}
      />
    </Navigation>
  );
}

export default SideNavigation;
