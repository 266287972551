import React, { useState, useContext, useEffect } from "react";
import { BulkDataContext } from "./BulkData/BulkDataContext";

export const ProjectContext = React.createContext(null);

export function ProjectProvider({ children }) {
  const { clientCode, bulkData: { Projects } } = useContext(BulkDataContext);
  const [projectList, setProjectList] = useState(Projects || []);
  const [newProject, setNewProject] = useState({});
  const [project, setProject] = useState({});

  useEffect(() => {
    setProjectList(Projects);
  }, [Projects]);

  return (
    <ProjectContext.Provider
      value={{
        projectList,
        setProjectList,
        newProject,
        setNewProject,
        project,
        setProject,
        clientCode
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
