import { Tree, Scrollable } from "@dentsu-ui/components";

const BPLTree = ({
  data,
  disabledValues = [],
  disabled,
  handleChange,
  customStyles = {},
}) => {
  customStyles.height = customStyles.height || "30vh";
  return (
    <Scrollable style={{ ...customStyles }}>
      <Tree
        data={data}
        disabledValues={disabledValues}
        isDisabled={disabled}
        defaultExpandAll={true}
        onChange={(checked) => {
          handleChange(checked);
        }}
        resize="none"
      />
    </Scrollable>
  );
};

export default BPLTree;
