import { Suspense, lazy, useState, useEffect } from "react";
import { Frame, Loading } from "@dentsu-ui/components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { SideNavigation } from "./components";
import { HierarchyContext } from "./context/HierarchyContext";
import PageNotFound from "./components/Fallbacks/PageNotFound";
import Forbidden from "./components/Fallbacks/Forbidden";
import { ProjectProvider } from "./context/ProjectProvider";
import { CreateScenarioProvider } from "./context/CreateScenarioProvider";
import { CompareScenariosProvider } from "./context/CompareScenariosProvider";
import { BulkDataContext } from "./context/BulkData/BulkDataContext";
import ClientBulkData from "./context/BulkData";

const Projects = lazy(() => import("./modules/Projects"));
const Scenarios = lazy(() => import("./modules/Scenarios"));
const Comparision = lazy(() => import("./modules/Comparision"));

function Routes(props) {
  const [bulkData, setBulkData] = useState({});
  const [clientData, setClientData] = useState({});
  const { clientCode } = props;

  useEffect(() => {
    const { bulkDataMock } = ClientBulkData[clientCode];
    setBulkData(bulkDataMock);
  }, [clientCode]);

  useEffect(() => {
    const {
      Markets: clientMarkets,
      Brands: clientBrands,
      Channels: clientChannels,
    } = bulkData;

    setClientData({
      clientMarkets,
      clientBrands,
      clientChannels,
      clientCode
    });
  }, [bulkData, clientCode]);

  if (clientCode) {
    let active_clients = ['NFT_CLIENT084', 'A_GNMOT', 'T_KRHNZ', 'A_VWG', 'A_PAJO', 'A_ROMG'];
    if (!active_clients.includes(clientCode)) {
      return <Redirect to="/pageNotFound" />;
    }
  }

  return (
    <BulkDataContext.Provider
      value={{
        clientCode,
        bulkData,
      }}
    >
      <HierarchyContext.Provider
        value={{
          clientData,
        }}
      >
        <ProjectProvider>
          <CreateScenarioProvider>
            <Router>
              <Frame navigation={<SideNavigation />}>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/projects" />
                  </Route>
                  <Route exact path="/projects">
                    <Suspense fallback={<Loading />}>
                      <Projects />
                    </Suspense>
                  </Route>
                  <Route path={`/project/:projectId/scenarios`}>
                    <Suspense fallback={<Loading />}>
                      <Scenarios />
                    </Suspense>
                  </Route>
                  <Route path="/project/:projectId/comparision">
                    <Suspense fallback={<Loading />}>
                      <CompareScenariosProvider>
                        <Comparision />
                      </CompareScenariosProvider>
                    </Suspense>
                  </Route>
                  <Route exact path="/pageNotFound">
                    <PageNotFound />
                  </Route>
                  <Redirect to="/pageNotFound" />
                  <Route exact path="/403">
                    <Forbidden />
                  </Route>
                </Switch>
              </Frame>
            </Router>
          </CreateScenarioProvider>
        </ProjectProvider>
      </HierarchyContext.Provider>
    </BulkDataContext.Provider>
  );
}

export default Routes;
