import Routes from './Routes';
import {
  useCMS,
} from '@gdo-global-client-reporting/dentsu-platform-shell';
import {
  getServiceConfig,
} from '@gdo-global-client-reporting/dentsu-platform-bridge';
import config from './config';
import "./global-app.css";
import queryString from 'query-string';


const App = () => {

  const {
    data: cmsContextData,
  } = useCMS();

  const { env } = config;

  let serviceConfig = getServiceConfig();
  // TODO: after platform integration uncomment this condition
if (env === 'development') {
    serviceConfig = config.serviceConfig;
    sessionStorage.setItem(
      'serviceConfig',
      JSON.stringify(config.serviceConfig),
    );
}

  if (env === 'int-g1ds' || env === 'development' || env === 'hint-g1ds') {
    console.info('ServiceConfig ::::: ', serviceConfig);
  }

  let clientCode = 'A_ROMG';
  let queryParams = queryString.parse(window.location.search);
  if (Object.keys(queryParams).length) {
    clientCode = queryParams.clientCode;
  }

  let cmsData = { result: {} };
  if (cmsContextData) {
    if (cmsContextData.getContent && cmsContextData.getContent.result) {
      cmsData = cmsContextData.getContent.result;
    }
  }

  return (
    <>
      <Routes
        cmsContent={cmsData}
        clientCode={clientCode}
      />
    </>
  )
}
export default (App);
