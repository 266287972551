import React, { useState } from "react";

export const CreateScenarioContext = React.createContext(null);

export function CreateScenarioProvider({ children }) {
  const [scenarioList, setScenarioList] = useState([]);
  const [createNewScenario, setCreateNewScenario] = useState({});
  return (
    <CreateScenarioContext.Provider
      value={{
        scenarioList,
        setScenarioList,
        setCreateNewScenario,
        createNewScenario,
      }}
    >
      {children}
    </CreateScenarioContext.Provider>
  );
}
