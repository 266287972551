export const projectConstants = {
  BPL_project_createProjectModal_nameFieldMaxlengthError:
    "Field must be 100 characters or less",
  BPL_project_createProjectModal_nameFieldUniqueValueError:
    "Project name must be unique",
  BPL_project_createNewProjectText: "Create new project",
  BPL_project_createProjectModal_headerMetadata: "Before you start",
  BPL_project_createProjectModal_nameFieldLabel: "Give your project a name",
  BPL_project_createProjectModal_nameFieldError: "Please give project name",
  BPL_project_createProjectModal_briefFieldLabel:
    "Link this project to an existing brief (optional)",
  BPL_project_createProjectModal_briefFieldPlaceholder: "Select brief",
  BPL_project_createProjectModal_dateFieldLabel: "Project start and end date",
  BPL_project_createProjectModal_dateFieldHint:
    "You can change this in your scenarios",
  BPL_project_createProjectModal_currencyFieldHint:
    "This will apply throughout this project and cannot be changed later11",
  BPL_project_createProjectModal_currencyFieldLabel: "Project currency",
  BPL_project_textfield_projectname_error: "Please give project name",
  BPL_project_createProjectModal_currencyFieldEmptyError:
    "Please select currency",
  BPL_project_createProjectModal_dateFieldEmptyError:
    "Please select the date range",
  BPL_project_business_planning_title_myProjects: "My projects",
  BPL_project_business_planning_subtitle: "Projects",
  BPL_project_business_planning_thumbnail: "/default-logo.png",
  BPL_project_business_planning_thumbnailFallback: "default-logo.png",
  BPL_project_emptystate_title: "You don’t have any projects yet",
  BPL_project_list_allText_allProjects: "All projects",
  BPL_project_list_card_markets: "Markets",
  BPL_project_list_card_brands: "Brands",
  BPL_project_list_card_channels: "Channels",
  BPL_project_list_card_dates: "Dates",
  BPL_project_list_card_button_viewProjectDetails: "View project details",
  BPL_project_list_card_button_viewScenarios: "View scenarios",
  BPL_project_list_card_menuItem_details: "Details",
  BPL_project_emptyState_paragraph_projectContainers:
    "Projects are containers for your business planning scenarios.",
  BPL_project_emptyState_paragraph_groupScenarios:
    "They allow you to group your scenarios and make them",
  BPL_project_emptyState_paragraph_easierToFind: "easier to find and maintain.",
  BPL_project_list_filter_show: "Show",
  BPL_project_list_filter_sortBy: "Sort by",
  BPL_project_list_card_button_more: "more",
  BPL_project_list_filterText_resultInTotal: "result in total",
  BPL_project_cancelCTAToastTitle: "Unsaved data discarded",
  BPL_project_cancelCTAToastMessage: "The data you entered has been discarded",
};

export const fallbackConstants = {
  BPL_fallback_emptystate_metadata_error404: "ERROR CODE: 404",
  BPL_fallback_emptystate_title_pagenotfound: "Page not found",
  BPL_fallback_emptystate_error_pagenotfound: "Sorry, page not found",
  BPL_fallback_emptystate_alert_message_datenotfound: "Data not found!!",
  BPL_fallback_emptystate_secondary_action_gobackhome: "Go back home",
};

export const brandProductStepConstants = {
  BPL_brandproductstep_formfield_label_select:
    "Select the brand/s and product/s for your project",
  BPL_brandproductstep_tree_disabled_values: ["eastAsia", "newZealand"],
  BPL_brandproductstep_checkbox_paragraph_data:
    "These will also track any other brands and products that customers may be loyal to based on previous positive experiences with the selected brands and products.",
};

export const channelStepConstants = {
  BPL_project_channelstep_fornfield_selectchannels:
    "Select channels for your project",
};

export const createProjectDialogConstants = {
  BPL_project_createprojectdialog_title_addscenarios:
    "Would you like to add scenarios now?",
  BPL_project_createprojectdialog_create_paragraph_data:
    "Your project has been created, but is currently empty. To compare optimised plans, you need to add one or more scenarios.",
  BPL_project_createprojectdialog_addscenario_paragraph_data:
    "Would you like to add scenarios now?",
  BPL_project_createprojectdialog_button_gotoemptyproject: " No, cancel",
  BPL_project_createprojectdialog_button_addscenarios: "Yes, continue",
};

export const forbiddenConstants = {
  BPL_fallbacks_forbidden_emptystate_title:
    "Sorry, you don’t have permission to access this page",
  BPL_fallbacks_forbidden_emptystate_metadata_error403: "ERROR CODE: 403",
  BPL_fallbacks_forbidden_emptystate_paragraph_data:
    "You need to have access rights to explore this page.",
};

export const marketStepConstants = {
  BPL_project_marketstep_formfield_label_selectmarketsforproject:
    "Select a market for your project",
  BPL_project_marketstep_tree_disabledvalue_data: ["eastAsia", "newZealand"],
};

export const selectScenarioStepConstants = {
  BPL_scenario_select_scenario_step_helpcallout_title_projectscenarios:
    "Project Scenarios",
  BPL_scenario_select_scenario_step_helpcallout_paragraph:
    "These will become the starting scenarios in your project and can be tweaked and manipulated once the project is set up. You can then also add further scenarios to the project.",
  BPL_scenario_select_scenario_step_select_starting_field:
    "Set at least one starting scenario for your project",
  BPL_scenario_select_scenario_step_currencyinput_maximum_budget_label:
    "Enter a maximum budget",
  BPL_scenario_select_scenario_step_select_planmanager_label:
    "Select a plan from plan manager",
};

export const KPIDiagiagnosticConstants = {
  BPL_kpidiagnostic_helpcallout_title_setkpivisibility:
    "What is a diagnostic metric?",
  BPL_kpidiagnostic_helpcallout_text:
    "An diagnostic metric is a metric that is calculated for a scenario, but not optimised for. It's included in the reporting to be informative but doesn't affect the ultimate outcome of the scenario.",
  BPL_kpidiagnostic_helpcallout_formfield_diagnostickpis:
    "Select the diagnostic metric/s",
};

export const projectWizardConstants = {
  BPL_project_projectWizard_marketStepTitle: "Select the market/s",
  BPL_project_projectWizard_brandProductStepTitle:
    "Select the brand/s and product/s",
  BPL_project_projectWizard_channelStepTitle: "Select the channel/s",
  BPL_project_projectWizard_reviewSettingsStepTitle: "Review settings",
  BPL_project_projectWizard_createProjectCTASuccessToastTitle:
    "Project created",
  BPL_project_projectWizard_createProjectCTASuccessToastMessage:
    "Added to the top of the list",
};

export const reviewSettingConstants = {
  BPL_project_reviewSettings_project_layout_testproject: "Annual Plan 2024",
  BPL_project_reviewSettings_brief_layout_client_annualbrief2023:
    "Client Annual Brief 2023",
  BPL_project_reviewSettings_projectDate_layout_default_projectdates:
    "Project dates",
  BPL_project_reviewSettings_currency_layout_default_projectcurrency:
    "Project currency",
  BPL_project_reviewSettings_markets_paragraph_country: "United Kingdom - MMM",
  BPL_project_reviewSettings_brandsandproducts_paragraph:
    "Nescafe Alegria, Alegria Mocha, Alegria Cappuccino",
  BPL_project_reviewSettings_channel_paragraph_recommend_channel:
    "Recommend channels for me",
};

export const scenarioWizardConstants = {
  BPL_project_scenarioWizard_step_selectKPITitle: "Select business objectives",
  BPL_project_scenarioWizard_step_selectDiagnosticKPITitle:
    "Select the diagnostic metric/s",
  BPL_project_scenarioWizard_step_selectScenarioType:
    "Select the scenario type/s",
  BPL_project_scenarioWizard_createScenarioText: "Create scenario",
  BPL_project_scenarioWizard_createScenarioCTASuccessToastTitle:
    "Scenario/s created",
  BPL_project_scenarioWizard_createMultipleScenariosCTASuccessToastTitle:
    "Scenario created",
  BPL_project_scenarioWizard_createScenarioCTASuccessToastMessage:
    "Added to the top of the list",
};
